import React from 'react';
import moment from 'moment';
import { renderToString } from 'react-dom/server';
import { SECONDS_IN_DAY } from '~/bundles/shared/constants/time';
import disallowUndefinedProperties from '~/utils/disallowUndefined';
import { getTextDirection } from '~/utils/I18n/I18n';
import AGPtooltip from './tooltips/AGPtooltip/AGPtooltip';
import BolusTooltip from './tooltips/HoursTooltips/BolusTooltip/BolusTooltip';
import ExerciseTooltip from './tooltips/HoursTooltips/ExerciseTooltip/ExerciseTooltip';
import GlucoseTooltip from './tooltips/HoursTooltips/GlucoseTooltip/GlucoseTooltip';
import CarbsTooltip from './tooltips/HoursTooltips/CarbsTooltip/CarbsTooltip';
import BasalTooltip from './tooltips/HoursTooltips/BasalTooltip/BasalTooltip';
import ScatterTooltip from './tooltips/ScatterTooltip/ScatterTooltip';
import BucketedGlucoseTooltip from './tooltips/BucketedGlucoseTooltip/BucketedGlucoseTooltip';

const renderToStringDirectional = (tooltip) => renderToString(<div style={{ direction: getTextDirection() }}>{tooltip}</div>);

class Tooltips {
  static agpTooltipRender(point, timestamp, reactProps, inSummaryPage = false) {
    return renderToStringDirectional(
      <AGPtooltip
        timestamp={timestamp}
        point={point}
        {...reactProps}
        inSummaryPage={inSummaryPage}
      />,
    );
  }

  static agpTooltip() {
    const { x, point } = this;
    const reactProps = this.series.chart.userOptions.reactProps;
    const timestamp = (x / reactProps.xAxisMax) * SECONDS_IN_DAY;

    return Tooltips.agpTooltipRender(point, timestamp, reactProps);
  }

  static summaryAgpTooltip() {
    const { x, point } = this;
    const reactProps = this.series.chart.userOptions.reactProps;
    const timestamp = (x / reactProps.xAxisMax) * SECONDS_IN_DAY;

    return Tooltips.agpTooltipRender(point, timestamp, reactProps, true);
  }

  static scatterTooltip() {
    const { point } = this;
    const meterUnits = this.series.chart.userOptions.reactProps.meterUnits;

    return renderToStringDirectional(<ScatterTooltip point={point} meterUnits={meterUnits} />);
  }

  static bucketedGlucoseTooltip() {
    const { points } = this;
    return renderToStringDirectional(<BucketedGlucoseTooltip points={points} />);
  }

  static tooltipPositioner(tooltipWidth, tooltipHeight, point, chart) {
    let tooltipX;
    const shift = 20;
    const padding = 15;

    if (point.plotX + tooltipWidth > chart.plotWidth - padding) {
      tooltipX = ((point.plotX + chart.plotLeft) - tooltipWidth) - shift;
    } else {
      tooltipX = point.plotX + chart.plotLeft + shift;
    }

    const tooltipY = Math.round(point.plotY - (tooltipHeight / 2));

    return {
      x: tooltipX,
      y: Math.min(Math.max(tooltipY, 0), chart.options.chart.height - tooltipHeight),
    };
  }

  static tooltipHoursPositioner(tooltipWidth, tooltipHeight, point, chart) {
    let tooltipX;
    let tooltipY;
    const shift = 20;
    const padding = 15;

    const startX = moment.utc(chart.options.reactProps.realStartDate).unix();
    const graphsStart = chart.options.xAxis[0].min;
    const graphEnd = chart.options.xAxis[0].max;
    const graphWidth = chart.options.chart.width;
    const rightShift = (((graphsStart - startX) / (graphEnd - graphsStart)) * graphWidth);
    const pos = point.plotX + rightShift;

    if (pos + tooltipWidth > chart.plotWidth - padding) {
      tooltipX = ((point.plotX + chart.plotLeft) - tooltipWidth) - shift;
    } else {
      tooltipX = point.plotX + chart.plotLeft + shift;
    }

    tooltipY = Math.round(point.plotY - (tooltipHeight / 2));
    if (chart.options.reactProps.overviewLastGraph) {
      tooltipY = Math.min(tooltipY, chart.options.chart.height - tooltipHeight - 2);
    }
    return {
      x: tooltipX,
      y: tooltipY,
    };
  }

  static bucketedGlucoseTooltipPositioner(tooltipWidth, tooltipHeight, point, chart) {
    const shift = 20;
    const toolTipX = point.plotX + chart.plotLeft + shift;
    const tooltipY = Math.floor(((chart.options.chart.height - shift) - tooltipHeight) / 2);
    return { x: toolTipX, y: tooltipY };
  }

  static glucoseTooltip() {
    const { point } = this;
    const meterUnits = this.series.chart.userOptions.reactProps.meterUnits;

    return renderToStringDirectional(<GlucoseTooltip point={point} meterUnits={meterUnits} />);
  }

  static bolusTooltip() {
    const meterUnits = this.series.chart.userOptions.reactProps.meterUnits;
    return renderToString(<BolusTooltip point={this.point} meterUnits={meterUnits} />);
  }

  static basalTooltip() {
    const { x, point } = this;
    const reactProps = this.series.chart.userOptions;
    const timestamp = (x / reactProps.xAxis.max) * SECONDS_IN_DAY;
    if (point.interpolated && point.y === 0 || point.displayTooltip == false) {
      return false;
    }
    return renderToStringDirectional(
      <BasalTooltip
        timestamp={timestamp}
        point={point}
        {...reactProps}
      />,
    );
  }

  static carbsTooltip() {
    const { x, point } = this;
    const reactProps = this.series.chart.userOptions;
    const timestamp = (x / reactProps.xAxis.max) * SECONDS_IN_DAY;
    return renderToStringDirectional(
      <CarbsTooltip
        timestamp={timestamp}
        point={point}
        {...reactProps}
      />,
    );
  }

  static exerciseTooltip() {
    const { x, point } = this;
    const { reactProps, xAxis } = this.series.chart.userOptions;
    const timestamp = (x / xAxis.max) * SECONDS_IN_DAY;

    return renderToStringDirectional(<ExerciseTooltip
      timestamp={timestamp}
      point={point}
      unit={reactProps.unit}
      {...this.series.chart.userOptions}
    />);
  }
}

export default disallowUndefinedProperties(Tooltips);
