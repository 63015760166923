import i18next from 'utils/I18n/I18n';
import { numberFormatterOneDecimal } from 'utils/i18nFormats';
import { metersToMiles, metersToKilometers, gramsToKilograms, gramsToPounds } from 'utils/unitsOfMeasurement';
import { SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from 'bundles/shared/constants/time';

interface UseHumanizeFunctions {
  humanizeBloodPressure: (systolic: number, diastolic: number) => string,
  humanizeCalories: (calories: number) => string,
  humanizeDuration: (duration: number) => string,
  humanizeDistance: (distance: number, unit: string) => string
  humanizeHeartRate: (heartRate: number) => string,
  humanizeIntensity: (intensity: number | string) => string
  humanizeWeight: (weight: number, unit: string) => string
}

const useHumanize = (): UseHumanizeFunctions => {
  const t = i18next.getFixedT(null, 'useHumanize');

  return {
    humanizeBloodPressure: humanizeBloodPressure(t),
    humanizeCalories: humanizeCalories(t),
    humanizeDuration: humanizeDuration(t),
    humanizeDistance: humanizeDistance(t),
    humanizeHeartRate: humanizeHeartRate(t),
    humanizeIntensity: humanizeIntensity(t),
    humanizeWeight: humanizeWeight(t),
  };
};

const humanizeBloodPressure = (t: typeof i18next.t) => (systolic: number, diastolic: number): string => {
  const systolicRounded = Math.round(systolic);
  const diastolicRounded = Math.round(diastolic);

  return t('bloodPressure', { systolic: systolicRounded, diastolic: diastolicRounded });
};

const humanizeCalories = (t: typeof i18next.t) => (calories: number): string => {
  const caloriesRounded = Math.round(calories);

  return t('calories', { calories: caloriesRounded });
};

const humanizeDuration = (t: typeof i18next.t) => (duration: number): string => {
  const { hours, minutes } = getHoursAndSecondsFromSeconds(duration);

  return t('duration', { hours: hours, minutes: minutes });
};

const humanizeDistance = (t: typeof i18next.t) => (distance: number, unit: string): string => {
  const convertedDistance = unit === 'km' ? metersToKilometers(distance) : metersToMiles(distance);
  const translationOptions = { distance: numberFormatterOneDecimal(convertedDistance) };

  return unit === 'km' ? t('distanceKm', translationOptions) : t('distanceMiles', translationOptions);
};

const humanizeHeartRate = (t: typeof i18next.t) => (heartRate: number): string => {
  const heartRateRounded = Math.round(heartRate);

  return t('heartRate', { heartRate: heartRateRounded });
};

const humanizeIntensity = (t: typeof i18next.t) => (intensity: number | string): string => {
  if (typeof intensity === 'number') return intensityFromGlookoExercise(intensity, t);

  return convertIntensity(intensity, t);
};

const humanizeWeight = (t: typeof i18next.t) => (weight: number, unit: string): string => {
  const convertedWeight = unit === 'kg' ? gramsToKilograms(weight) : gramsToPounds(weight);
  const translationOptions = unit === 'kg' ? { weight: numberFormatterOneDecimal(convertedWeight) } : { weight: Math.round(convertedWeight) };

  return unit === 'kg' ? t('weightKg', translationOptions) : t('weightLbs', translationOptions);
};

const convertIntensity = (intensity: string, t: typeof i18next.t): string => {
  if (intensity === 'low') return t('intensityLow');
  if (intensity === 'medium') return t('intensityMedium');
  if (intensity === 'high') return t('intensityHigh');

  return t('intensity', { intensity });
};

const intensityFromGlookoExercise = (intensity: number, t: typeof i18next.t): string => {
  if (intensity < 33) return t('intensityLow');
  if (intensity < 66) return t('intensityMedium');

  return t('intensityHigh');
};

const getHoursAndSecondsFromSeconds = (seconds: number): { hours: number, minutes: number } => {
  const hours = Math.floor(seconds / SECONDS_IN_HOUR);
  const minutes = Math.round((seconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);

  return { hours, minutes };
};

export default useHumanize;
