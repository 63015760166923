import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';
import Style from '../PdfWizard.scss';

const MANAGE_FAVOURITES_LINK = 'settings#manage-favourite-settings';

const SelectFavoriteReport = (props) => {
  const {
    t,
    placeholder,
    selectFavoriteReport,
    favorites,
    selectedFavorite,
    signedInByToken,
  } = props;

  return (
    <div className={Style.Select}>
      <div className={Style.reportTitle}>
        {t('preferredSetting')}
      </div>
      <Select
        options={favorites}
        className={Style.selectContainer}
        defaultValue={selectedFavorite}
        onChange={selectFavoriteReport}
        isDisabled={favorites.length === 0}
        placeholder={placeholder}
        size='medium'
      />
      {!signedInByToken && (
        <div className={Style.manageButtonContainer}>
          <a className={Style.manageButton} href={MANAGE_FAVOURITES_LINK} target="_self">{t('manageFavorites')}</a>
        </div>
      )}
    </div>
  );
};

SelectFavoriteReport.propTypes = {
  placeholder: PropTypes.string.isRequired,
  selectFavoriteReport: PropTypes.func.isRequired,
  favorites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedFavorite: PropTypes.string.isRequired,
  signedInByToken: PropTypes.bool,
};

export default translate('SelectFavoriteReport')(SelectFavoriteReport);
