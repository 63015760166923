import React from 'react';
import { Select } from '@glooko/common-ui';
import { isEmpty } from 'lodash';
import { components } from 'react-select';
import ImageStore from '~/utils/ImageStore';
import Style from './TerminalSyncSelect.scss';

const CustomOption = (props) => (
  <components.Option {...props}>
    {props.isSelected ? <img src= {ImageStore.checked} /> : <img src={ImageStore.unChecked} />}
    {props.label}
  </components.Option>
);

const CustomMultiValue = (props) => {
  const values = props.selectProps.value;
  const label = props.data.label;

  if (isEmpty(values)) {
    return '';
  }

  return (values[values.length - 1].label === label ? label : label + ', ');
};

const TerminalSyncSelect = (props) => {
  const { defaultValue, handleChange, placeholder, options, noResultsText, type } = props;

  return (
    <Select
      className={Style.terminalSelect}
      value={defaultValue}
      options={options}
      isMulti
      size='medium'
      onChange={(option) => handleChange(option, type)}
      isClearable={false}
      placeholder={placeholder}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      components={{ Option: CustomOption, MultiValueContainer: CustomMultiValue }}
      noOptionsMessage={() => noResultsText}
    />
  );
};

export default TerminalSyncSelect;
