export const PATIENT_ACTIONS = {
  KEEP: 'KEEP',
  REMOVE: 'REMOVE',
};
export const REDUCER_ACTIONS = {
  EDIT_ACTION: 'EDIT_ACTION',
  EDIT_PATIENT_FIELDS: 'EDIT_PATIENT_FIELDS',
  UPDATE_EDITING_STATUS: 'UPDATE_EDITING_STATUS',
  UNDO_CHANGE_ACTION: 'UNDO_CHANGE_ACTION',
  UPDATE_ALL_ERROR_STATUS: 'UPDATE_ALL_ERROR_STATUS',
};
export function createInitialState(duplicatePatients) {
  const initialState = {
    duplicatePatients: {},
    editedPatients: {},
  };
  duplicatePatients.forEach((patient) => {
    // Normalize mrn nulls to empty string.
    if (patient.mrn === null) {
      patient.mrn = '';
    }
    initialState.duplicatePatients[patient.id] = patient;
    initialState.editedPatients[patient.id] = {
      action: PATIENT_ACTIONS.KEEP,
      patient: patient,
      error: '',
      editing: false,
    };
  });

  return initialState;
}

export function duplicatePatientsReducer(state, action) {
  switch (action.type) {
    case REDUCER_ACTIONS.EDIT_ACTION:
      const editedPatient = state.editedPatients[action.patientId];
      const modifiedPatient = {
        ...editedPatient,
        action: action.action,
      };
      state.editedPatients[action.patientId] = modifiedPatient;
      return { ...state };
    case REDUCER_ACTIONS.UNDO_CHANGE_ACTION:
      const initialState = createInitialState(action.duplicatePatients);
      return { ...initialState };
    case REDUCER_ACTIONS.EDIT_PATIENT_FIELDS:
      const patient = state.editedPatients[action.patientId];
      const updatedPatient = {
        ...patient,
        error: '',
        patient: {
          ...patient.patient,
          ...action.fields,
        },
      };
      state.editedPatients[action.patientId] = updatedPatient;
      return { ...state };
    case REDUCER_ACTIONS.UPDATE_EDITING_STATUS:
      const patientWithEditingChange = state.editedPatients[action.patientId];
      const updatedPatientWithStatusChange = {
        ...patientWithEditingChange,
        editing: action.editing,
      };
      state.editedPatients[action.patientId] = updatedPatientWithStatusChange;
      return { ...state };
    case REDUCER_ACTIONS.UPDATE_ALL_ERROR_STATUS:
      const editedPatientsWithErrorStatus = {};
      Object.keys(state.editedPatients).forEach((patientId) => {
        let error = '';
        const matchingPatient = action.users.find((user) => user.userId === patientId);
        if (matchingPatient && matchingPatient.status !== 'valid') {
          error = matchingPatient.status;
        }
        editedPatientsWithErrorStatus[patientId] = {
          ...state.editedPatients[patientId],
          error,
        };
      });

      return {
        ...state,
        editedPatients: editedPatientsWithErrorStatus,
      };
  }
}

export const selectDeduplicationPayload = (state) => {
  const users = Object.values(state.editedPatients).map(({ patient, action }) => (
    {
      userId: patient.id,
      firstName: patient.firstName,
      lastName: patient.lastName,
      dateOfBirth: patient.dateOfBirth,
      mrn: patient.mrn,
      status: action.toLowerCase(),
    }
  ));
  return {
    users,
  };
};
