import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { Button, DobPicker, Icon, Radio, Tag, TextInput } from '@glooko/common-ui';
import moment from 'moment';
import classNames from 'classnames';
import i18next from 'i18next';
import TimeUtils from 'utils/TimeUtils/TimeUtils';
import Images from 'utils/ImageStore';
import { TIME_FORMATS } from 'utils/i18nFormats';
import { getTextDirection } from '~/utils/I18n/I18n';
import { profileUrl } from '~/utils/navigation';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { useAgeOfMajorty, useCustomMrnInfo, usePgsName } from './hooks';
import Styles from './DuplicatePatientItem.scss';
import { PATIENT_ACTIONS } from './DuplicatePatientReducer';

export const Mode = {
  EDIT: 'edit',
  REVIEW: 'review',
};

const DuplicatePatientItem = ({
    t, patient, originalPatient, action, error, isEditing,
    updateAction, updatePatientFieldsAction, updateEditingAction,
    mode }) => {
  const [firstName, setFirstName] = useState(patient.firstName);
  const [lastName, setLastName] = useState(patient.lastName);
  const [dateOfBirth, setDateOfBirth] = useState(patient.dateOfBirth);
  const [mrn, setMrn] = useState(patient.mrn);
  const { exampleMrn, labelMrn } = useCustomMrnInfo(t('mrn'));
  const { isBecomingMinor } = useAgeOfMajorty();
  const { pgsName } = usePgsName();
  useEffect(() => {
    if (isEditing) { resetFields(); }
  }, [isEditing]);

  const resetFields = () => {
    setFirstName(patient.firstName);
    setLastName(patient.lastName);
    setDateOfBirth(patient.dateOfBirth);
    setMrn(patient.mrn);
  };

  const changeEditing = (editing) => {
    updateEditingAction(patient.id, editing);
  };

  const onClickAction = (e) => {
    const { value } = e.target;
    updateAction(patient.id, value);
  };

  const handleEdit = () => {
    changeEditing(true);
  };

  const handleSave = () => {
    updatePatientFieldsAction(patient.id, { firstName, lastName, dateOfBirth, mrn });
    changeEditing(false);
  };

  const handleCancelEdit = () => {
    resetFields();
    changeEditing(false);
  };

  const handleProfile = () => {
    window.open(profileUrl(patient.id), '_blank');
  };

  const dobPlaceholders = { day: t('day'), month: t('month'), year: t('year') };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'duplicate_mrn':
        return t('mrnDuplicate');
      case 'invalid_format_mrn':
        return t('mrnInvalidFormat');
      default:
        return '';
    }
  };

  const validateDob = (value) => (
    isBecomingMinor(patient.dateOfBirth, value) ? t('cantChangeDobToMinor') : undefined
  );

  const validateNotEmpty = (value) => (
    value?.length === 0 ? t('canNotBeEmpty') : undefined
  );

  const isValid = () => (
    !validateNotEmpty(firstName) && !validateNotEmpty(lastName) && !validateDob(dateOfBirth)
  );

  const fieldHelper = (value) => value || '—';

  const renderField = (newValue, oldValue) => {
    if (mode === Mode.EDIT) {
      return (
        <span className={Styles.value}>{fieldHelper(newValue)}</span>
      );
    }
    const hasChanged = oldValue !== newValue;
    return (
      <>
        <span className={classNames(Styles.value)}>{fieldHelper(newValue)}</span>
        {hasChanged && (
          <span
            className={classNames(Styles.value, Styles.changedValue, { [Styles.striken]: (!!oldValue) })}
          >
            {fieldHelper(oldValue)}
          </span>)}
      </>
    );
  };

  const renderFieldsViewMode = () => (
    <div className={Styles.fieldRow} data-testid={`patient-fields-${patient.id}`}>
      <div className={Styles.field}>
        <span className={Styles.label}>{t('firstName')}</span>
        {renderField(patient.firstName, originalPatient.firstName)}
      </div>
      <div className={Styles.field}>
        <span className={Styles.label}>{t('lastName')}</span>
        {renderField(patient.lastName, originalPatient.lastName)}
      </div>
      <div className={Styles.field}>
        <span className={Styles.label}>{t('dob')}</span>
        {renderField(TimeUtils.formatDate(patient.dateOfBirth, TIME_FORMATS.MM_DD_YYYY),
          TimeUtils.formatDate(originalPatient.dateOfBirth, TIME_FORMATS.MM_DD_YYYY))}
      </div>
      <div className={Styles.field}>
        <span className={Styles.label}>{labelMrn}</span>
        {renderField(patient.mrn, originalPatient.mrn)}
      </div>
    </div>
  );

  const renderEditableFieldsEditMode = () => (
    <div className="settings-override" data-testid={`edit-patient-fields-${patient.id}`}>
      <div className={Styles.editModeFields}>
        <div className={Styles.editModeField}>
          <TextInput
            dataAttributes={{ testid: `patient-first-name-{${patient.id}` }}
            error={validateNotEmpty(firstName)}
            label={t('firstName')}
            name="firstName"
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            value={firstName}
          />
        </div>

        <div className={Styles.editModeField}>
          <TextInput
            dataAttributes={{ testid: `patient-last-name-${patient.id}` }}
            error={validateNotEmpty(lastName)}
            label={t('lastName')}
            name="lastName"
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            value={lastName}
          />
        </div>

        <div className={classNames({
          [Styles.editModeField]: true, [Styles.notValid]: validateDob(dateOfBirth) })}
        >
          <DobPicker
            allowFutureDates={false}
            dataAttributes={{ testid: `patient-dob-${patient.id}` }}
            initialDate={dateOfBirth ? moment.utc(dateOfBirth).format('YYYY-MM-DD') : undefined}
            label={t('dateOfBirth')}
            onChange={(dob) => setDateOfBirth(dob + 'T00:00:00.000Z')}
            placeholders={dobPlaceholders}
            locale={i18next.language}
          />

          <div className={Styles.errorMessage}>{validateDob(dateOfBirth)}</div>
        </div>
        <div className={Styles.editModeField}>
          <TextInput
            dataAttributes={{ testid: `patient-mrn-${patient.id}` }}
            label={labelMrn}
            name="mrn"
            onChange={(e) => setMrn(e.target.value)}
            placeholder={exampleMrn ?? ''}
            type="text"
            value={mrn ?? ''}
          />
        </div>
      </div>
    </div>);

  const renderActionTag = () => {
    const actionText = (action === PATIENT_ACTIONS.KEEP) ? t('keep') : t('remove');
    return (
    <Tag
      key={`action-${patient.id}`}
      id={`action-${patient.id}`}
      className={classNames(Styles.tag, { [Styles.tagRemove]: (action === PATIENT_ACTIONS.REMOVE),
        [Styles.tagKeep]: (action === PATIENT_ACTIONS.KEEP),
       })}
    >
      {actionText}
    </Tag>
  );
};

  const renderDevices = () => {
    if (patient.deviceData?.length) {
      return patient.deviceData.map((device, index) => (
        <div className={Styles.fieldRow} key={index}>
          <span className={Styles.deviceName}>{fieldHelper(device.name)}</span>
          <span className={Styles.deviceSync}>{TimeUtils.formatDate(device.lastSyncTimestamp, TIME_FORMATS.MMM_D_YYYY_H_MM_A)}</span>
        </div>
      ));
    } else {
      return (
        <div className={Styles.fieldRow}>
          <span className={Styles.deviceName}>{fieldHelper(null)}</span>
          <span className={Styles.deviceSync}>{fieldHelper(null)}</span>
        </div>
      );
    }
  };

  const renderAction = () => (
    <div className={Styles.actionCard}>
      <div className={Styles.header}>{t('action')}
        <div
          className={Styles.tooltipdiv}
        >
          <img
            dir={getTextDirection()}
            src={Images.helpCircleIcon}
            alt="actions-info"
            data-tooltip-float={false}
            data-tooltip-hidden={false}
            data-tooltip-id="my-tooltip"
            data-tooltip-place="top"
            data-tooltip-variant='light'
          />

          <Tooltip className={Styles.Tooltip} id="my-tooltip" testid="tooltip-test-id">
            <div className={Styles.tooltipTable}>
              <div className={Styles.actionHeader}>{t('action')}</div>

              <div className={Styles.description}>
                <div className={Styles.subHeader}>{t('keep')}</div>
                <div className={Styles.subHeaderDescription}>{t('keepDescription')}</div>
              </div>
              <div className={Styles.description}>
                <div className={Styles.subHeader}>{t('remove')}</div>
                <div className={Styles.subHeaderDescription}>{t('removeDescription', { clinicName: pgsName })}</div>
              </div>

              <div className={Styles.description}>
                <div className={Styles.subHeaderDescription}>{t('accountActivationImpact')}</div>

                <ul className={Styles.bulletList}>
                  <li>
                    <div className={Styles.bulletPointTitle}>
                      {t('patientAccountNotActivated')}
                    </div>
                    <div>
                      {t('patientAccountNotActivatedDescription')}
                    </div>
                  </li>

                  <li>
                    <div className={Styles.bulletPointTitle}>
                      {t('patientAccountActivated')}
                    </div>
                    <div>
                      {t('patientAccountActivatedDescription', { clinicName: pgsName })}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={Styles.actions}>
        <Radio
          label={t('keep')}
          name={`${patient.id}-keep`}
          dataAttributes={{ testid: `keep-${patient.id}` }}
          checked={(action === PATIENT_ACTIONS.KEEP)}
          onChange={onClickAction}
          value={PATIENT_ACTIONS.KEEP}
        />
        <Radio
          label={t('remove')}
          name={`${patient.id}-remove`}
          dataAttributes={{ testid: `remove-${patient.id}` }}
          checked={(action === PATIENT_ACTIONS.REMOVE)}
          onChange={onClickAction}
          value={PATIENT_ACTIONS.REMOVE}
        />
      </div>
      <div className={Styles.actionSubtext}>
        <span>{t('thisAccountWillBe')}</span>{' '}<strong>{action === PATIENT_ACTIONS.KEEP ? t('kept') : t('deleted')}</strong>.
      </div>
    </div>
  );

  return (
    <>
    <div
      className={classNames({ [Styles.DuplicatePatientItem]: true, [Styles.editMode]: isEditing })}
      key={`${patient.id}`}
      data-testid={`duplicate-patient-${patient.id}`}
    >
      <div
        className={classNames({
          [Styles.patientCard]: true, [Styles.cardError]: !isValid() || error })}
        data-testid={`duplicate-patient-card-${patient.id}`}
      >
        <div className={Styles.header}>
          <span className={Styles.fullName}>
            <span>{patient.firstName}</span>{' '}
            <span>{patient.lastName}</span>
          </span>
          <Button
            dataAttributes={{ testid: `patient-profile-${patient.id}` }}
            variation='link'
            onClick={handleProfile}
          >{t('profile')}
          </Button>
          {mode === Mode.REVIEW && <div className={Styles.actionTag}>{renderActionTag()}</div> }
        </div>
        <div className={Styles.fieldRows}>
          {isEditing ? renderEditableFieldsEditMode() : renderFieldsViewMode()}
          <div className={Styles.fieldRow}>
            <div className={Styles.field}>
              <span className={Styles.label}>{t('status')}</span>
              <span className={Styles.value}>{fieldHelper(patient.status)}</span>
            </div>
            <div className={classNames(Styles.field, Styles.email)}>
              <span className={Styles.label}>{t('email')}</span>
              <span className={Styles.value}>{fieldHelper(patient.email)}</span>
            </div>
          </div>
          <div className={Styles.fieldRow}>
            <div className={Styles.devices}>
              <div className={Styles.fieldRow}>
                <span className={classNames(Styles.label, Styles.deviceName)}>{t('devices')}</span>
                <span className={classNames(Styles.label, Styles.deviceSync)}>{t('lastSync')}</span>
              </div>
              {renderDevices()}
            </div>
            {(mode === Mode.EDIT && isEditing) && (
              <div className={Styles.editModeButtons}>
                <Button
                  dataAttributes={{ testid: `patient-cancel-${patient.id}` }}
                  variation='secondary'
                  onClick={handleCancelEdit}
                >{t('cancel')}
                </Button>
                <Button
                  dataAttributes={{ testid: `patient-save-${patient.id}` }}
                  variation='primary'
                  onClick={handleSave}
                  disabled={!isValid()}
                >
                  {t('save')}
                </Button>
              </div>)}
            {(mode === Mode.EDIT && !isEditing) && (
              <div className={Styles.editButton}>
                <Button
                  dataAttributes={{ testid: `patient-edit-${patient.id}` }}
                  variation='secondary'
                  onClick={handleEdit}
                >
                  <Icon name='edit' />
                  {t('edit')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {(mode !== Mode.REVIEW) && renderAction()}
    </div>
    {error &&
      <div className={Styles.errorMessage} data-testid={`edit-patient-mrn-error-${patient.id}`}>
        {getErrorMessage(error)}
      </div>}
    </>
  );
};

export default translate('DuplicatePatientFix')(DuplicatePatientItem);
