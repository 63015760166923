import queryString from 'query-string';
import ExecuteApiCall from './apiClient';

export function getParamsFromUrl() {
  const queryParams = queryString.parse(location.search);
  const patientParam = queryParams.patient || queryParams.patientID;
  return patientParam ? { patient: patientParam } : {};
}

export function fetchCurrentUsers() {
  const params = getParamsFromUrl();
  return ExecuteApiCall('get', 'api/v3/session/users', params);
}

export function fetchPatientMrn() {
  const params = getParamsFromUrl();
  return ExecuteApiCall('get', 'api/v3/patient/mrn', params);
}

export function updatePatient(patient, patientParams) {
  return ExecuteApiCall('put', 'api/v2/users/update', {
    user: patientParams,
    patient,
  });
}

export function updateHidePatientList(status) {
  return ExecuteApiCall('put', 'api/v3/users/hide_patient_list', { status });
}

export function updatePatientInvite(params) {
  return ExecuteApiCall('put', 'api/v3/users/invite', params);
}

export function sendActivationEmail(patient) {
  return ExecuteApiCall('post', 'api/v2/users/send_activation_email', {
    patient,
  });
}

export function sendGuardianEmail() {
  return ExecuteApiCall('put', 'api/v3/users/resend_guardian_consent');
}

export function getSimilarPatientList(firstName, lastName) {
  return ExecuteApiCall('post', 'api/v2/population_tracker/similar_names', {
    firstName,
    lastName,
  });
}

export function updateAssignCareProgram(payload) {
  return ExecuteApiCall('post', 'api/v3/provider_group_site/care_programs/assign', payload);
}

export function updateUnAssignCareProgram(payload) {
  return ExecuteApiCall('post', 'api/v3/provider_group_site/care_programs/unassign', payload);
}

export function getUserEncryptedData(professional) {
  return ExecuteApiCall('post', 'api/v3/users/academy_payload', professional);
}

export function updatePregnancyDueDate(patient, dueDate) {
  return ExecuteApiCall(
    'put',
    'api/v3/users/pregnancy_due_date',
    {
      patient,
      dueDate,
    },
  );
}

export function updatePatientProfile(profile, patientGlookoCode, reasonForChange) {
  const payload = {
    ...profile,
  };
  if (patientGlookoCode) {
    payload.patient = patientGlookoCode;
  }
  if (reasonForChange?.length > 0) {
    payload.reasonForChange = reasonForChange;
  }
  return ExecuteApiCall(
    'put',
    'api/v3/patient/settings/update',
    payload,
  );
}

export function updateProviderProfile(profile) {
  return ExecuteApiCall(
    'post',
    'api/v3/professional/settings/update',
    profile,
  );
}

export function savePatientDeduplication(payload) {
  return ExecuteApiCall('put', 'api/v2/population_tracker/patient_deduplication', payload);
}
