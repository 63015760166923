import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Readings from '~/services/Readings';
import { localizedPercentage } from '~/utils/i18nFormats';
import { translate } from 'bundles/shared/components/WithTranslate/WithTranslate';
import Style from './SingleGlucoseColumnLabels.scss';

export const SingleGlucoseColumnLabels = (props) => {
  const {
    data, meterUnits, inRangeMin, inRangeMax, t,
    showColorLegend, showName, showRange, showValue, summaryPageStatsMain, inSummaryPdfCgm,
  } = props;
  const denormalizedMin = Readings.denormalizedValue(inRangeMin);
  const denormalizedMax = Readings.denormalizedValue(inRangeMax);
  const labels = [
    {
      name: 'veryHighPercentage',
      displayName: t('veryHighPercentage'),
      percentage: data.veryHighPercentage || 0,
      range: { min: 250, max: undefined },
    },
    {
      name: 'highPercentage',
      displayName: t('highPercentage'),
      percentage: data.highPercentage,
      range: { min: denormalizedMax, max: 250, minExclude: true },
    },
    {
      name: 'normalPercentage',
      displayName: t('normalPercentage'),
      percentage: data.normalPercentage,
      range: { min: denormalizedMin, max: denormalizedMax },
    },
    {
      name: 'lowPercentage',
      displayName: t('lowPercentage'),
      percentage: data.lowPercentage,
      range: { min: 54, max: denormalizedMin, maxExclude: true },
    },
    {
      name: 'veryLowPercentage',
      displayName: t('veryLowPercentage'),
      percentage: data.veryLowPercentage || 0,
      range: { min: undefined, max: 54 },
    },
  ];
  const getPercentageClasses = (labelName) => (
    classNames({
      [Style.percentageCell]: true,
      [Style.percentage]: true,
      [Style[labelName]]: true,
      [Style.labelsSummaryPdfCgm]: inSummaryPdfCgm,
    })
  );

  const getRowClasses = (labelName) => (
    classNames({
      [Style.labelRow]: true,
      [Style.inSummary]: summaryPageStatsMain,
      [Style.extendedRow]: labelName === 'normalPercentage',
    })
  );

  const labelRows = labels.map((label) => (
    <div className={getRowClasses(label.name)} key={label.name}>
      {showColorLegend &&
        <div
          className={getPercentageClasses(label.name)}
        >
          <svg className={Style.legendColorCircle} height="10" width="10">
            <rect width="10" height="10" />
          </svg>
        </div>}
      {showValue &&
        <div
          className={getPercentageClasses(label.name)}
          style={{ textAlign: 'start', paddingInlineStart: '5px' }}
        >
          {localizedPercentage(label.percentage)}
        </div>}
      {inSummaryPdfCgm && <div className={Style.line} />}
      <div className={Style.rangeCell}>
        {showName &&
          <div className={classNames({
            [Style.labelName]: true,
            [Style.inSummary]: summaryPageStatsMain,
            [Style.inSummaryPdfCgm]: inSummaryPdfCgm,
          })}
          >{t(label.name)}
          </div>}
        {showRange &&
          <div className={classNames({
            [Style.labelRange]: true,
            [Style.inSummary]: summaryPageStatsMain,
            [Style.inSummaryPdfCgm]: inSummaryPdfCgm,
          })}
          >
            &nbsp;
            {`${Readings.displayRangeValue({
              lowVal: label.range.min,
              highVal: label.range.max,
              meterUnits: meterUnits,
              exclusiveLow: label.range.minExclude,
              exclusiveHigh: label.range.maxExclude })}`}
            &nbsp;
          </div>}
      </div>
    </div>
  ));

  return (
    <div className={Style.SingleGlucoseColumnLabels}>
      <div className={classNames({
        [Style.labelsTableContainer]: true,
        [Style.inSummaryPdfCgm]: inSummaryPdfCgm,
      })}
      >
        <div className={Style.labelsTable}>
          {labelRows}
        </div>
      </div>
    </div>
  );
};

SingleGlucoseColumnLabels.propTypes = {
  data: PropTypes.shape({
    count: PropTypes.number,
    veryHighPercentage: PropTypes.number,
    highPercentage: PropTypes.number.isRequired,
    lowPercentage: PropTypes.number.isRequired,
    veryLowPercentage: PropTypes.number,
    normalPercentage: PropTypes.number.isRequired,
  }).isRequired,
  meterUnits: PropTypes.oneOf(Readings.AVAILABLE_METER_UNITS),
  inRangeMin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  inRangeMax: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  showColorLegend: PropTypes.bool,
  showName: PropTypes.bool,
  showRange: PropTypes.bool,
  showValue: PropTypes.bool,
  summaryPageStatsMain: PropTypes.bool,
  inSummaryPdfCgm: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

SingleGlucoseColumnLabels.defaultProps = {
  data: {
    count: 0,
    veryHighPercentage: 0,
    highPercentage: 0,
    lowPercentage: 0,
    veryLowPercentage: 0,
    normalPercentage: 0,
  },
  meterUnits: 'mgdl',
  showColorLegend: true,
  showName: true,
  showRange: true,
  showValue: true,
  summaryPageStatsMain: false,
  inSummaryPdfCgm: false,
  t: (s) => s,
};

export default translate('SingleGlucoseColumnLabels')(SingleGlucoseColumnLabels);
