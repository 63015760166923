import MathUtils from 'utils/MathUtils/MathUtils';
import Readings from 'services/Readings';
import { TYPE_CGM } from 'bundles/shared/constants/readings';

const DEFAULT_COEFFICIENT = 1.25;

export const useGraphAxis = () => ({
  carbsAxisMax,
  exerciseAxisMax,
  stepsAxisMax,
  insulinAxisMax,
  bgAxisMax,
  bolusAxisMax,
  basalAxisMax,
  bgAxisDisplayValue,
});

const carbsAxisMax = (series: GraphSeries[]) => axisPerXEndIndex(series, DEFAULT_COEFFICIENT, 150);
const exerciseAxisMax = (series: GraphSeries[]) => roundToHighest(axisPerXEndIndex(series, DEFAULT_COEFFICIENT, 10));
const stepsAxisMax = (series: GraphSeries[]) => roundToHighest(axisPerXEndIndex(series, DEFAULT_COEFFICIENT, 10));
const insulinAxisMax = (series: GraphSeries[]) => axisPerXEndIndex(series, DEFAULT_COEFFICIENT, 30);
const bolusAxisMax = (series: GraphSeries[], coefficient: number = 1.1) => axisEndIndex(series, coefficient, 5, 5) * 5;
const basalAxisMax = (series: GraphSeries[]) => axisEndIndex(series, 1.9, 2);
const bgAxisMax = (meterUnits: MeterUnits) => Math.round(bgAxisDisplayValue(meterUnits) * 1.08);
const bgAxisDisplayValue = (meterUnits: MeterUnits) => Readings.displayValue(Readings.LIMITS[TYPE_CGM].hi, meterUnits);
const roundToHighest = (value: number) => MathUtils.ceilTo2HighestDigits(value);

const axisEndIndex = (series: GraphSeries[], coefficient: number, defaultValue: number, dividedBy: number = 1) => {
  const seriesMax = maxValueInSeries(series);
  return Math.ceil(((seriesMax * coefficient) || defaultValue) / dividedBy);
};

const axisPerXEndIndex = (series: GraphSeries[], coefficient: number, defaultValue: number) => {
  const seriesMax = maxValuePerXInSeries(series);
  return Math.ceil(seriesMax * coefficient) || defaultValue;
};

const maxValueInSeries = (series: GraphSeries[]) => {
  const yCoordinates = series.flatMap(({ data }) => data?.map(({ y }) => y ?? 0) ?? 0);
  return yCoordinates.length > 0 ? Math.max(...yCoordinates) : 0;
};

const maxValuePerXInSeries = (series: GraphSeries[]) => {
  const sums = series.reduce<{ [key: number]: number }>((accumulator, { data }) => {
    data?.forEach(({ x, y }) => {
      accumulator[x] = (accumulator[x] ?? 0) + (y ?? 0);
    });
    return accumulator;
  }, {});

  const sumValues = Object.values(sums);
  return sumValues.length > 0 ? Math.max(...sumValues) : 0;
};
