/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Images from '~/utils/ImageStore';
import { Button } from '@glooko/common-ui';
import moment from 'moment';
import Style from '../PartnerIntegrationsPresenter/PartnerIntegrationsPresenter.scss';
import { GreyOverlay } from '../../../../shared/components/Overlay/Overlay';
import { disconnectFromIntegration } from '../../../../../services/partnerIntegrationsApi';
import { TIME_FORMATS } from '../../../../../utils/i18nFormats';
import {
  POPUP_DISCONNECT_FAILED,
  POPUP_DISCONNECT_SUCCEEDED,
} from '../PartnerIntegrationsPresenter/PartnerIntegrationsPresenter';
import useSanitizer from 'hooks/useSanitizer';

@translate('PartnerIntegrationsPresenter')
export default class PopupDisconnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const { actions, data, setPopupState, closePopup, t } = this.props;
    const { fetchPartnerIntegrationsThunk } = actions;

    const onDisconnecting = () => {
      if (data.integration === 'INSULET_OMNIPOD_5_CLOUD') {
        window.open(window.SupportLinks.article('10721599141011'), '_blank');
        return;
      }

      this.setState({ loading: true });

      disconnectFromIntegration(data.integration)
        .then(() => fetchPartnerIntegrationsThunk())
        .then(() => setPopupState(POPUP_DISCONNECT_SUCCEEDED))
        .catch(() => setPopupState(POPUP_DISCONNECT_FAILED));
    };

    const sanitizer = useSanitizer();

    return (
      <div>
        <GreyOverlay />
        {!this.state.loading && (
          <div className={Style.popup}>
            <div
              className={Style.closeButton}
              role="button"
              tabIndex={0}
              onClick={closePopup}
            >
              <img src={Images.closeButton} alt="x" />
            </div>

            <div className={Style.innerPopup}>
              <div className={Style.header}>
                <div className={classNames(Style.brandImage)}>
                  <img src={data.iconUrl} alt={data.name} />
                </div>
                <span>{data.name}</span>
              </div>

              <div className={Style.content}>
                <p dangerouslySetInnerHTML={{ __html: sanitizer(data.text) }} />
                <table>
                  <tbody>
                    <tr>
                      <td>{t('connectedSince')}:</td>
                      <td>
                        {moment(data.startDate).format(TIME_FORMATS.MMMM_DO_YYYY_H_MMA)}
                      </td>
                    </tr>

                    {data.latestTransferAttempt && (
                      <tr>
                        <td>{t('lastSync')}:</td>
                        <td>
                          {moment(data.latestTransferAttempt).format(TIME_FORMATS.MMMM_DO_YYYY_H_MMA)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className={Style.actions}>
                <Button
                  variation='secondary'
                  onClick={onDisconnecting}
                  dataAttributes={{ testid: 'popup-disconnect-disconnect' }}
                >
                  {t('disconnect')}
                </Button>
                <Button
                  onClick={closePopup}
                  dataAttributes={{ testid: 'popup-disconnect-ok' }}
                >
                  {t('ok')}
                </Button>
              </div>
            </div>
          </div>
        )}
        {this.state.loading && (
          <div className={Style.popup}>
            <div className={Style.innerPopup}>
              <div className={Style.loading}>
                <svg viewBox="0 0 100 100">
                  <circle cx="50" cy="50" r="45" />
                </svg>
                <span>{t('disconnecting')}...</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PopupDisconnect.propTypes = {
  actions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setPopupState: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

PopupDisconnect.defaultProps = {
  actions: {},
  data: {},
  setPopupState: /* istanbul ignore next */ () => {},
  closePopup: /* istanbul ignore next */ () => {},
  t: /* istanbul ignore next */ () => {},
};

