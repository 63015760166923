import ExecuteApiCall from './apiClient';

export function addProConnection(code) {
  return ExecuteApiCall('post', 'api/v2/pro_connect', { code: code });
}
export function deleteProConnection(code) {
  return ExecuteApiCall('delete', 'api/v2/pro_connect', { code: code });
}
export function updatePrimarySite(code) {
  return ExecuteApiCall('post', 'api/v2/pro_connect/primary', { code: code });
}
