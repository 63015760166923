import { PATH_SIGN_OUT, PATH_SETTINGS } from '../bundles/shared/constants/navigation';

export async function logout() {
  const isPopulationInsightsFilter = localStorage.getItem('isPopulationInsightsFilter');
  if (isPopulationInsightsFilter === 'true') {
    await clearPopInsightsFilters();
  }
  window.location.assign(PATH_SIGN_OUT);
}

export function profileUrl(patientId) {
  if (!patientId) {
    return PATH_SETTINGS;
  }
  return `${PATH_SETTINGS}?patient=${patientId}`;
}
