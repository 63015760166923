export const bgGraphSeriesName = [
  'bgLow', 'bgHigh', 'bgNormal',
  'bgLowManual', 'bgHighManual', 'bgNormalManual',
  'bgAbove400', 'bgAbove400Manual',
];
export const bgGraphAverageName = ['bgDailyAverage'];
export const bgGraphPercentilesName = ['bgDailyPercentile10To90', 'bgDailyPercentile25To75'];
export const cgmGraphSeriesName = [
  'cgmLow', 'cgmHigh', 'cgmNormal',
  'cgmCalibrationLow', 'cgmCalibrationNormal', 'cgmCalibrationHigh',
];
export const cgmGraphAverageName = ['cgmDailyAverage'];
export const cgmGraphPercentilesName = ['cgmDailyPercentile10To90', 'cgmDailyPercentile25To75'];
export const insulinSeriesName = [
  'injectionBolus', 'deliveredBolus', 'gkInsulin', 'gkInsulinBolus', 'gkInsulinBasal',
  'gkInsulinPremixed', 'gkInsulinOther',
];

export const insulinTotalsName = [
  'totalInsulinPerDay', 'basalUnitsPerDay', 'bolusUnitsPerDay',
];

export const insulinPumpSeriesNameProcessed = [
  'insulinBolusColumnNoPointWidth',
  'insulinBasalColumnNoPointWidth',
  'insulinPremixedColumnNoPointWidth',
];

export const insulinManualSeriesNameProcessed = [
  'gkInsulinBolusColumnNoPointWidth',
  'gkInsulinBasalColumnNoPointWidth',
  'gkInsulinPremixedColumnNoPointWidth',
  'gkInsulinOtherColumnNoPointWidth',
];

export const insulinSeriesNameProcessed = [
  ...insulinPumpSeriesNameProcessed,
  ...insulinManualSeriesNameProcessed,
];

export const insulinInlinePumpSeriesNameProcessed = ['insulinAllInlinePump'];
export const insulinInlineManualSeriesNameProcessed = ['insulinAllInlineManual'];

export const carbsSeriesName = ['carbAll'];
export const carbsSeriesNameProcessed = ['carbAllColumnNoPointWidth'];
export const carbsSeriesNamePdfProcessed = ['carbAllInline'];
export const exerciseSeriesNameProcessed = ['exerciseColumnNoPointWidth'];
export const stepsSeriesNameProcessed = ['stepsColumnNoPointWidth'];

export const exerciseSeriesNamePdfProcessed = ['exerciseInline'];
export const stepsSeriesNamePdfProcessed = ['stepsInline'];
