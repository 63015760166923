import classNames from 'classnames';
import InsulinGraph from 'bundles/graphs/components/Graphs/InsulinGraph/InsulinGraph';
import WithTranslate from 'bundles/shared/components/WithTranslate/WithTranslate';
import OverviewGraphRow from '../../OverviewGraphRow/OverviewGraphRow';
import Style from '../OverviewGraphPresenter.scss';

export interface OverviewGraphInsulinRowProps extends WithTranslationFunction {
  axisWidth: number;
  endTimestamp: string | number;
  inPdf: boolean;
  oneDayWidth: number;
  startTimestamp: string | number;
  configOverride: object;
  fetchStatus: FetchStatus;
  windowWidth: number;
  yAxisMax: number;
  windowHeight: number;
  axisTickStep: number;
  axisEndIndex: number;
  axisTitle: string;
  axisSubtitle: string;
  series: GraphSeries[];
}

const OverviewGraphInsulinRow = ({
  startTimestamp,
  endTimestamp,
  windowHeight,
  axisEndIndex,
  axisTickStep,
  axisTitle,
  axisSubtitle,
  axisWidth,
  oneDayWidth,
  inPdf,
  series,
  windowWidth,
  yAxisMax,
  fetchStatus,
  configOverride,
}: OverviewGraphInsulinRowProps) => (
  <OverviewGraphRow
    axisEndIndex={axisEndIndex}
    axisStartIndex={0}
    axisSubtitle={axisSubtitle}
    axisTickStep={axisTickStep}
    axisTitle={axisTitle}
    axisWidth={axisWidth}
    endTimestamp={endTimestamp}
    inPdf={inPdf}
    oneDayWidth={oneDayWidth}
    startTimestamp={startTimestamp}
    windowHeight={windowHeight}
  >
    <div className={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
      <InsulinGraph
        configOverride={configOverride}
        endTimestamp={endTimestamp}
        fetchStatus={fetchStatus}
        series={series}
        startTimestamp={startTimestamp}
        windowHeight={windowHeight}
        windowWidth={windowWidth}
        yAxisMax={yAxisMax}
      />
    </div>
  </OverviewGraphRow>
);

export default WithTranslate('OverviewGraphPresenter')(OverviewGraphInsulinRow);
