import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import useSanitizer from '~/hooks/useSanitizer';
import Style from './FileUploaderPopup.scss';
import { translate } from '../WithTranslate/WithTranslate.jsx';
import Images from '../../../../utils/ImageStore';
import SupportLinks from '../../../../utils/support';

const FileUploaderPopupFailed = (props) => {
  const { t, onClosePopup, onTryAgain, acceptedExtensions } = props;
  const sanitizer = useSanitizer();

  const extensionsString = acceptedExtensions.join(' / ');

  return (
    <Dialog open={true} size="medium" onClose={onClosePopup} dataAttributes={{ testid: 'file-uploader-failed' }}>
      <DialogHeader dataAttributes={{ testid: 'file-uploader-failed' }}>
        <h2>{t('uploadErrorTitle')}</h2>
      </DialogHeader>
      <DialogContent dataAttributes={{ testid: 'file-uploader-failed' }}>
        <div className={Style.failed}>
          {t('uploadErrorMsgTitle')}
          <img src={Images.integrationTickFailure} alt="" />
        </div>
        <p>{t('uploadErrorMsgLine1', { extensionsString })}</p>
        <p dangerouslySetInnerHTML={{ __html: sanitizer(t('havingProblems', { url: SupportLinks.fileTicket() })) }} />
      </DialogContent>
      <DialogActions dataAttributes={{ testid: 'file-uploader-failed' }}>
        <Button
          variation="secondary"
          onClick={onClosePopup}
          dataAttributes={{ testid: 'file-uploader-failed-upload-error-close' }}
        >
          {t('uploadErrorCloseButton')}
        </Button>
        <Button
          onClick={onTryAgain}
          dataAttributes={{ testid: 'file-uploader-failed-upload-error-try-again' }}
        >
          {t('uploadErrorTryAgainButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FileUploaderPopupFailed.propTypes = {
  t: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  onTryAgain: PropTypes.func.isRequired,
  acceptedExtensions: PropTypes.array.isRequired,
};

FileUploaderPopupFailed.defaultProps = {
  t: /* istanbul ignore next */ () => {},
  onClosePopup: /* istanbul ignore next */ () => {},
  onTryAgain: /* istanbul ignore next */ () => {},
  acceptedExtensions: [],
};

export default translate('FileUploaderPopup')(FileUploaderPopupFailed);
