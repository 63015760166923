class SearchBarUtils {
  static getSearchQueryFromStorage() {
    return JSON.parse(sessionStorage.getItem('searchQuery'));
  }

  static saveSearchQueryToStorage(searchQuery) {
    window.sessionStorage.setItem('searchQuery', JSON.stringify(searchQuery));
  }

  static clearPatientsListViewFromStorage() {
    window.sessionStorage.removeItem('patients_list_view');
  }

  static hasSearchQuery() {
    const searchQuery = this.getSearchQueryFromStorage()?.value;
    return !!searchQuery && searchQuery.length > 0;
  }
}

export default SearchBarUtils;
