import React from 'react';
import PropTypes from 'prop-types';
import { DobPicker } from '@glooko/common-ui';
import i18next from 'i18next';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Style from './SearchPatientInput.scss';

const SearchPatientInput = (props) => {
  const {
    t,
    setDob,
    updateFormData,
  } = props;

  const dobPlaceholders = { day: t('day'), month: t('month'), year: t('year') };

  return (
    <div>
      <div
        class={Style.fieldContainer}
      >
        * {t('firstName')}
        <input
          name="firstName"
          class={Style.inputField}
          onChange={updateFormData}
          placeholder={t('patientFirstName')}
        />
      </div>
      <div
        class={Style.fieldContainer}
      >
        * {t('lastName')}
        <input
          name="lastName"
          class={Style.inputField}
          onChange={updateFormData}
          placeholder={t('patientLastName')}
        />
      </div>
      <div
        name="dob"
        class={Style.fieldContainer}
      >
        * {t('birthdate')}
        <DobPicker
          name='dob'
          onChange={setDob}
          placeholders={dobPlaceholders}
          locale={i18next.language}
        />
      </div>
      <div class={Style.fieldContainer}>
        {t('mrnOptional')}
        <input
          name="mrn"
          class={Style.inputField}
          onChange={updateFormData}
        />
      </div>
    </div>
  );
};

SearchPatientInput.propTypes = {
  t: PropTypes.func.isRequired,
  setDob: PropTypes.func.isRequired,
  updateFormData: PropTypes.func.isRequired,
};

export default translate('SearchPatientInput')(SearchPatientInput);
