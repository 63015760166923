import deepMerge from 'deepmerge';
import { trackAssignToPatientClicked } from '~/services/eventLogging';
import { FETCH_STATUS_SUCCESSFUL, FETCH_STATUS_FAILED, FETCH_STATUS_IN_PROGRESS } from '~/bundles/shared/constants/graphs';

export const PGS_FETCH_SUCCESS = 'PGS_FETCH_SUCCESS';
export const PGS_FILTER_LIST_FETCHED = 'PGS_FILTER_LIST_FETCHED';
export const UPDATE_FILTERS_LIST = 'UPDATE_FILTERS_LIST';
export const UPDATE_SUBSCRIPTION_MODEL = 'UPDATE_SUBSCRIPTION_MODEL';
export const UPDATE_TERMINAL_SYNCS = 'UPDATE_TERMINAL_SYNCS';
export const UPDATE_SERIAL_NUMBER_CACHE = 'UPDATE_SERIAL_NUMBER_CACHE';
export const UPDATE_TERMINAL_SYNC = 'UPDATE_TERMINAL_SYNC';
export const UPDATE_PATIENT_EMAIL = 'UPDATE_PATIENT_EMAIL';
export const UPDATE_PGS_SETTING_SUCCESS = 'UPDATE_PGS_SETTING_SUCCESS';
export const PATIENT_SEARCH_RESULTS = 'PATIENT_SEARCH_RESULTS';
export const UPDATE_HIDE_BANNER = 'UPDATE_HIDE_BANNER';
export const UPDATE_SUGGESTED_RESULTS = 'UPDATE_SUGGESTED_RESULTS';
export const UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS';
export const UPDATE_SEARCH_STATUS = 'UPDATE_SEARCH_STATUS';
export const PROVIDER_PATIENTS_HAS_ERRORED = 'PROVIDER_PATIENTS_HAS_ERRORED';
export const PROVIDER_PATIENTS_IN_PROGRESS = 'PROVIDER_PATIENTS_IN_PROGRESS';
export const PROVIDER_PATIENTS_FETCHED = 'PROVIDER_PATIENTS_FETCHED';
export const PROFFESIONAL_TAGS_HAS_ERRORED = 'PROFFESIONAL_TAGS_HAS_ERRORED';
export const PROFFESIONAL_TAGS_IN_PROGRESS = 'PROFFESIONAL_TAGS_IN_PROGRESS';
export const PROFFESIONAL_TAGS_FETCHED = 'PROFFESIONAL_TAGS_FETCHED';
export const FETCH_PATIENT_TAGS_IN_PROGRESS = 'FETCH_PATIENT_TAGS_IN_PROGRESS';
export const FETCH_PATIENT_TAGS_SUCCESS = 'FETCH_PATIENT_TAGS_SUCCESS';
export const FETCH_PATIENT_TAGS_HAS_ERRORED = 'FETCH_PATIENT_TAGS_HAS_ERRORED';
export const TAGS_UPDATE_SAVED = 'TAGS_UPDATE_SAVED';
export const TAGS_UPDATE_IN_PROGRESS = 'TAGS_UPDATE_IN_PROGRESS';
export const TAGS_UPDATE_HAS_ERRORED = 'TAGS_UPDATE_HAS_ERRORED';
export const UPDATE_PATIENT_CAREPROGRAMS_STATE = 'UPDATE_PATIENT_CAREPROGRAMS_STATE';
export const PATIENT_CAREPROGRAMS_PROGRESS = 'PATIENT_CAREPROGRAMS_PROGRESS';
export const RESET_UPDATE_PATIENT_ICPA_DATA_STATUS = 'RESET_UPDATE_PATIENT_ICPA_DATA_STATUS';
export const UPDATE_PATIENT_ICPA_DATA_IN_PROGRESS = 'UPDATE_PATIENT_ICPA_DATA_IN_PROGRESS';
export const UPDATE_PATIENT_ICPA_DATA_SUCCESS = 'UPDATE_PATIENT_ICPA_DATA_SUCCESS';
export const UPDATE_PATIENT_ICPA_DATA_FAILED = 'UPDATE_PATIENT_ICPA_DATA_FAILED';
export const RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_IN_PROGRESS = 'RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_IN_PROGRESS';
export const RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_SUCCESS = 'RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_SUCCESS';
export const RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_FAILED = 'RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_FAILED';
export const UNEXPECTED_TERMINAL_SYNC_FAIL = 'UNEXPECTED_TERMINAL_SYNC_FAIL';
export const UPDATE_CREATED_POPUP_VISIBILITY = 'UPDATE_CREATED_POPUP_VISIBILITY';
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_FAIL = 'CREATE_PATIENT_FAIL';

export const CURRENT_PATIENT_ICPA_DATA_FETCH_SUCCESS = 'CURRENT_PATIENT_ICPA_DATA_FETCH_SUCCESS';
export const CURRENT_PATIENT_ICPA_DATA_FETCH_FAILED = 'CURRENT_PATIENT_ICPA_DATA_FETCH_FAILED';

export function providerGroupSiteFetchSuccess(providerGroupSite) {
  return { type: PGS_FETCH_SUCCESS, providerGroupSite };
}

export const filterListFetched = (filters) => ({
  type: PGS_FILTER_LIST_FETCHED, payload: { filters },
});

export function updateFiltersList(filters) {
  return { type: UPDATE_FILTERS_LIST, payload: { filters } };
}

export function patientSearchResults(patients, status) {
  return { type: PATIENT_SEARCH_RESULTS, patients, status };
}

export function updateSubscriptionModel(subscription) {
  return { type: UPDATE_SUBSCRIPTION_MODEL, subscription };
}

export function updateTerminalSyncs(terminalSyncs) {
  return { type: UPDATE_TERMINAL_SYNCS, terminalSyncs };
}

export function updateSerialNumberCache(terminalSyncs) {
  return { type: UPDATE_SERIAL_NUMBER_CACHE, terminalSyncs };
}

export function updateTerminalSync(terminalSync, rowId, assign) {
  return { type: UPDATE_TERMINAL_SYNC, terminalSync, rowId, assign };
}

export function updatePatientEmail(rowId) {
  return { type: UPDATE_PATIENT_EMAIL, rowId };
}

export function providerGroupSiteSettingUpdateSuccess(requiredField) {
  return { type: UPDATE_PGS_SETTING_SUCCESS, requiredField };
}

export function createPatientSuccess(results, status) {
  return { type: CREATE_PATIENT_SUCCESS, results, status };
}

export function createPatientError(error, status) {
  return { type: CREATE_PATIENT_FAIL, error, status };
}

export function updateHideBanner(rowId) {
  return { type: UPDATE_HIDE_BANNER, rowId };
}

export function updateSuggestedResults(results) {
  return { type: UPDATE_SUGGESTED_RESULTS, results };
}

export function updateSearchResults(results) {
  return { type: UPDATE_SEARCH_RESULTS, results };
}

export function updateSearchStatus() {
  return { type: UPDATE_SEARCH_STATUS };
}

export function providerPatientsFetched(payload) {
  return { type: PROVIDER_PATIENTS_FETCHED, payload };
}

export function providerPatientsHasErrored(params) {
  return { type: PROVIDER_PATIENTS_HAS_ERRORED, params };
}

export function providerPatientsInProgress(params) {
  return { type: PROVIDER_PATIENTS_IN_PROGRESS, params };
}

export function professionalTagsFetched(payload) {
  return { type: PROFFESIONAL_TAGS_FETCHED, payload };
}

export function professionalTagsHasErrored(params) {
  return { type: PROFFESIONAL_TAGS_HAS_ERRORED, params };
}

export function professionalTagsInProgress(params) {
  return { type: PROFFESIONAL_TAGS_IN_PROGRESS, params };
}

export function fetchPatientTagsInProgress(patientId) {
  return { type: FETCH_PATIENT_TAGS_IN_PROGRESS, patientId };
}
export function fetchPatientTagsSuccess(payload) {
  return { type: FETCH_PATIENT_TAGS_SUCCESS, payload };
}
export function fetchPatientTagsHasErrored() {
  return { type: FETCH_PATIENT_TAGS_HAS_ERRORED };
}

export function updateTagsStatusSaved(payload) {
  return { type: TAGS_UPDATE_SAVED, payload };
}

export function updateTagsStatusHasErrored(bool) {
  return { type: TAGS_UPDATE_HAS_ERRORED, hasErrored: bool };
}

export function updateTagsStatusInProgress(bool) {
  return { type: TAGS_UPDATE_IN_PROGRESS, isProgress: bool };
}

export function updatePatientCareProgramsState(carePrograms) {
  return { type: UPDATE_PATIENT_CAREPROGRAMS_STATE, patientCarePrograms: carePrograms };
}

export function updatePatientCareProgramsInProgress() {
  return { type: PATIENT_CAREPROGRAMS_PROGRESS };
}

export function updatePatientICPADataInProgress() {
  return {
    type: UPDATE_PATIENT_ICPA_DATA_IN_PROGRESS,
  };
}

export function updatePatientICPADataSuccess() {
  return {
    type: UPDATE_PATIENT_ICPA_DATA_SUCCESS,
  };
}

export function updatePatientICPADataFailed() {
  return {
    type: UPDATE_PATIENT_ICPA_DATA_FAILED,
  };
}

export function resetUpdatePatientICPADataStatus() {
  return {
    type: RESET_UPDATE_PATIENT_ICPA_DATA_STATUS,
  };
}

export function recentlySyncedUserICPAData() {
  return {
    type: RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_IN_PROGRESS,
  };
}

export function recentlySyncedUserICPADataSuccess(userData) {
  return {
    type: RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_SUCCESS,
    userData,
  };
}

export function recentlySyncedUserICPADataFailed() {
  return {
    type: RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_FAILED,
  };
}

export function currentPatientICPADataFetchSuccess(userData) {
  return {
    type: CURRENT_PATIENT_ICPA_DATA_FETCH_SUCCESS,
    userData,
  };
}

export function currentPatientICPADataFetchFailed() {
  return {
    type: CURRENT_PATIENT_ICPA_DATA_FETCH_FAILED,
  };
}

export function unexpectedTerminalSyncFail(rowId, failureCode, assign) {
  return { type: UNEXPECTED_TERMINAL_SYNC_FAIL, rowId, failureCode, assign };
}

export function updateCreatedPopupVisibility(createdPopupVisible) {
  return { type: UPDATE_CREATED_POPUP_VISIBILITY, createdPopupVisible };
}

function initialStatusFilters() {
  const sessionFilters = window.sessionStorage.patients_list_view_filters;
  let patientStatuses = [
    { id: 'patientStatuses-Activated', label: 'Activated' },
    { id: 'patientStatuses-Invite_Pending', label: 'Invite_Pending' },
    { id: 'patientStatuses-Not_Invited', label: 'Not_Invited' },
  ];
  const patientStatusSessionFilters = sessionFilters ?
    JSON.parse(sessionFilters).patientStatuses : [];
  patientStatuses = patientStatuses.map(
    (s) => ({ ...s, checked: patientStatusSessionFilters.includes(s.label) }));

  return patientStatuses;
}

const initialState = {
  hypoEnabled: false,
  academyProperty: false,
  subscriptionModel: {
    features: [],
    hideData: false,
    showNote: false,
    kioskSyncPdf: false,
    appStoreFeatures: [],
  },
  devices: {},
  patientSearch: {
    status: '',
  },
  suggestedResults: {},
  searchResults: {
    results: [],
    isSearching: false,
    totalCount: 0,
  },
  filters: {
    tags: [],
    customTags: [],
    statuses: [],
    midsTags: [],
    careProgramTags: [],
    flags: [],
    patientStatuses: initialStatusFilters(),
  },
  authCode: '',
  providerPatients: [],
  status: '',
  paginationData: {
    totalPages: -1,
    currentPage: -1,
    totalCount: 0,
  },
  allTags: {
    status: '',
    tags: [],
  },
  tagsUpdate: {
    tags: [],
    inProgress: false,
    hasErrored: false,
  },
  isPopulationInsightsFilter: false,
  carePrograms: null,
  patientCarePrograms: {
    carePrograms: [],
    status: '',
  },
  selectedPatientICPADataUpdateStatus: '',
  recentlySyncedUsersICPAData: {
    status: '',
    users: {},
  },
  currentPatientICPADetails: {
    userDetails: {},
    status: '',
  },
  lastRunTimestamp: '',
  filtersStatus: '',
  createdPatient: {
    patient: {},
    error: '',
    status: '',
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PGS_FETCH_SUCCESS: {
      const response = deepMerge(state, action.providerGroupSite);
      return {
        ...response,
        carePrograms: action.providerGroupSite.carePrograms,
      };
    }
    case PGS_FILTER_LIST_FETCHED: {
      const newFilters = action.payload.filters;
      const timeStamp = newFilters.lastRunTimestamp;
      delete newFilters.lastRunTimestamp;

      return {
        ...state,
        lastRunTimestamp: timeStamp,
        filtersStatus: PGS_FILTER_LIST_FETCHED,
        filters: {
          ...state.filters,
          ...newFilters,
        },
      };
    }
    case UPDATE_FILTERS_LIST:
      const newFilters = action.payload.filters;
      const timeStamp = newFilters.lastRunTimestamp || state.lastRunTimestamp;
      delete newFilters.lastRunTimestamp;

      return {
        ...state,
        lastRunTimestamp: timeStamp,
        filtersStatus: PGS_FILTER_LIST_FETCHED,
        filters: {
          ...state.filters,
          ...newFilters,
        },
      };
    case UPDATE_SUBSCRIPTION_MODEL:
      return {
        ...state,
        subscriptionModel: {
          ...state.subscriptionModel,
          ...action.subscription,
        },
      };
    case UPDATE_TERMINAL_SYNCS:
      return { ...state, devices: action.terminalSyncs };
    case UPDATE_SERIAL_NUMBER_CACHE:
      return {
        ...state,
        devices: {
          ...state.devices,
          serialNumberCache: action.terminalSyncs.serialNumberCache,
        },
      };
    case UPDATE_TERMINAL_SYNC: {
      if (action.assign) {
        trackAssignToPatientClicked(action.terminalSync);
      }

      const updatedTerminalSyncs =
        state.devices.terminalSyncs.map((terminalSync) => {
          if (terminalSync.id === action.rowId) {
            const updatedTerminalSync = action.terminalSync;
            if (terminalSync.originalSection) {
              updatedTerminalSync.originalSection = terminalSync.originalSection;
            } else {
              updatedTerminalSync.originalSection = terminalSync.syncProcess === 'succeeded' ?
                'recentlyAssigned' : 'unassigned';
            }
            return updatedTerminalSync;
          }
          return terminalSync;
        });

      return {
        ...state,
        devices: {
          ...state.devices,
          terminalSyncs: updatedTerminalSyncs,
        },
      };
    }
    case UNEXPECTED_TERMINAL_SYNC_FAIL: {
      const updatedTerminalSyncs =
        state.devices.terminalSyncs.map((terminalSync) => {
          if (terminalSync.id === action.rowId) {
            return {
              ...terminalSync,
              failureCode: action.failureCode,
              syncProcess: 'failed',
            };
          }
          return terminalSync;
        });

      return {
        ...state,
        devices: {
          ...state.devices,
          terminalSyncs: updatedTerminalSyncs,
        },
      };
    }
    case UPDATE_PATIENT_EMAIL: {
      const updatedTerminalSyncs =
        state.devices.terminalSyncs.map((terminalSync) => {
          if (terminalSync.id === action.rowId) {
            return Object.assign({}, terminalSync, {
              ...terminalSync,
              assignedPatient: {
                ...terminalSync.assignedPatient,
                user: {
                  ...terminalSync.assignedPatient.user,
                  fakeEmail: false,
                },
              },
            });
          }
          return terminalSync;
        });
      return {
        ...state,
        devices: {
          ...state.devices,
          terminalSyncs: updatedTerminalSyncs,
        },
      };
    }
    case UPDATE_HIDE_BANNER: {
      const updatedTerminalSyncs =
        state.devices.terminalSyncs.map((terminalSync) => {
          if (terminalSync.id === action.rowId) {
            return Object.assign({}, terminalSync, {
              ...terminalSync,
              hideBanner: true,
            });
          }
          return terminalSync;
        });
      return {
        ...state,
        devices: {
          ...state.devices,
          terminalSyncs: updatedTerminalSyncs,
        },
      };
    }
    case UPDATE_PGS_SETTING_SUCCESS:
      return {
        ...state,
        ...action.requiredField,
      };
    case CREATE_PATIENT_SUCCESS:
      return {
        ...state,
        createdPatient: {
          ...state.createdPatient,
          patient: action.results.data,
          status: action.status,
        },
      };
    case CREATE_PATIENT_FAIL:
      if (action.error.response.data.error_key) {
        return {
          ...state,
          createdPatient: {
            ...state.createdPatient,
            error: '',
            status: action.status,
          },
        };
      }
      return {
        ...state,
        createdPatient: {
          ...state.createdPatient,
          error: action.error.response.statusText,
          status: action.status,
        },
      };
    case PATIENT_SEARCH_RESULTS:
      return { ...state, patientSearch: { patients: action.patients, status: action.status } };
    case UPDATE_SUGGESTED_RESULTS:
      return { ...state, suggestedResults: action.results };
    case UPDATE_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: {
          isSearching: false,
          results: action.results.results,
          totalCount: action.results.totalCount,
        },
      };
    case UPDATE_SEARCH_STATUS:
      return { ...state, searchResults: { ...state.searchResults, isSearching: true } };
    case PROVIDER_PATIENTS_FETCHED: {
      return {
        ...state,
        paginationData: action.payload.paginationMetadata,
        providerPatients: action.payload.patients,
        status: FETCH_STATUS_SUCCESSFUL,
        isPopulationInsightsFilter:
        action.payload.metadata && action.payload.metadata.populationInsightsFilter &&
        action.payload.metadata.populationInsightsFilter.active ?
          action.payload.metadata.populationInsightsFilter.active : false,
      };
    }
    case PROVIDER_PATIENTS_HAS_ERRORED: {
      return { ...state, providerPatients: FETCH_STATUS_FAILED, status: FETCH_STATUS_FAILED };
    }
    case PROVIDER_PATIENTS_IN_PROGRESS: {
      return {
        ...state,
        providerPatients: [],
        status: FETCH_STATUS_IN_PROGRESS,
      };
    }
    case PROFFESIONAL_TAGS_FETCHED: {
      const providerTags = action.payload.professional.tags.find(
        (tagList) => tagList.category === 'providerTags').tags;
      const customTags = action.payload.professional.tags.find(
        (tagList) => tagList.category === 'customTags').tags;
      return {
        ...state,
        allTags: {
          ...state.allTags,
          status: FETCH_STATUS_SUCCESSFUL,
          tags: [...providerTags, ...customTags],
        },
      };
    }
    case PROFFESIONAL_TAGS_HAS_ERRORED: {
      return {
        ...state,
        allTags: {
          ...state.allTags,
          status: FETCH_STATUS_FAILED,
        },
      };
    }
    case PROFFESIONAL_TAGS_IN_PROGRESS: {
      return {
        ...state,
        allTags: {
          ...state.allTags,
          status: FETCH_STATUS_IN_PROGRESS,
        },
      };
    }
    case FETCH_PATIENT_TAGS_IN_PROGRESS: {
      // find if patient exists otherwise seed it.
      const patient = state.providerPatients.find((p) => p.id === action.patientId);
      if (patient) {
        return {
          ...state,
          tagsUpdate: {
            status: FETCH_STATUS_IN_PROGRESS,
          },
        };
      } else {
        const newPatientSeed = {
          id: action.patientId,
          tags: [],
        };
        return {
          ...state,
          tagsUpdate: {
            status: FETCH_STATUS_IN_PROGRESS,
          },
          providerPatients: [...state.providerPatients, newPatientSeed],
        };
      }
    }
    case FETCH_PATIENT_TAGS_SUCCESS: {
      return {
        ...state,
        tagsUpdate: {
          tags: action.payload.tags,
          status: FETCH_STATUS_SUCCESSFUL,
        },
        providerPatients: state.providerPatients.map((patient) => {
          if (patient.id !== action.payload.patientId) {
            return patient;
          }

          return { ...patient, tags: action.payload.tags };
        }),
      };
    }
    case FETCH_PATIENT_TAGS_HAS_ERRORED: {
      return {
        ...state,
        tagsUpdate: {
          hasErrored: true,
        },
      };
    }
    case TAGS_UPDATE_SAVED: {
      return {
        ...state,
        tagsUpdate: {
          tags: action.payload.tags,
        },
        providerPatients: state.providerPatients.map((patient) => {
          if (patient.id !== action.payload.patient) {
            return patient;
          }

          return { ...patient, tags: action.payload.tags };
        }),
      };
    }
    case TAGS_UPDATE_HAS_ERRORED: {
      return {
        ...state,
        tagsUpdate: {
          hasErrored: action.hasErrored,
        },
      };
    }
    case TAGS_UPDATE_IN_PROGRESS: {
      return {
        ...state,
        tagsUpdate: {
          inProgress: action.inProgress,
        },
      };
    }
    case PATIENT_CAREPROGRAMS_PROGRESS: {
      return {
        ...state,
        patientCarePrograms: {
          ...state.patientCarePrograms,
          status: FETCH_STATUS_IN_PROGRESS,
        },
      };
    }
    case UPDATE_PATIENT_CAREPROGRAMS_STATE: {
      return {
        ...state,
        patientCarePrograms: {
          carePrograms: action.patientCarePrograms,
          status: FETCH_STATUS_SUCCESSFUL,
        },
      };
    }
    case UPDATE_PATIENT_ICPA_DATA_IN_PROGRESS: {
      return {
        ...state,
        selectedPatientICPADataUpdateStatus: action.type,

      };
    }
    case UPDATE_PATIENT_ICPA_DATA_SUCCESS: {
      return {
        ...state,
        selectedPatientICPADataUpdateStatus: action.type,
      };
    }
    case UPDATE_PATIENT_ICPA_DATA_FAILED: {
      return {
        ...state,
        selectedPatientICPADataUpdateStatus: action.type,
      };
    }
    case RESET_UPDATE_PATIENT_ICPA_DATA_STATUS: {
      return {
        ...state,
        selectedPatientICPADataUpdateStatus: '',
      };
    }
    case RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_IN_PROGRESS: {
      return {
        ...state,
        recentlySyncedUsersICPAData: {
          ...state.recentlySyncedUsersICPAData,
          status: action.type,
        },
      };
    }
    case RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        recentlySyncedUsersICPAData: {
          users: {
            ...state.recentlySyncedUsersICPAData.users,
            ...action.userData,
          },
          status: action.type,
        },
      };
    }
    case RECENTLY_SYNCED_USER_ICPA_DATA_FETCH_FAILED: {
      return {
        ...state,
        recentlySyncedUsersICPAData: {
          ...state.recentlySyncedUsersICPAData,
          status: action.type,
        },
      };
    }
    case CURRENT_PATIENT_ICPA_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        currentPatientICPADetails: {
          userDetails: {
            ...state.currentPatientICPADetails.userDetails,
            ...action.userData,
          },
          status: action.type,
        },
      };
    }
    case CURRENT_PATIENT_ICPA_DATA_FETCH_FAILED: {
      return {
        ...state,
        currentPatientICPADetails: {
          ...state.currentPatientICPADetails,
          status: action.type,
        },
      };
    }
    case UPDATE_CREATED_POPUP_VISIBILITY:
      return { ...state, createdPopupDisplayed: action.createdPopupVisible };
    default:
      return state;
  }
}
