import React from 'react';
import PropTypes from 'prop-types';
import FindPatientModal from '~/bundles/poptracker/FindPatientModal/FindPatientModal.jsx';
import { translate } from 'bundles/shared/components/WithTranslate/WithTranslate';
import Style from './UpdatePatientInfoPresenter.scss';

export class UpdatePatientInfoPresenter extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    patientICPAData: PropTypes.object,
    ageOfMajority: PropTypes.number,
    currentPatient: PropTypes.object.isRequired,
    customMrnLabel: PropTypes.string,
    customMrnExamples: PropTypes.string,
  }

  static defaultProps = {
    patientICPAData: {
      userDetails: {},
      status: '',
    },
    ageOfMajority: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  hideCreatePatientModal = () => {
    this.setState({
      isModalVisible: false,
    });
  }

  openUpdateInfoModal = () => {
    this.setState({
      isModalVisible: true,
    });
  }

  savePatientData = () => {
    window.location.replace(location.href);
  }

  render() {
    const { ageOfMajority, t, patientICPAData, currentPatient } = this.props;
    const { userDetails, status } = patientICPAData;
    const { isModalVisible } = this.state;
    const { activated, archived } = currentPatient;

    if (archived && !activated) {
      return null;
    }

    return (
      <div>
        <button
          type="button"
          className={Style.updatePatientInfoButton}
          onClick={this.openUpdateInfoModal}
        >
          {t('updatePatientAccountLink')}
        </button>
        {status === 'CURRENT_PATIENT_ICPA_DATA_FETCH_SUCCESS' &&
        <FindPatientModal
          visible={isModalVisible}
          visibilityCallback={this.hideCreatePatientModal}
          updatePatientDataView={isModalVisible}
          existingUserDetails={userDetails}
          savePatientData={this.savePatientData}
          isUpdate
          ageOfMajority={ageOfMajority}
        />}
      </div>
    );
  }
}

export default translate('UpdatePatientInfoPresenter')(UpdatePatientInfoPresenter);
