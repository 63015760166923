import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, isString } from 'lodash';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { Button, Chip, Icon } from '@glooko/common-ui';
import ImageStore from '~/utils/ImageStore';
import TextHighlighter from '~/bundles/shared/components/TextHighlighter/TextHighlighter.jsx';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import {
  providerTagsSaveThunk,
  customTagsSaveThunk,
  providerTagsDeleteThunk,
  customTagsDeleteThunk,
} from '~/redux/thunks/providerGroupSite/providerGroupSite';
import TagList from '../../../../shared/components/TagList/TagList.jsx';

import styles from './PatientTagsEditable.scss';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    providerTagsSaveThunk,
    customTagsSaveThunk,
    providerTagsDeleteThunk,
    customTagsDeleteThunk,
  }, dispatch),
});

export class PatientTagsEditable extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    professionalTags: PropTypes.array.isRequired,
    tooltipPosition: PropTypes.string.isRequired,
    patientId: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      providerTagsSaveThunk: PropTypes.func,
      customTagsSaveThunk: PropTypes.func,
      providerTagsDeleteThunk: PropTypes.func,
      customTagsDeleteThunk: PropTypes.func,
    }).isRequired,
    tagsMaxWidth: PropTypes.number,
  };

  static defaultProps = {
    tagsMaxWidth: 0,
  }

  constructor(props) {
    super(props);
    const rTags = [];
    this.props.tags.forEach((tagGroup) => {
      if (tagGroup.category !== 'careProgramTags' && tagGroup.category !== 'flags' &&
        tagGroup.category !== 'pregnancy' && tagGroup.category !== 'hedia') {
        tagGroup.tags.forEach((tag) => {
          rTags.push(tag);
        });
      }
    });
    this.state = {
      appliedTags: rTags.filter((t) => t.class !== 'mids'),
      allAppliedTags: rTags.filter((t) => t.class !== 'mids'),
      deletedTags: [],
      showModal: false,
      multiTag: [],
      value: undefined,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.removeAppliedTag = this.removeAppliedTag.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleOnChange(value) {
    this.setState({ multiTag: value });
  }

  handleClick() {
    if (!this.state.showModal) {
      document.addEventListener('click', this.handleOutsideClick, true);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, true);
    }

    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      appliedTags: this.state.allAppliedTags,
      deletedTags: [],
      multiTag: [],
    }));
  }

  handleOutsideClick(e) {
    e.preventDefault();
    if ((this.node && this.node.ownerDocument && this.node.contains(e.target)) ||
      e.target.className === 'chip__remove_icon') {
      return;
    }

    this.handleClick(e);
  }

  handleSave() {
    const { patientId } = this.props;
    const providerTagsToAdd = this.state.multiTag.filter((tag) => tag.providerId);
    const providerTagsToDel = this.state.deletedTags.filter((tag) => tag.providerId);
    const customTagsToAdd = this.state.multiTag.filter(
      (tag) => !tag.providerId).map((tag) => tag.label);
    const customTagsToDel = this.state.deletedTags.filter(
      (tag) => !tag.providerId).map((tag) => tag.label);
    if (providerTagsToAdd.length > 0) {
      this.props.actions.providerTagsSaveThunk({
        patient: patientId,
        providerIds: providerTagsToAdd.map((providerTag) => providerTag.providerId),
      });
    }
    if (customTagsToAdd.length > 0) {
      this.props.actions.customTagsSaveThunk({
        patient: patientId,
        customTexts: customTagsToAdd,
      });
    }
    if (providerTagsToDel.length > 0) {
      this.props.actions.providerTagsDeleteThunk(patientId, {
        providerIds: providerTagsToDel.map((providerTag) => providerTag.providerId),
      });
    }
    if (customTagsToDel.length > 0) {
      this.props.actions.customTagsDeleteThunk(patientId, {
        customTexts: customTagsToDel,
      });
    }

    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
    document.removeEventListener('click', this.handleOutsideClick, true);
  }

  removeAppliedTag(e) {
    const deletedTags = this.state.deletedTags.slice();
    const appliedTags = this.state.appliedTags.slice();
    const indexToDelete = appliedTags.findIndex((t) => {
      const id = `${t.id}-${t.label.replaceAll(' ', '_')}`;
      return id === e;
    });
    deletedTags.push(...appliedTags.splice(indexToDelete, 1));
    this.setState({
      deletedTags,
      appliedTags,
    });
  }

  handleInputChange = (value) => {
    this.setState({ value });
    return value;
  }

  CustomMultiValueRemove = (props) => (
      <components.MultiValueRemove {...props}>
        <Icon name="cross" size="xsmall" />
      </components.MultiValueRemove>
    );

  CustomOption = (props) => (
    <components.Option {...props}>
      {(this.state.value && isString(props.label) && props.label !== this.props.t('createNewTag', { value: this.state.value })) ?
       <TextHighlighter search={this.state.value} text={props.label} /> :
       props.label}
    </components.Option>
  );

  render() {
    const { professionalTags, tags, lastIndex, t, tagsMaxWidth, tooltipPosition, patientId } = this.props;
    const { multiTag, appliedTags, deletedTags } = this.state;

    const allTags = filter(tags, (item) => item.category !== 'careProgramTags' &&
      item.category !== 'flags');
    return (
      <div
        className={styles.patientTagsEditable}
      >
        <button
          className={styles.tagsButton}
          onClick={this.handleClick}
          data-testid="PatientTagsEditable-tagsButton"
        >
          <img src={appliedTags.length ? ImageStore.tagsEdit : ImageStore.plusIcon} alt="edit-add-icon" />
        </button>
        {this.state.showModal &&
          <div
            class={classNames({
              [styles.tagManagementDialogue]: true,
              [styles.lastRows]: lastIndex,
            })}
            ref={(node) => { this.node = node; }}
          >
            <CreatableSelect
              className={styles.creatableSelect}
              onInputChange={this.handleInputChange}
              inputValue={this.state.value}
              isMulti
              options={professionalTags}
              onChange={this.handleOnChange}
              value={multiTag}
              components={{
                MultiValueRemove: this.CustomMultiValueRemove,
                Option: this.CustomOption,
                IndicatorSeparator: null,
                DropdownIndicator: null,
              }}
              formatCreateLabel={(value) => <span className={styles.formatCreateLabel}>{t('createNewTag', { value })}</span>}
              placeholder={t('addTag')}
              autoFocus
            />
            <div>
              {appliedTags.length > 0 &&
                <div className={styles.appliedTags}> {t('appliedTags')} </div>}
              <div className={styles.chips}>
                {appliedTags.map((tag) => (
                  <Chip
                    className={styles.chip}
                    key={tag.id}
                    id={tag.id}
                    label={tag.label}
                    onClick={this.removeAppliedTag}
                  />
                ))}
              </div>
              <div className={styles.buttonsContainer}>
                <Button onClick={this.handleClick} variation='secondary'>
                  {t('cancel')}
                </Button>
                <Button onClick={this.handleSave} disabled={!(multiTag.length || deletedTags.length)}>
                  {t('apply')}
                </Button>
              </div>
            </div>
          </div>}
        <TagList
          id={patientId}
          tags={allTags}
          tooltipPosition={tooltipPosition}
          onClickTag={this.handleClick}
          maxWidth={tagsMaxWidth}
        />
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(translate('PatientListTable')(PatientTagsEditable));
