import React from 'react';
import PropTypes from 'prop-types';
import images from '~/utils/ImageStore';
import { translate } from 'bundles/shared/components/WithTranslate/WithTranslate';
import Group from './GraphsLegendGroup';
import Item from './GraphsLegendItem';
import InfoItem from './GraphsLegendInfoItem';
import Style from './GraphsLegend.scss';

export const SystemModeLegend = (props) => {
  const {
    systemLabel,
    autoLabel,
    autoDescr,
    autoModes,
    displayAuto,
    displayManual,
    manualLabel,
    manualDescr,
    titleVisible,
    disableTooltip,
    t,
    testId,
  } = props;

  const renderInfoContent = () => {
    if (disableTooltip) return null;

    const tooltipItems = [];

    if (displayAuto) {
      tooltipItems.push(
        <InfoItem
          imageSrc={images.pumpAutomaticLegend}
          name={autoLabel}
          descr={autoDescr}
          key='auto-mode'
        />,
      );
    }

    autoModes.forEach((mode) => {
      tooltipItems.push(
        <InfoItem
          imageSrc={mode.imgSrc}
          name={mode.name}
          descr={mode.descr}
          key={mode.name}
        />,
      );
    });

    if (displayManual) {
      tooltipItems.push(
        <InfoItem
          imageSrc={images.pumpManualLegend}
          name={manualLabel}
          descr={manualDescr}
          key='manual-mode'
        />,
      );
    }

    return (
      <div className={Style.systemInfoTooltip}>
        {tooltipItems}
      </div>
    );
  };

  const title = `${t('pumpSystem')} (${systemLabel})`;

  const items = [];

  if (displayAuto) {
    items.push(
      <Item
        testId={`${testId}.auto`}
        visible
        src={images.pumpAutomaticLegend}
        label={autoLabel}
        key='auto-mode'
      />,
    );
  }
  autoModes.forEach((mode) => {
    items.push(
      <Item
        testId={`${testId}.${mode.testId}`}
        visible
        src={mode.imgSrc}
        label={mode.name}
        key={mode.name}
      />,
    );
  });
  if (displayManual) {
    items.push(
      <Item
        testId={`${testId}.manual`}
        visible
        src={images.pumpManualLegend}
        label={manualLabel}
        key='manual-mode'
      />,
    );
  }

  return (
    <Group
      title={title}
      titleVisible={titleVisible}
      infoContent={renderInfoContent()}
    >
      {items}
    </Group>
  );
};

SystemModeLegend.propTypes = {
  systemLabel: PropTypes.string.isRequired,
  autoLabel: PropTypes.string.isRequired,
  manualLabel: PropTypes.string.isRequired,
  displayAuto: PropTypes.bool.isRequired,
  displayManual: PropTypes.bool.isRequired,
  autoModes: PropTypes.arrayOf(PropTypes.shape(
    {
      name: PropTypes.string,
      imageSrc: PropTypes.string,
      descr: PropTypes.string,
      testId: PropTypes.string,
    })),
  autoDescr: PropTypes.string,
  manualDescr: PropTypes.string,
  titleVisible: PropTypes.bool,
  disableTooltip: PropTypes.bool,
  t: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

SystemModeLegend.defaultProps = {
  autoModes: [],
  autoDescr: '',
  manualDescr: '',
  titleVisible: true,
  disableTooltip: false,
  displayAuto: false,
  displayManual: false,
  t: (s) => s,
};

export default translate('GraphsLegend')(SystemModeLegend);
