// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".DropdownDatepicker_datePickerWrapper{display:-webkit-box;display:-ms-flexbox;display:flex;margin-top:10px}.DropdownDatepicker_datePickerWrapper .DropdownDatepicker_selectWrapper{-webkit-margin-end:10px;margin-inline-end:10px}", "",{"version":3,"sources":["DropdownDatepicker.scss"],"names":[],"mappings":"AAAA,sCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,eAAA,CAEA,wEACE,uBAAA,CAAA,sBAAA","file":"DropdownDatepicker.scss","sourcesContent":[".datePickerWrapper {\n  display: flex;\n  margin-top: 10px;\n\n  .selectWrapper {\n    margin-inline-end: 10px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"datePickerWrapper": "DropdownDatepicker_datePickerWrapper",
	"selectWrapper": "DropdownDatepicker_selectWrapper"
};
module.exports = exports;
