import React, { useEffect, useMemo, useState } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Pagination, Table } from '@glooko/common-ui';
import ProviderGroupSiteHelper from 'redux/modules/providerGroupSite/ProviderGroupSiteHelper';
import SearchBarUtils from 'utils/SearchBarUtils/SearchBarUtils';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import {
  fetchFilterProviderPatientsThunk,
  fetchFiltersThunk,
  fetchProfessionalTagsThunk,
} from '~/redux/thunks/providerGroupSite/providerGroupSite';
import { fetchCountries } from '~/redux/modules/countries/countries';
import { getPatientTablePageIndex, setPatientTablePageIndex } from '~/bundles/shared/helpers/localStorageHelper';
import { buildFilterParams } from '~/bundles/shared/helpers/filterParamsHelper';
import { FETCH_STATUS_IN_PROGRESS } from '~/bundles/shared/constants/graphs';
import {
  PGS_FILTER_LIST_FETCHED,
} from '~/redux/modules/providerGroupSite/providerGroupSite';
import {
  POP_INSIGHTS_FEATURE,
} from '~/bundles/shared/constants/providerGroupSite';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import Readings from '~/services/Readings';
import CareProgramsContainer from '~/bundles/shared/components/CarePrograms/CareProgramsContainer/CareProgramsContainer.jsx';
import UserHelper from '~/redux/modules/users/UserHelper';
import PatientColumn from './PatientColumn/PatientColumn';
import PatientTagsEditable from './PatientTagsEditable/PatientTagsEditable';
import PatientFlagsColumn from './PatientFlagsColumn/PatientFlagsColumn';
import PatientCareProgramsColumn from './PatientCareProgramsColumn/PatientCareProgramsColumn';
import PatientLastSyncColumn from './PatientLastSyncColumn/PatientLastSyncColumn';
import PatientTirColumn from './PatientTirColumn/PatientTirColumn';
import { addHediaTags, addPregnancyTags, findLastSync, mapFlagsAndPatients, getFlagTranslations } from './PatientListTableUtils';
import styles from './PatientListTable.scss';
import { PAGINATION_PAGE_SIZE } from '../../../shared/constants/tables';

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUsers.currentUser,
  patients: state.providerGroupSite.providerPatients,
  status: state.providerGroupSite.status,
  paginationData: state.providerGroupSite.paginationData,
  professionalTags: [...state.providerGroupSite.allTags.tags],
  searchParams: state.searchParams,
  currentFilters: state.providerGroupSite.filters,
  pgsCarePrograms: state.providerGroupSite.carePrograms,
  lastRunTimestamp: state.providerGroupSite.lastRunTimestamp,
  filtersStatus: state.providerGroupSite.filtersStatus,
  searchFilterFlags: state.providerGroupSite.filters?.flags,
  features: state.providerGroupSite?.subscriptionModel?.features,
  hasPregnancyPackage: state.providerGroupSite.pregnancyPackage,
  hasHediaEnabled: state.providerGroupSite.apps.includes('hedia'),
  hidePatientList: UserHelper.CurrentUserHidePatientList(state),
  hasFilters: ProviderGroupSiteHelper.hasSearchFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchProfessionalTagsThunk,
    fetchFilterProviderPatientsThunk,
    fetchFiltersThunk,
    fetchCountries,
  }, dispatch),
});

const PatientListTable = (props) => {
  const {
    t,
    currentUser,
    currentFilters,
    searchParams,
    hasHediaEnabled,
    hasPregnancyPackage,
    patients,
    searchFilterFlags,
    features,
    pgsCarePrograms,
    professionalTags,
    lastRunTimestamp,
    paginationData,
    filtersStatus,
    hidePatientList,
    hasFilters,
  } = props;
  const showingDuplicates = searchParams?.duplicates;

  const [isOpenCareProgramModal, setIsOpenCareProgramModal] = useState(false);
  const [currentPatientInfo, setCurrentPatientInfo] = useState({});

  const [sorting, setSorting] = useState([{
    desc: localStorage.getItem('defaultSortDirection') ?? 'descending',
    id: localStorage.getItem('defaultSortBy') ?? 'last_sync_timestamp',
  }]);

  const destructedSorting = ({
    sortBy: sorting[0].id,
    direction: sorting[0].desc ? 'descending' : 'ascending',
  });

  useEffect(() => {
    fetchData(getPatientTablePageIndex());
  }, []);

  useEffect(() => {
    const { sortBy, direction } = destructedSorting;
    localStorage.setItem('defaultSortBy', sortBy);
    localStorage.setItem('defaultSortDirection', direction);
  }, [sorting]);

  useEffect(() => {
    props.actions.fetchCountries();
  }, []);

  const isMGDL = !(currentUser && currentUser.preference && currentUser.preference.meterUnits === 'mmoll');

  const pgsIsPopInsights = currentUser.populationInsightsPgs;
  const showTimeInRange = currentUser.blindedReferenceDevices?.length === 0;
  const meterUnits = (currentUser?.meterUnits === 'mmoll') ? Readings.MMOLL : Readings.MGDL;

  let tagsColMaxWidth = 400;
  if (pgsCarePrograms.length > 0 && pgsIsPopInsights) {
    tagsColMaxWidth = 160;
  } else if (pgsCarePrograms.length > 0 || pgsIsPopInsights) {
    tagsColMaxWidth = 200;
  }

  const getCurrentFilters = () => (window.sessionStorage.patients_list_view_filters ?
    JSON.parse(window.sessionStorage.patients_list_view_filters) :
    buildFilterParams(currentFilters));

  const setSortByAndDirection = () => {
    const { sortBy, direction } = destructedSorting;

    localStorage.setItem('defaultSortBy', sortBy);
    localStorage.setItem('defaultSortDirection', direction);
  };

  // if hide patientdisabled is false, there are no active filters
  // no active filters => do not hide patients
  const hidePatient = (hasFilters || SearchBarUtils.hasSearchQuery()) ? false : hidePatientList;

  const fetchData = (pageToFetch) => {
    const page = pageToFetch || searchParams.page;
    const { includeDeactivated, searchBy, poptrackerSearchQuery, duplicates } = searchParams;
    setSortByAndDirection();

    setPatientTablePageIndex(page);
    const { sortBy, direction } = destructedSorting;

    props.actions.fetchFilterProviderPatientsThunk({
      includeDeactivated,
      duplicates,
      page,
      searchBy,
      poptrackerSearchQuery,
      sortBy,
      direction,
    },
      props.currentFilters,
      getCurrentFilters(),
    );

    props.actions.fetchProfessionalTagsThunk();
  };

  /* istanbul ignore next */
  const handleCareProgramAddOrEditClick = (currentPatientInfo) => {
    setCurrentPatientInfo(currentPatientInfo);
    setIsOpenCareProgramModal(true);
  };

  /* istanbul ignore next */
  const handleCloseCareProgramModal = (isChangeState) => {
    if (isChangeState) {
      fetchData();
      props.actions.fetchFiltersThunk();
    }
    setIsOpenCareProgramModal(false);
  };

  const changeSorting = (value) => {
    setSorting(value);
    fetchData();
  };

  const changeCurrentPage = (currentPage) => {
    fetchData(currentPage);
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('patient', {
      header: t('patient'),
      id: 'name',
      cell: (info) => (
        <PatientColumn
          patient={info.row.original}
          sortByName={sorting[0].id === 'name'}
          showDuplicatesIndicator={showingDuplicates}
          fetchTableData={fetchData}
        />),
      meta: {
        style: {
          width: 225,
        },
      },
      sortDescFirst: false,
    }),
    columnHelper.accessor('lastSync', {
      header: t('lastSync'),
      id: 'last_sync_timestamp',
      cell: (info) => (
        <PatientLastSyncColumn
          lastSyncDate={info.getValue()}
          lastSyncDateWithTime={info.row.original.lastSyncWithTime}
          lastDeviceSyncedFrom={info.row.original.lastDeviceSyncedFrom}
          lastDeviceSyncedName={info.row.original.lastDeviceSyncedName}
        />),
      meta: {
        style: {
          width: 200,
        },
      },
    }),
    columnHelper.accessor('tirPercentage', {
      header: (
        <div className={styles.customColor}>
          {t('timeInRange')}
        </div>
      ),
      cell: (info) => (
        <PatientTirColumn
          patientId={info.row.original.id}
          meterUnits={meterUnits}
          cgmTirPercentage={info.row.original.cgmTirPercentage}
          cgmTirStartDate={info.row.original.cgmTirStartDate}
          cgmTirEndDate={info.row.original.cgmTirEndDate}
          cgmPercentTimeActive={info.row.original.cgmPercentTimeActive}
          cgmTargetRangeHigh={info.row.original.cgmTargetRangeHigh}
          cgmTargetRangeLow={info.row.original.cgmTargetRangeLow}
          meterAverageReadingsPerDay={info.row.original.meterAverageReadingsPerDay}
          meterTargetRangeHigh={info.row.original.meterTargetRangeHigh}
          meterTargetRangeLow={info.row.original.meterTargetRangeLow}
          meterTirStartDate={info.row.original.meterTirStartDate}
          meterTirEndDate={info.row.original.meterTirEndDate}
          meterTirPercentage={info.row.original.meterTirPercentage}
        />
      ),
      meta: {
        style: {
          width: 150,
        },
      },
      enableSorting: false,
    }),
    columnHelper.accessor('flags', {
      header: (
        <div>
          {t('flags')}
          <div>{lastRunTimestamp ? `${t('lastRunOn')} ${lastRunTimestamp}` : null}</div>
        </div>
      ),
      cell: (info) => (
        <div>
          <PatientFlagsColumn
            lastIndex={info.row.index >= info.table.getRowCount() - 3}
            flags={info.row.original.tags}
            tagsMaxWidth={200}
            rowIndex={info.row.index}
          />
        </div>
      ),
      meta: {
        style: {
          width: 220,
        },
      },
      enableSorting: false,
    }),
    columnHelper.accessor('tags', {
      header: t('tags'),
      cell: (info) => (
        <div>
          <PatientTagsEditable
            tags={info.row.original.tags}
            tooltipPosition={(info.row.index >= info.table.getRowCount() - 3) ? 'top' : 'bottom'}
            professionalTags={professionalTags}
            patientId={info.row.original.id}
            tagsMaxWidth={tagsColMaxWidth}
          />
        </div>
      ),
      meta: {
        style: {
          ...((pgsCarePrograms.length > 0 || pgsIsPopInsights) && { width: 220 }),
          ...(pgsCarePrograms.length > 0 && pgsIsPopInsights && { width: 200 }),
        },
      },
      enableSorting: false,
    }),
    columnHelper.accessor('carePrograms', {
      header: t('carePrograms'),
      cell: (info) => (
        <div>
          <PatientCareProgramsColumn
            patientInfo={info.row.original}
            lastIndex={info.row.index >= info.table.getRowCount() - 3}
            handleCareProgramAddOrEditClick={handleCareProgramAddOrEditClick}
            tagsMaxWidth={pgsIsPopInsights ? 140 : 220}
          />
        </div>
      ),
      meta: {
        style: {
          width: 230,
        },
      },
      enableSorting: false,
    }),
  ];

  const tableData = useMemo(() => {
    let patientData = hidePatient ? [] : patients;

    if (features.includes(POP_INSIGHTS_FEATURE) && patients.length > 0) {
      patientData = mapFlagsAndPatients(patientData, searchFilterFlags, getFlagTranslations(isMGDL));
    }

    if (hasPregnancyPackage && patients.length > 0) {
      patientData = addPregnancyTags(patientData, t('week'));
    }

    if (hasHediaEnabled && patients.length > 0) {
      patientData = addHediaTags(patientData);
    }

    return patientData.map((patient) => {
      const patientLastSyncArray = Object.values(patient.lastSyncTimestamps);

      return {
        ...patient,
        dob: patient.dateOfBirth ? moment.utc(patient.dateOfBirth).format(TIME_FORMATS.MM_DD_YYYY) : t('noDateSet'),
        lastSync: findLastSync(patientLastSyncArray),
        lastSyncWithTime: patientLastSyncArray.length > 0 ? patientLastSyncArray.reduce(
          (maxTimestamp, currentTimestamp) => ((currentTimestamp > maxTimestamp) ? currentTimestamp : maxTimestamp), patientLastSyncArray[0],
        ) : null,
      };
    });
  }, [props.status, patients, hidePatientList, hasFilters]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnVisibility: {
        carePrograms: pgsCarePrograms.length > 0,
        tirPercentage: showTimeInRange,
        flags: pgsIsPopInsights,
      },
    },
    enableSortingRemoval: false,
    enableMultiSort: false,
    onSortingChange: changeSorting,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const showLoader = props.status === FETCH_STATUS_IN_PROGRESS || filtersStatus !== PGS_FILTER_LIST_FETCHED;

  return (
    <>
      <Table
        table={table}
        noDataText={t('noPatientsFound')}
        loading={showLoader}
        className={styles.popTrackerTable}
      />
      <div style={{ margin: '20px 10px 10px', boxSizing: 'border-box' }}>
        <Pagination
          totalRows={hidePatient ? 0 : paginationData.totalCount}
          pageSize={PAGINATION_PAGE_SIZE}
          currentPage={paginationData.currentPage}
          onChange={/* istanbul ignore next */(page) => changeCurrentPage(page)}
        />
      </div>
      {isOpenCareProgramModal &&
        <CareProgramsContainer
          isModalOpen={isOpenCareProgramModal}
          patientProfile={currentPatientInfo}
          handleCloseModal={handleCloseCareProgramModal}
        />}
    </>
  );
};

PatientListTable.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    fetchProfessionalTagsThunk: PropTypes.func,
    fetchFilterProviderPatientsThunk: PropTypes.func,
    fetchFiltersThunk: PropTypes.func,
  }).isRequired,
  patients: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  paginationData: PropTypes.object.isRequired,
  professionalTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchParams: PropTypes.object.isRequired,
  currentFilters: PropTypes.object.isRequired,
  pgsCarePrograms: PropTypes.array,
  currentUser: PropTypes.object.isRequired,
  lastRunTimestamp: PropTypes.string,
  filtersStatus: PropTypes.string,
  searchFilterFlags: PropTypes.array,
  features: PropTypes.array,
  hasPregnancyPackage: PropTypes.bool,
  hasHediaEnabled: PropTypes.bool.isRequired,
  hidePatientList: PropTypes.bool.isRequired,
  hasFilters: PropTypes.bool.isRequired,
};

PatientListTable.defaultProps = {
  pgsCarePrograms: [],
  lastRunTimestamp: '',
  filtersStatus: '',
  searchFilterFlags: [],
  features: [],
  hasPregnancyPackage: false,
  hasHediaEnabled: false,
};

export const TranslatedPatientListTable = translate('PatientListTable')(PatientListTable);
export default connect(mapStateToProps, mapDispatchToProps)(TranslatedPatientListTable);
