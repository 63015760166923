import moment from 'moment';
import {
  CUSTOM_RANGE,
  PDF_TIMEFRAMES,
} from '~/bundles/shared/constants/time';
import { findIndex, merge, isEmpty, compact } from 'lodash';
import { TYPE_BG, TYPE_CGM } from '~/bundles/shared/constants/readings';
import { PAGES, PAGE_TO_PAGES_INDEX, PAGE_OVERVIEW, PAGE_CALENDAR } from
  '~/bundles/shared/constants/pages';
import {
  pageChange,
  readingsTypeChanged,
  updateTimeframe,
  updateTimeframePdfWizard,
  updatePdfWizardVisibility,
  stateOnTimeFrameChange,
} from '~/redux/modules/page/page';
import { trackWebTimeFramePicker, trackWebPageLoaded } from '~/services/eventLogging';
import UserHelper from '../modules/users/UserHelper';
import PageHelper from '../modules/page/PageHelper';
import { fetchCurrentUsersThunk } from './users';
import {
  fetchProviderGroupSiteThunk,
  fetchFiltersThunk,
} from './providerGroupSite/providerGroupSite';
import { fetchEndDatesThunk } from './endDates';
import { fetchSubscriptionThunk } from './subscription';

function calculateEndDate(
  endDateMax, readingsType, selectorEnabled, syncTimestamps,
) {
  let date = null;
  const {
    meter, pump, cgmDevice, insulinPen, kiosk, lastSyncTimestamp,
  } = syncTimestamps;

  const endDateMaxMoment = moment.utc(endDateMax).endOf('day');
  const kioskMoment = kiosk ? moment.utc(kiosk).endOf('day') : null;

  if (selectorEnabled && readingsType === TYPE_CGM) {
    date = cgmDevice;
  } else {
    date = [pump, meter, insulinPen].filter((e) => !!e).sort().reverse()[0];
    date = date || lastSyncTimestamp;
  }

  date = date ? moment.utc(date) : moment.utc().endOf('day');

  // app data is limited to last kiosk sync
  if (kioskMoment && endDateMaxMoment.isSame(kioskMoment) && date > kioskMoment) {
    return kioskMoment.toISOString();
  }

  return date.toISOString();
}

export function baseReadingsType(syncTimestamps, endDateMax) {
  const { cgmDevice, pump, meter, insulinPen } = syncTimestamps;
  const endDateMaxMoment = moment.utc(endDateMax);

  const bgSyncTimestamp = [
    pump, meter, insulinPen,
  ].filter((e) => !!e).sort().reverse()[0];

  if (cgmDevice) {
    const cgmInLastMonth = moment.utc(cgmDevice).isSameOrAfter(
      endDateMaxMoment.subtract(1, 'month'),
    );
    if (cgmInLastMonth || !bgSyncTimestamp) return TYPE_CGM;

    return moment.utc(cgmDevice).isAfter(bgSyncTimestamp) ? TYPE_CGM : TYPE_BG;
  }

  return TYPE_BG;
}

function getCreatePdfModalDateRange() {
  return localStorage.getItem('createPdfModalDateRange');
}

export function pathChangeThunk(selectedPageIndex, isUserSelection = true) {
  return (dispatch, getState) => {
    const pageValues = defaultPageValues(selectedPageIndex, getState);
    const newPageValues = updatePageValuesTo(pageValues, selectedPageIndex, isUserSelection);
    trackWebPageLoaded(newPageValues.readingsType);
    dispatch(pageChange(newPageValues));
  };
}

export function pageChangeThunk(selectedPageIndex, isUserSelection = true) {
  return (dispatch, getState) => {
    const pageValues = defaultPageValues(selectedPageIndex, getState);
    const newPageValues = updatePageValuesTo(pageValues, selectedPageIndex, isUserSelection);
    dispatch(pageChange(newPageValues));
  };
}

function updatePageValuesTo(pageValues, selectedPageIndex, isUserSelection = true) {
  let newPageValues = pageValues;
  const bgEndDate = newPageValues.endDate;
  if (isUserSelection) {
    newPageValues = merge(newPageValues, userSelections(selectedPageIndex));
  }
  const endDate = newPageValues.datepickerEnabled ? newPageValues.endDate : bgEndDate;
  const selectedTimeframe = newPageValues.availableTimeFrames[newPageValues.timeFrameIndex].value;
  if (newPageValues.id === 'PAGE_LOGBOOK' && newPageValues.readingsType === 'cgm') {
    newPageValues = merge(newPageValues, logbookOverrides(newPageValues, endDate));
  }
  if (newPageValues.customRangeDaysLimit && selectedTimeframe === CUSTOM_RANGE) {
    newPageValues = merge(newPageValues, customTimeframeOverride(newPageValues));
  }
  if (getCreatePdfModalDateRange() && newPageValues.id === 'PAGE_SUMMARY') {
    newPageValues = merge(newPageValues, JSON.parse(getCreatePdfModalDateRange()));
    localStorage.removeItem('createPdfModalDateRange');
  }

  return newPageValues;
}

function logbookOverrides(pageValues, bgEndDate) {
  const timeFrame = pageValues.availableTimeFrames[pageValues.timeFrameIndex].value;
  const bgStartDate = pageValues.datepickerEnabled ?
    pageValues.startDate : stateOnTimeFrameChange(timeFrame, bgEndDate).startDate;

  return { startDate: bgStartDate, endDate: bgEndDate, readingsType: TYPE_BG };
}

function customTimeframeOverride(pageValues) {
  const momentStart = moment(pageValues.startDate).utc();
  const limit = pageValues.customRangeDaysLimit - 1;
  const diff = moment(pageValues.endDate).utc().diff(momentStart, 'days');
  if (diff <= limit) return {};

  const startDate = momentStart
    .add(diff - limit, 'days')
    .startOf('day')
    .toISOString();

  return { startDate };
}

function userSelections(selectedPageIndex) {
  if (isEmpty(UserHelper.getLocalGraphSelections())) return {};
  const { availableTimeFrames } = PAGES[selectedPageIndex];
  const {
    timeframe: userTimeFrameValue,
    startDate: userStartDate,
    endDate,
    readingsType,
  } = UserHelper.getLocalGraphSelections();

  let timeFrameIndex;
  let timeFrameIndexPdfWizard;
  let timeFramePdfWizard;
  let datepickerEnabled;
  let startDate;

  const closestTimeFrame =
    PageHelper.closestTimeFrame(availableTimeFrames, userTimeFrameValue);
  const closestPdfTimeFrame = PageHelper.closestTimeFrame(PDF_TIMEFRAMES, userTimeFrameValue);

  if (closestTimeFrame) {
    timeFrameIndex =
      findIndex(availableTimeFrames, (frame) => frame.value === closestTimeFrame.value);
    timeFrameIndexPdfWizard =
      findIndex(PDF_TIMEFRAMES, (frame) => frame.value === closestPdfTimeFrame.value);
    timeFramePdfWizard = PDF_TIMEFRAMES[timeFrameIndexPdfWizard].value;

    ({ datepickerEnabled, startDate } = stateOnTimeFrameChange(
      closestTimeFrame.value,
      endDate,
    ));

    if (datepickerEnabled) startDate = userStartDate;
  }

  return {
    endDate: endDate && moment.utc(endDate).endOf('day').toISOString(),
    endDatePdfWizard: endDate,
    startDate: startDate && moment.utc(startDate).startOf('day').toISOString(),
    timeFrameIndexPdfWizard,
    timeFramePdfWizard,
    startDatePdfWizard: moment.utc(endDate).subtract({ days: 13 }).endOf('day').toISOString(),
    timeFrameIndex,
    datepickerEnabled,
    readingsType,
  };
}

function defaultPageValues(selectedPageIndex, getState) {
  const state = getState();
  const endDateCommon = state.page.endDateCommon;
  const syncTimestamps = UserHelper.lastSyncTimestampStore(state);

  const {
    id,
    showControllerBar,
    showNavBar,
    availableTimeFrames,
    timeFrameIndex,
    readingsTypeEnabled,
    readingsTypeSelectorEnabled,
    profileFeaturesEnabled,
    exerciseTypeSelectorEnabled,
    noToggleBg,
    customRangeDaysLimit,
  } = PAGES[selectedPageIndex];

  const {
    startDate: calendarStartDate,
    endDate: calendarEndDate,
    enabled: calendarRestoreEnabled,
    timeFrameIndex: calendarTimeFrameIndex,
  } = state.page.calendarTimeFrameReturn;

  const readingsType = baseReadingsType(syncTimestamps, endDateCommon);

  const endDate = calculateEndDate(
    endDateCommon,
    readingsType,
    readingsTypeSelectorEnabled,
    syncTimestamps,
  );

  const timestampStateChanges =
    (id === PAGE_CALENDAR && calendarRestoreEnabled) ?
      {
        ...stateOnTimeFrameChange(
          availableTimeFrames[calendarTimeFrameIndex].value,
          endDate,
        ),
        startDate: calendarStartDate,
        endDate: calendarEndDate,
        timeFrameIndex: calendarTimeFrameIndex,
      } :
      stateOnTimeFrameChange(
        availableTimeFrames[timeFrameIndex].value,
        endDate,
      );

  const pdfClosestTimeFrame =
    PageHelper.closestTimeFrame(PDF_TIMEFRAMES, availableTimeFrames[timeFrameIndex].value);
  const timeFrameIndexPdfWizard = findIndex(PDF_TIMEFRAMES, pdfClosestTimeFrame);

  return {
    id,
    showControllerBar,
    showNavBar,
    selectedPageIndex,
    availableTimeFrames,
    timeFrameIndex,
    timeFrameIndexPdfWizard,
    timeFramePdfWizard: PDF_TIMEFRAMES[timeFrameIndexPdfWizard].value,
    readingsType,
    readingsTypeEnabled,
    readingsTypeSelectorEnabled,
    profileFeaturesEnabled,
    exerciseTypeSelectorEnabled,
    noToggleBg,
    customRangeDaysLimit,
    ...timestampStateChanges,
  };
}

export function openSingleDayThunk(endDate) {
  return (dispatch, getState) => {
    const pageState = getState().page;
    const selectedPageIndex = PAGE_TO_PAGES_INDEX[PAGE_OVERVIEW];
    const {
      availableTimeFrames,
      readingsTypeSelectorEnabled,
      exerciseTypeSelectorEnabled,
    } = PAGES[selectedPageIndex];
    const timeframe = 1;
    const timeFrameIndex =
      findIndex(availableTimeFrames, (frame) => frame.value === timeframe);

    const timestampStateChanges = stateOnTimeFrameChange(timeframe, endDate);

    dispatch(pageChange({
      selectedPageIndex,
      availableTimeFrames,
      timeFrameIndex,
      readingsTypeSelectorEnabled,
      exerciseTypeSelectorEnabled,
      calendarTimeFrameReturn: {
        startDate: pageState.startDate,
        endDate: pageState.endDate,
        enabled: true,
        timeFrameIndex: pageState.timeFrameIndex,
      },
      ...timestampStateChanges,
    }));
  };
}

export function setUpPage(page, includeEndDate, includeSubscriptionLimits = false) {
  const thunks = [fetchCurrentUsersThunk, fetchProviderGroupSiteThunk, fetchFiltersThunk];

  return (dispatch, getState) => Promise.all(thunks.map((t) => dispatch(t())))
    .then(() => {
      // fetchEndDates and fetchSubscription must occur after fetchCurrentUsers
      // because the endDates api requires the patient's glooko code as a param
      const currentUserIsPro = !UserHelper.currentUserIsPatient(getState());
      return Promise.all(
        compact([
          includeEndDate && fetchEndDatesThunk,
          currentUserIsPro && includeSubscriptionLimits && fetchSubscriptionThunk,
        ]).map((t) => dispatch(t())),
      );
    })
    .then(() => dispatch(pathChangeThunk(PAGE_TO_PAGES_INDEX[page])));
}

export function readingsTypeChangedThunk(readingsType) {
  return (dispatch, getState) => {
    const state = getState();
    const syncTimestamps = UserHelper.lastSyncTimestampStore(state);
    const { endDateCommon, availableTimeFrames, timeFrameIndex } = state.page;
    const timeframe = availableTimeFrames[timeFrameIndex].value;
    let endDate = state.page.endDate;
    let startDate = state.page.startDate;

    if (timeframe !== CUSTOM_RANGE) {
      endDate = calculateEndDate(endDateCommon, readingsType, true, syncTimestamps);
      endDate = moment.utc(endDate).endOf('day').toISOString();
      startDate = moment.utc(endDate)
        .subtract({ days: timeframe - 1 })
        .startOf('day')
        .toISOString();
    }

    dispatch(readingsTypeChanged(readingsType, endDate, startDate));
  };
}

export function updatePdfWizardTimeframeThunk(timeframe, fromControllerBar = false) {
  return (dispatch, getState) => {
    const state = getState();
    const { endDate, startDate, endDatePDF } = state.page;
    const { kioskSyncPdf } = state.providerGroupSite.subscriptionModel;
    const currentUserIsPatient = UserHelper.currentUserIsPatient(state);
    const timeFrame = parseInt(timeframe, 10);
    const closestPdfTimeFrame = PageHelper.closestTimeFrame(PDF_TIMEFRAMES, timeFrame).value;
    const timeFrameIndexPdfWizard = findIndex(
      PDF_TIMEFRAMES, (elem) => elem.value === closestPdfTimeFrame,
    );
    const timeFramePdfWizard = PDF_TIMEFRAMES[timeFrameIndexPdfWizard].value;
    const pdfWizardDatepickerEnabled = timeFramePdfWizard === CUSTOM_RANGE;
    let startDatePdfWizard;
    let endDatePdfWizard;

    if (!pdfWizardDatepickerEnabled) {
      endDatePdfWizard = endDatePDF || moment.utc().toISOString();
      // for pdf wizard always use max pdf timestamp for end date) when using date presets
      startDatePdfWizard = moment.utc(endDatePdfWizard).endOf('day')
        .subtract({ days: timeFramePdfWizard - 1 })
        .startOf('day')
        .toISOString();
    } else {
      endDatePdfWizard = fromControllerBar ? endDate : state.page.endDatePdfWizard;
      startDatePdfWizard = fromControllerBar ? startDate : state.page.startDatePdfWizard;
    }

    // if current user is a provider and pdf date is limited to last kiosk sync
    if (!currentUserIsPatient && kioskSyncPdf) {
      // set endDate to current end date if it's before last kiosk (only for custom date range)
      endDatePdfWizard =
        (moment.utc(endDate).isSameOrBefore(endDatePDF) &&
          pdfWizardDatepickerEnabled) ? endDate : endDatePDF;
    }

    dispatch(updateTimeframePdfWizard({
      timeFramePdfWizard,
      timeFrameIndexPdfWizard,
      pdfWizardDatepickerEnabled,
      endDatePdfWizard,
      startDatePdfWizard,
    }));
  };
}

export function updateTimeframeThunk(timeframe) {
  return (dispatch, getState) => {
    const state = getState();
    const syncTimestamps = UserHelper.lastSyncTimestampStore(state);
    const { endDateCommon, readingsType, availableTimeFrames } = state.page;
    const timeFrame = parseInt(timeframe, 10);
    const timeFrameIndex = findIndex(availableTimeFrames, (elem) => elem.value === timeFrame);
    const selector = state.page.readingsTypeSelectorEnabled;
    const datepickerEnabled = timeframe === CUSTOM_RANGE;
    let endDate = state.page.endDate;
    let startDate = state.page.startDate;

    if (!datepickerEnabled) {
      endDate = calculateEndDate(endDateCommon, readingsType, selector, syncTimestamps);
      endDate = moment.utc(endDate).endOf('day').toISOString();
      startDate = moment.utc(endDate)
        .subtract({ days: timeframe - 1 })
        .startOf('day')
        .toISOString();
    }

    dispatch(updateTimeframe({
      timeframe,
      timeFrameIndex,
      datepickerEnabled,
      endDate,
      startDate,
    }));
    dispatch(updatePdfWizardTimeframeThunk(timeframe, true));
  };
}

export function dateRangeUpdateTimeframeThunk(timeframe) {
  trackWebTimeFramePicker();
  return updateTimeframeThunk(timeframe);
}

export function updatePdfWizardVisibilityThunk(pdfWizardDisplayed) {
  return (dispatch, getState) => {
    const state = getState();
    const { availableTimeFrames, timeFrameIndex } = state.page;
    const timeFrame = parseInt(availableTimeFrames[timeFrameIndex].value, 10);

    if (pdfWizardDisplayed) {
      dispatch(updatePdfWizardTimeframeThunk(timeFrame, true));
    }
    dispatch(updatePdfWizardVisibility(pdfWizardDisplayed));
  };
}
