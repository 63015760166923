import TimeUtils from 'utils/TimeUtils/TimeUtils';

export const useCustomMrnInfo = (defaultLabel) => {
  const store = window.glookoStore;
  let providerGroupSite;

  if (store) {
    const state = store.getState();
    providerGroupSite = state.providerGroupSite;
  }

  return {
    exampleMrn: providerGroupSite?.customMrnExamples,
    labelMrn: providerGroupSite?.customMrnLabel ?? defaultLabel,
  };
};

export const useAgeOfMajorty = () => {
  const store = window.glookoStore;
  let majorityAge = 16;

  if (store) {
    const state = store.getState();
    const { countryOfJurisdiction } = state.providerGroupSite;
    const { allCountries } = state.countries;
    const countryInfo = allCountries.find((c) => c.countryCode === countryOfJurisdiction);
    majorityAge = countryInfo?.majorityAge;
  }

  return {
    isBecomingMinor: (dob, value) => (
      dob ? TimeUtils.isOfAge(dob, majorityAge) && !TimeUtils.isOfAge(value, majorityAge) : false
    ),
  };
};

// NOT MEANT TO BE PERMENANT, just to use before we upgrade to react-redux 7.1
export const useDispatch = () => {
  const store = window.glookoStore;

  return {
    dispatch: store.dispatch,
  };
};

export const usePgsName = () => {
  const store = window.glookoStore;
  let providerGroupSite;

  if (store) {
    const state = store.getState();
    providerGroupSite = state.providerGroupSite;
  }

  return {
    pgsName: providerGroupSite?.name,
  };
};
