import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogHeader, DialogActions, DialogContent, Checkbox, LinkedText } from '@glooko/common-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import images from 'utils/ImageStore';
import { logout } from 'utils/navigation';
import { translate } from 'bundles/shared/components/WithTranslate/WithTranslate';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { ofAgeConsentUpdateThunk } from '../../../../redux/thunks/consents';
import Style from './MinorToMajorModal.scss';
import { PATH_SETTINGS } from '../../constants/navigation';

const mapStateToProps = (state) => {
  const user = state.users.currentUsers.currentUser;
  // There is a flash of isLoading is false on load. So we need to check the id too
  const userLoading = state.users.isLoading || !user.id;
  return {
    hasTermsAndConditions: user.termsOfUseConsent !== 'pending',
    firstName: user.firstName,
    lastName: user.lastName,
    userLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitConsents: bindActionCreators(ofAgeConsentUpdateThunk, dispatch),
});

export const MinorToMajorModal = ({ hasTermsAndConditions, firstName, lastName, userLoading, submitConsents, t }) => {
  // State
  const [isOpen, setIsOpen] = useState(!userLoading && !hasTermsAndConditions);
  const [submitting, setSubmitting] = useState(false);

  // If loading finishes after initial render. Recheck if need to open.
  useEffect(() => {
    if (!userLoading) {
      setIsOpen(!hasTermsAndConditions);
    }
  }, [userLoading]);

  // starting page on 1 not 0.
  const [page, setPage] = useState(1);
  const [consents, setConsents] = useState({
    privacy: false,
    termsOfUse: false,
    email: false,
    receiveSms: false,
  });

  // Render Functions
  const renderConsents = () => (
      <div className={Style.ConsentOptionList}>
        <div className={Style.ConsentOption}>
          <Checkbox id="privacy" name="privacy" checked={consents.privacy} onChange={updateConsent} />
          {/* This is broken because it eslint doesnt identify checkbox as an associated control */}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='privacy'>
            <LinkedText
              className={Style.ConsentLabel}
              template = {t('privacyLabel')}
              linkContent={[{ title: t('privacyNotice'), url: 'https://glooko.com/privacy/' }]}
              openInNewTab
            />
          </label>
        </div>
        <div className={Style.ConsentOption}>
          <Checkbox id="termsOfUse" name="termsOfUse" checked={consents.termsOfUse} onChange={updateConsent} />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="termsOfUse">
            <LinkedText
              className={Style.ConsentLabel}
              template = {t('termsOfUseLabel')}
              linkContent={[{ title: t('termsOfUse'), url: 'https://glooko.com/terms-of-use/' }]}
              openInNewTab
            />
          </label>
        </div>
        <div className={Style.ConsentOption}>
          <Checkbox id="email" name="email" checked={consents.email} onChange={updateConsent} />
          <label className={Style.ConsentLabel} htmlFor="email">{t('emailLabel')}</label>
        </div>
        <div className={Style.ConsentOption}>
          <Checkbox id="receiveSms" name="receiveSms" checked={consents.receiveSms} onChange={updateConsent} />
          <label className={Style.ConsentLabel} htmlFor="receiveSms">{t('receiveSmsLabel')}</label>
        </div>
      </div>
    );

  const renderHeader = () => (
      <>
        {page === 1 && <h3 className={Style.DialogHeader}>{t('updateConsentHeader')}</h3>}
        {page === 2 && <h3 className={Style.DialogHeader}>{t('accountConsentHeader')}</h3>}
        {page === 3 && <h3 className={Style.DialogHeader}>{t('consentUpdatedHeader')}</h3>}
      </>
    );

  const renderBody = () => (
      <>
        {page === 1 &&
        <>
          <img
            className={Style.Image}
            src={images.userShieldCircle}
          />
          <p>
            <Trans>{t('updateIntroBodyOne', { name: `${firstName} ${lastName}` })}</Trans>
          </p>
          <p>
            {t('updateIntroBodyTwo')}
          </p>
        </>}
        {page === 2 &&
        <>
          <p>
            {t('optionSelectionBody')}
          </p>
          {renderConsents()}
        </>}
        {page === 3 &&
        <>
          <img
            className={Style.Image}
            src={images.checkCircleSuccess}
          />
          <p>
            {t('updateSuccessBodyOne')}
          </p>
          <p>
            {t('updateSuccessBodyTwo')}
          </p>
          <p>
            {t('updateSuccessBodyThree')}
          </p>
        </>}
      </>
    );

  const renderPrimaryButtonText = () => (
      <>
        {page === 1 && t('reviewTermsOfService')}
        {page === 2 && t('updateConsent')}
        {page === 3 && t('done')}
      </>
    );

  const renderSecondaryButtonText = () => (
      <>
        {page === 1 && t('logOut')}
        {page === 2 && t('back')}
        {page === 3 && t('goToSettings')}
      </>
    );

  // Function Handlers
  // Consent keys are mapped to "name" on the checkbox for this to work
  const updateConsent = (e) => {
    setConsents({
      ...consents,
      [e.target.name]: !consents[e.target.name],
    });
  };

  const isContinueDisabled = (page === 2 && !(consents.privacy && consents.termsOfUse));

  const closeDialog = () => {
    setIsOpen(false);
  };

  const primaryButtonHandler = () => {
    switch (page) {
      case 1:
        setPage(page + 1);
        break;
      case 2:
        setSubmitting(true);
        submitConsents(consents, onSuccess, onError);
        break;
      case 3:
        closeDialog();
        break;
    }
  };

  const secondaryButtonHandler = () => {
    switch (page) {
      case 1:
        logout();
        break;
      case 2:
        setPage(page - 1);
        break;
      case 3:
        window.location.assign(PATH_SETTINGS);
        break;
    }
  };

  const onSuccess = () => {
    setSubmitting(false);
    setPage(page + 1);
  };

  const onError = () => {
    setSubmitting(false);
  };

  if (!userLoading) {
    return (
      <Dialog open={isOpen} size="small" classname={Style.dialog} showCloseButton={false}>
        <DialogHeader>
          {renderHeader()}
        </DialogHeader>
        <DialogContent>
          <div className={Style.DialogBody}>
            {renderBody()}
          </div>
        </DialogContent>
        <DialogActions className={classNames({ [Style.ButtonsWrap]: (page === 1) })}>
          <Button variation="secondary" onClick={secondaryButtonHandler} disabled={submitting}>
            {renderSecondaryButtonText()}
          </Button>
          <Button onClick={primaryButtonHandler} disabled={isContinueDisabled} isLoading={submitting}>{renderPrimaryButtonText()}</Button>
        </DialogActions>
      </Dialog>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('MinorToMajorModal')(MinorToMajorModal));
