import disallowUndefinedProperties from '~/utils/disallowUndefined';
import { pick, keys, uniq, flatMap, some } from 'lodash';
import Colors from '~/../assets/styles/export/colors';
import deepMerge from 'deepmerge';
import SeriesStoreRaw from './series/series';

const SeriesStore = disallowUndefinedProperties(SeriesStoreRaw);

const generateSeries = (series, pdfFix, dataLabelsOverride = false) => {
  const options = (pdfFix && SeriesStoreRaw[`${series.name}PdfFixed`]) || SeriesStore[series.name];
  const overrides = uniq(
    flatMap(
      (options.glookoCustomOptions || {}).state || {}, (configs) => keys(configs),
    ),
  );
  const defaultStateOptions = pick(options, overrides);

  let seriesData = {
    ...((pdfFix && SeriesStoreRaw[`${series.name}PdfFixed`]) || SeriesStore[series.name]),
    data: series.data,
  };

  if (dataLabelsOverride) seriesData = { ...seriesData, dataLabels: series.dataLabels };

  if (hasCgmCalculatedValues(series)) {
    seriesData.data = seriesData.data.map((point) => (point.calculated === true ? ({ ...SeriesStore['cgmCalculated'], ...point }) : point));
  }

  return deepMerge(seriesData, {
    glookoCustomOptions: {
      state: {
        defaults: defaultStateOptions,
      },
    },
  });
};

const hasCgmCalculatedValues = (series) => some(series.data, ['calculated', true]);

const initialHighchartsConfig = {
  global: {
    useUTC: true,
  },
  chart: {
    events: {
      mouseOut: null,
      mouseOver: null,
      click: null,
    },
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: Colors.transparent,
    style: {
      fontFamily: 'Lato, sans serif',
    },
  },
  title: false,
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  loading: {
    labelStyle: {
      color: Colors.black,
    },
    style: {
      backgroundColor: Colors.chartBackground,
    },
    hideDuration: 300,
  },
  rangeSelector: {
    enabled: false,
  },
  plotOptions: {
    series: {
      turboThreshold: 0,
      stickyTracking: false,
      animation: false,
      pointPlacement: 'on',
      dataLabels: {
        allowOverlap: true,
        style: {
          fontWeight: 300,
          color: Colors.black,
          textOutline: 'none',
        },
      },
    },
    columnrange: {
      borderWidth: 0,
    },
    column: {
      borderWidth: 0,
      pointPlacement: 'on',
      pointPadding: 0,
      groupPadding: 0,
      pointRange: 0.01,
    },
    pie: {
      enableMouseTracking: false,
      tooltip: {
        enabled: false,
      },
    },
  },
  xAxis: {
    labels: false,
    offset: 30,
    lineWidth: 0,
    tickWidth: 0,
  },
  yAxis: {
    min: 0,
    max: 100,
    gridLineColor: 'transparent',
    offset: 0,
    title: false,
    tickInterval: 10,
    alignTicks: false,
    endOnTick: false,
    labels: false,
  },
  series: [],
};

const secondAxis = {
  min: 0,
  max: 1,
  reversed: true,
  gridLineColor: 'transparent',
  offset: 0,
  labels: true,
  title: false,
  alignTicks: false,
  endOnTick: false,
};

export default {
  SeriesStore,
  generateSeries,
  initialHighchartsConfig,
  secondAxis,
};
