// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".TerminalSyncSelect_terminalSelect div[class*=dropdown__multi-value]{background:none}.TerminalSyncSelect_terminalSelect div[class*=dropdown__option--is-selected]{background-color:#fff !important}.TerminalSyncSelect_terminalSelect div[class*=dropdown__option]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.TerminalSyncSelect_terminalSelect div[class*=dropdown__option] img{margin-right:8px}.TerminalSyncSelect_terminalSelect div[class*=dropdown__value-container]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:inline-block;color:var(--neutral-40)}", "",{"version":3,"sources":["TerminalSyncSelect.scss"],"names":[],"mappings":"AACE,qEACC,eAAA,CAGD,6EACE,gCAAA,CAGF,gEACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEA,oEACE,gBAAA,CAIJ,yEACE,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,oBAAA,CACA,uBAAA","file":"TerminalSyncSelect.scss","sourcesContent":[".terminalSelect {\n  div[class*=\"dropdown__multi-value\"]{\n   background: none;\n  }\n\n  div[class*=\"dropdown__option--is-selected\"]{\n    background-color: white !important;\n   }\n\n  div[class*=\"dropdown__option\"]{\n    display: flex;\n    align-items: center;\n\n    img {\n      margin-right: 8px;\n    }\n  }\n\n  div[class*=\"dropdown__value-container\"]{\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: inline-block;\n    color: var(--neutral-40);\n  }\n }\n"]}]);
// Exports
exports.locals = {
	"terminalSelect": "TerminalSyncSelect_terminalSelect"
};
module.exports = exports;
