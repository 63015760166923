import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { Calendar } from '@glooko/common-ui';
import '../../DateWrapper/calendar.overrides.css';
import { getTextDirection } from 'utils/I18n/I18n';
import { translate } from '../../WithTranslate/WithTranslate';

const PdfWizardCalendar = (props) => {
  const { startDateInitialValue, endDateInitialValue, blockOutDates, updateTimestampsPdfWizard, maxDate, validators, t } = props;

  const [startDate, setStartDate] = React.useState(startDateInitialValue);
  const [endDate, setEndDate] = React.useState(endDateInitialValue);

  const onDatePickerApply = () => {
    updateTimestampsPdfWizard(
      startDate.startOf('day').toISOString(),
      endDate.endOf('day').toISOString(),
    );
  };
  return (
    <Calendar
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      handleApply={onDatePickerApply}
      isOutsideRange={(day) => (blockOutDates && day.isAfter(maxDate))}
      validators={validators}
      isRTL={getTextDirection() === 'rtl'}
      applyText={t('apply')}
      cancelText={t('cancel')}
    />
  );
};

PdfWizardCalendar.propTypes = {
  startDateInitialValue: momentPropTypes.momentObj,
  endDateInitialValue: momentPropTypes.momentObj,
  blockOutDates: PropTypes.bool.isRequired,
  updateTimestampsPdfWizard: PropTypes.func.isRequired,
  maxDate: momentPropTypes.momentObj,
  validators: PropTypes.arrayOf(PropTypes.shape({
    rules: PropTypes.func,
    message: PropTypes.string,
  })),
  t: PropTypes.func.isRequired,
};

export default translate('PdfWizardCalendar')(PdfWizardCalendar);
