export function trackMidsChangePatientTherapy() {
  window.eventLogging.trackMidsChangePatientTherapy();
}

export function trackMidsEndPatientProgram() {
  window.eventLogging.trackMidsEndPatientProgram();
}

export function trackMidsRestartPatientProgram() {
  window.eventLogging.trackMidsRestartPatientProgram();
}

export function trackMidsResumePatientProgram() {
  window.eventLogging.trackMidsResumePatientProgram();
}

export function trackMidsSuspendPatientProgram() {
  window.eventLogging.trackMidsSuspendPatientProgram();
}

export function trackWebDatePicker() {
  window.eventLogging.trackWebDatePicker();
}

export function trackWebTimeFramePicker() {
  window.eventLogging.trackWebTimeFramePicker();
}

export function trackWebDataTypeToggled() {
  window.eventLogging.trackWebDataTypeToggled();
}

export function trackWebTimeFrameShifted() {
  window.eventLogging.trackWebTimeFrameShifted();
}

export function trackPdfWizardFavoriteSelected() {
  window.eventLogging.trackPdfWizardFavoriteSelected();
}

export function trackCreatedPdfReport(payload) {
  window.eventLogging.trackCreatedPdfReport(payload);
}

export function trackSummaryTimeStatisticsClicked(buttonName) {
  window.eventLogging.trackSummaryTimeStatisticsClicked(buttonName);
}

export function trackExportedCsv(payload) {
  window.eventLogging.trackExportedCsv(payload);
}

export function trackGraphsOverviewClicked() {
  window.eventLogging.trackGraphsOverviewClicked();
}

export function trackGraphsOverlayClicked() {
  window.eventLogging.trackGraphsOverlayClicked();
}

export function trackGraphsWeekViewClicked() {
  window.eventLogging.trackGraphsWeekViewClicked();
}

export function trackGraphsCalendarClicked() {
  window.eventLogging.trackGraphsCalendarClicked();
}

export function trackGraphsOverviewTimeFrameClicked() {
  window.eventLogging.trackGraphsOverviewTimeFrameClicked();
}

export function trackGraphsOverviewPercentilesClicked(state) {
  window.eventLogging.trackGraphsOverviewPercentilesClicked(state);
}

export function trackGraphsOverviewAverageClicked(state) {
  window.eventLogging.trackGraphsOverviewAverageClicked(state);
}

export function trackGraphsOverlayDayOfWeekClicked() {
  window.eventLogging.trackGraphsOverlayDayOfWeekClicked();
}

export function trackGraphsOverlayScatterClicked() {
  window.eventLogging.trackGraphsOverlayScatterClicked();
}

export function trackGraphsOverlayScatterConnectDaysClicked(state) {
  window.eventLogging.trackGraphsOverlayScatterConnectDaysClicked(state);
}

export function trackGraphsOverlayScatterAverageClicked(state) {
  window.eventLogging.trackGraphsOverlayScatterAverageClicked(state);
}

export function trackGraphsOverlayScatterBgReadingClicked() {
  window.eventLogging.trackGraphsOverlayScatterBgReadingClicked();
}

export function trackGraphsOverlayBoxPlotClicked() {
  window.eventLogging.trackGraphsOverlayBoxPlotClicked();
}

export function trackGraphsOverlayLowestHighestClicked(state, tab) {
  window.eventLogging.trackGraphsOverlayLowestHighestClicked(state, tab);
}

export function trackGraphsOverlayMedianClicked(state, tab) {
  window.eventLogging.trackGraphsOverlayMedianClicked(state, tab);
}

export function trackGraphsOverlaySpaghettiClicked() {
  window.eventLogging.trackGraphsOverlaySpaghettiClicked();
}

export function trackGraphsOverlaySpaghettiTracingClicked() {
  window.eventLogging.trackGraphsOverlaySpaghettiTracingClicked();
}

export function trackGraphsOverlayAgpClicked() {
  window.eventLogging.trackGraphsOverlayAgpClicked();
}

export function trackGraphsCalendarDayClicked() {
  window.eventLogging.trackGraphsCalendarDayClicked();
}

export function trackGraphsWeekViewDayClicked() {
  window.eventLogging.trackGraphsWeekViewDayClicked();
}

export function trackInsightsSetSiteChangeClicked() {
  window.eventLogging.trackInsightsSetSiteChangeClicked();
}

export function trackInsightsTempBasalDecreaseSuspendClicked() {
  window.eventLogging.trackInsightsTempBasalDecreaseSuspendClicked();
}

export function trackInsightsTempBasalIncreaseClicked() {
  window.eventLogging.trackInsightsTempBasalIncreaseClicked();
}

export function trackSelectedDevice(payload) {
  window.eventLogging.trackSelectedDevice(payload);
}

export function trackAssignDevicesTabClicked() {
  window.eventLogging.trackAssignDevicesTabClicked();
}

export function trackFilteredTypeSelected(source) {
  window.eventLogging.trackFilteredTypeSelected(source);
}

export function trackAssignToPatientClicked(terminalSync) {
  window.eventLogging.trackAssignToPatientClicked(terminalSync);
}

export function trackConfirmDOBModalButtonClicked(terminalSync, glookoCode, selection, success) {
  window.eventLogging.trackConfirmDOBModalButtonClicked(terminalSync, glookoCode, selection, success);
}

export function trackAddEmailBannerButtonClicked(terminalSync, glookoCode, selection) {
  window.eventLogging.trackAddEmailBannerButtonClicked(terminalSync, glookoCode, selection);
}

export function trackFindModalButtonClicked(terminalSync, selection, mrnFilled) {
  window.eventLogging.trackFindModalButtonClicked(terminalSync, selection, mrnFilled);
}

export function trackCreateModalButtonClicked(terminalSync, selection, todFilled, emailFilled, phoneFilled) {
  window.eventLogging.trackCreateModalButtonClicked(terminalSync, selection, todFilled, emailFilled, phoneFilled);
}

export function trackPatientClicked(device) {
  window.eventLogging.trackPatientClicked(device);
}

export function trackCreateReportClicked(device) {
  window.eventLogging.trackCreateReportClicked(device);
}

export function trackUnassignClicked(device) {
  window.eventLogging.trackUnassignClicked(device);
}
export function trackAssignToOtherClicked(device, glookoCode) {
  window.eventLogging.trackAssignToOtherClicked(device, glookoCode);
}
export function trackSelectPatientModalButtonClicked(terminalSync, glookoCode, selection, matchCount) {
  window.eventLogging.trackSelectPatientModalButtonClicked(terminalSync, glookoCode, selection, matchCount);
}

// Search
export function trackSearchTypedText(searchBy) {
  window.eventLogging.trackSearchTypedText(searchBy);
}
export function trackSearchClickedPatient(category, patientId, patientGlookoCode) {
  window.eventLogging.trackSearchClickedPatient(category, patientId, patientGlookoCode);
}
export function trackSearchClickedPdf(category, patientId, patientGlookoCode) {
  window.eventLogging.trackSearchClickedPdf(category, patientId, patientGlookoCode);
}
export function trackSearchViewedAllMatches(totalMatch, callback) {
  window.eventLogging.trackSearchViewedAllMatches(totalMatch, callback);
}
export function trackFilterApplied(
  selection, tagCount, customTagCount,
  midsCount, flagsCount) {
  window.eventLogging.trackFilterApplied(
    selection, tagCount, customTagCount,
    midsCount, flagsCount);
}

export function trackViewCarePrograms(cpName) {
  window.eventLogging.trackViewCarePrograms(cpName);
}

export function trackWebPageLoaded(dataSource) {
  window.eventLogging.trackWebPageLoaded(dataSource);
}
export function trackChangeEmailClicked() {
  window.eventLogging.trackChangeEmailClicked();
}
export function trackChangePasswordClicked() {
  window.eventLogging.trackChangePasswordClicked();
}
export function trackChangeLanguageClicked() {
  window.eventLogging.trackChangeLanguageClicked();
}
export function trackUpdatedDeviceDataSharing(partner, choice) {
  window.eventLogging.trackUpdatedDeviceDataSharing(partner, choice);
}
export function trackSelectedAppsDevices(selectedDevice) {
  window.eventLogging.trackSelectedAppsDevices(selectedDevice);
}
