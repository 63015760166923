export default class ProviderGroupSiteHelper {
  static hasSubscriptionFeature(state, featureName) {
    return state.providerGroupSite.subscriptionModel.features.includes(featureName);
  }

  static getPatientTags(state, patientId) {
    const patient = state.providerGroupSite.providerPatients.find((p) => p.id === patientId);
    return (patient) ? patient.tags : [];
  }

  // returns false if there are no filters
  static hasSearchFilters(state) {
    const { tags, customTags, midsTags, careProgramTags, flags, patientStatuses } = state.providerGroupSite.filters;

    const filters = [tags, customTags, midsTags, careProgramTags, flags, patientStatuses];
    const hasFilters = filters.some(
      (filter) => !!filter && filter.length > 0 && filter.some((status) => status.checked === true),
    );
    return hasFilters;
  }
}
