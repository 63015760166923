import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import elementClass from 'element-class';
import { germanLocaleClassName } from '~/../assets/styles/gl_classNames.scss';
import * as ENV from '~/bundles/shared/constants/env';
import translations from './Translations';
import { defaultLocale, locale } from './FromRails';
import './UpdateMomentConfig';

i18next
  .use(initReactI18next)
  .init({
    fallbackLng: ENV.debug() ? false : defaultLocale,
    lng: locale,
    debug: ENV.debug(),
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
    react: {
      wait: true,
    },
    resources: translations,
    defaultNS: 'translation',
  });

i18next.on('languageChanged', (newLocale) => {
  moment.locale(newLocale);

  elementClass(
    document.getElementsByTagName('html')[0],
  )[(newLocale === 'de' ? 'add' : 'remove')](germanLocaleClassName);
});

i18next.changeLanguage(locale);

export const getCollator = () => new Intl.Collator(i18next.language.substr(0, 2));

export const getTextDirection = () => i18next.dir(i18next.language);

export default i18next;
