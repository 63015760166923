import classNames from 'classnames';
import { localizedPercentage } from 'utils/i18nFormats';
import { translate } from 'bundles/shared/components/WithTranslate/WithTranslate';
import Style from './BucketedGlucoseTooltip.scss';

const seriesToChartIndex = { wellAbove: 0, above: 1, inRange: 2, below: 3, wellBelow: 4 };

interface BucketedGlucoseTooltipProps extends WithTranslationFunction {
  points: {
    point: {
      options: {
        tooltipLabel: string;
        value: number
      };
    };
  }[];
}

const BucketedGlucoseTooltip = ({ points, t }: BucketedGlucoseTooltipProps) => {
  const tooltipLabel = points[0].point.options.tooltipLabel;

  return (
    <div className={classNames(Style.BucketedGlucoseTooltip, Style.arrowLeft)}>
      <div className={Style.row}>
        <div className={Style.wellAbove} />
        <span className={Style.label}>{t('wellAboveRange')}</span>

        <span className={Style.wellAbovePercentage}>
          {localizedPercentage(points[seriesToChartIndex.wellAbove].point.options.value)}
        </span>
      </div>

      <div className={Style.row}>
        <div className={Style.above} />
        <span className={Style.label}>{t('aboveRange')}</span>

        <span className={Style.abovePercentage}>
          {localizedPercentage(points[seriesToChartIndex.above].point.options.value)}
        </span>
      </div>

      <div className={Style.row}>
        <div className={Style.inRange} />
        <span className={Style.label}>{t('inTargetRange')}</span>

        <span className={Style.inRangePercentage}>
          {localizedPercentage(points[seriesToChartIndex.inRange].point.options.value)}
        </span>
      </div>

      <div className={Style.row}>
        <div className={Style.below} />
        <span className={Style.label}>{t('belowRange')}</span>

        <span className={Style.belowPercentage}>
          {localizedPercentage(points[seriesToChartIndex.below].point.options.value)}
        </span>
      </div>

      <div className={Style.row}>
        <div className={Style.wellBelow} />
        <span className={Style.label}>{t('wellBelowRange')}</span>

        <span className={Style.wellBelowPercentage}>
          {localizedPercentage(points[seriesToChartIndex.wellBelow].point.options.value)}
        </span>
      </div>

      <div className={Style.bucketLabel}>{tooltipLabel}</div>
    </div>
  );
};

export default translate('BucketedGlucoseTooltip')(BucketedGlucoseTooltip);
