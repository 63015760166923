import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@glooko/common-ui';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import { find } from 'lodash';
import { ALL_TIMEFRAMES } from '~/bundles/shared/constants/time';
import GraphsHelper from '~/redux/modules/graphs/GraphsHelper';
import Style from './DateRangeSelect.scss';

class DateRangeSelect extends Component {
  static propTypes = {
    availableTimeFrames: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
      })),
    timeFrameIndex: PropTypes.number.isRequired,
    actions: PropTypes.shape({
      updateTimeframe: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 0,
    actions: { updateTimeframe: () => {} },
  };

  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  onSelectChange(e) {
    this.props.actions.updateTimeframe(e.value);
  }

  arrowRenderer() {
    return (
      <div class={Style.arrowDown}>
        <p><FaAngleDown /></p>
      </div>
    );
  }

  render() {
    const { availableTimeFrames, timeFrameIndex } = this.props;

    const timeframes = availableTimeFrames.map((timeframe) => ({
      value: timeframe.value,
      label: GraphsHelper.retrieveLabel(timeframe.value),
    }));
    const indexValue = availableTimeFrames[timeFrameIndex].value;
    const defaultValue = find(timeframes, (item) => item.value === indexValue);

    return (
      <div className={Style.DateRangeSelect}>
        <Select
          options={timeframes}
          onChange={this.onSelectChange}
          value={defaultValue}
          className={Style.selectContainer}
          size='small'
        />
      </div>
    );
  }
}

export default DateRangeSelect;
