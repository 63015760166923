import { filter } from 'lodash';
import GraphsHelper from '~/redux/modules/graphs/GraphsHelper';
import { keyFromParams } from '~/redux/modules/graphs/graphs';
import {
  bgGraphSeriesName,
  bgGraphAverageName,
  bgGraphPercentilesName,
  cgmGraphAverageName,
  cgmGraphPercentilesName,
  insulinPumpSeriesNameProcessed,
  insulinManualSeriesNameProcessed,
  insulinInlinePumpSeriesNameProcessed,
  insulinInlineManualSeriesNameProcessed,
  cgmGraphSeriesName,
  exerciseSeriesNamePdfProcessed,
  exerciseSeriesNameProcessed,
  stepsSeriesNamePdfProcessed,
  stepsSeriesNameProcessed,
  carbsSeriesNamePdfProcessed,
  carbsSeriesNameProcessed,
} from '../../../constants/overviewDaysSeries';

const sharedSeries = (series, seriesExercise, inPDF) => {
  const exerciseSeriesNamesForFilter = inPDF ? exerciseSeriesNamePdfProcessed : exerciseSeriesNameProcessed;
  const stepsSeriesNamesForFilter = inPDF ? stepsSeriesNamePdfProcessed : stepsSeriesNameProcessed;
  const carbsSeriesNamesForFilter = inPDF ? carbsSeriesNamePdfProcessed : carbsSeriesNameProcessed;

  return {
    insulinPumpSeries: filter(series, (obj) => insulinPumpSeriesNameProcessed.includes(obj.name)),
    insulinManualSeries: filter(series, (obj) => insulinManualSeriesNameProcessed.includes(obj.name)),
    insulinInlinePumpSeries: filter(series, (obj) => insulinInlinePumpSeriesNameProcessed.includes(obj.name)),
    insulinInlineManualSeries: filter(series, (obj) => insulinInlineManualSeriesNameProcessed.includes(obj.name)),
    carbsSeries: filter(series, (obj) => carbsSeriesNamesForFilter.includes(obj.name)),
    exerciseSeries: filter(seriesExercise, (obj) => exerciseSeriesNamesForFilter.includes(obj.name)),
    stepsSeries: filter(seriesExercise, (obj) => stepsSeriesNamesForFilter.includes(obj.name)),
  };
};

export const overviewDaysGraphSeries = (
  series, seriesExercise, seriesAverage, percentiles, average, inPDF,
) => ({
    ...sharedSeries(series, seriesExercise, inPDF),
    bgGraphSeries: [
      ...(percentiles ?
        filter(seriesAverage, (obj) => bgGraphPercentilesName.includes(obj.name)) :
        filter(series, (obj) => bgGraphSeriesName.includes(obj.name))),
      ...(average ? (filter(seriesAverage, (obj) => bgGraphAverageName.includes(obj.name))) : []),
    ],
    cgmGraphSeries: [
      ...(percentiles ?
        filter(seriesAverage, (obj) => cgmGraphPercentilesName.includes(obj.name)) :
        filter(series, (obj) => cgmGraphSeriesName.includes(obj.name))),
      ...(average ? (filter(seriesAverage, (obj) => cgmGraphAverageName.includes(obj.name))) : []),
    ],
  });

export const overviewDaysLegendSeries = (series, seriesExercise, seriesAverage, inPDF) => ({
    ...sharedSeries(series, seriesExercise, inPDF),
    bgCgmGraphSeries: [
      ...filter(series, (obj) => bgGraphSeriesName.includes(obj.name)),
      ...filter(series, (obj) => cgmGraphSeriesName.includes(obj.name)),
    ],
    bgCgmAveragesGraphSeries: [
      ...filter(seriesAverage, (obj) => bgGraphAverageName.includes(obj.name)),
      ...filter(seriesAverage, (obj) => cgmGraphAverageName.includes(obj.name)),
      ...filter(seriesAverage, (obj) => bgGraphPercentilesName.includes(obj.name)),
      ...filter(seriesAverage, (obj) => cgmGraphPercentilesName.includes(obj.name)),
    ],
  });

export const retrieveFetchStatusAndSeries = (state, startTimestamp, endTimestamp, id) => {
  const seriesKey = keyFromParams({
    startTimestamp,
    endTimestamp,
    id,
  });

  return GraphsHelper.retrieveFetchStatusAndSeries(state, seriesKey);
};
