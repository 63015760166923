import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import useSanitizer from '~/hooks/useSanitizer';
import { translate } from '../WithTranslate/WithTranslate.jsx';
import Images from '../../../../utils/ImageStore';
import Style from './FileUploaderPopup.scss';
import SupportLinks from '../../../../utils/support';

const FileUploaderPopupSuccess = (props) => {
  const { t, onDone, onViewData, fileName } = props;
  const sanitizer = useSanitizer();

  return (
    <Dialog open={true} size="medium" onClose={onDone} dataAttributes={{ testid: 'file-uploader-success' }}>
      <DialogHeader dataAttributes={{ testid: 'file-uploader-success' }}>
        <h1>{t('uploadSuccessTitle')}</h1>
      </DialogHeader>
      <DialogContent dataAttributes={{ testid: 'file-uploader-success' }}>
        <div className={Style.success}>
          {t('uploadSuccessFile')}: {fileName}
          <img src={Images.integrationTickSuccess} alt="" />
        </div>
        <p>{t('uploadSuccessMsgLine1')}</p>
        <p>
          {t('uploadSuccessMsgLine2')}<br />
          {t('uploadSuccessMsgLine3')}
        </p>
        <p dangerouslySetInnerHTML={{ __html: sanitizer(t('havingProblems', { url: SupportLinks.fileTicket() })) }} />
      </DialogContent>
      <DialogActions dataAttributes={{ testid: 'file-uploader-success' }}>
        <Button variation="secondary" onClick={onDone} dataAttributes={{ testid: 'file-uploader-success-done' }}>{t('uploadSuccessDoneButton')}</Button>
        <Button onClick={onViewData} dataAttributes={{ testid: 'file-uploader-success-view-data' }}>{t('uploadSuccessViewDataButton')}</Button>
      </DialogActions>
    </Dialog>
  );
};

FileUploaderPopupSuccess.propTypes = {
  t: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired,
  onViewData: PropTypes.func.isRequired,
};

FileUploaderPopupSuccess.defaultProps = {
  t: /* istanbul ignore next */ () => {},
  onDone: /* istanbul ignore next */ () => {},
  onViewData: /* istanbul ignore next */ () => {},
};

export default translate('FileUploaderPopup')(FileUploaderPopupSuccess);
