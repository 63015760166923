import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { getCurrentPatientICPADataThunk } from '~/redux/thunks/patientICPADetails';
import UpdatePatientInfoPresenter from './UpdatePatientInfoPresenter';

const mapStateToProps = (state) => {
  const { countryOfJurisdiction } = state.providerGroupSite;
  const selectedCountry = state.countries.allCountries.find(
    (c) => c.countryCode === countryOfJurisdiction,
  );
  return {
    patientICPAData: state.providerGroupSite.currentPatientICPADetails,
    currentPatient: state.users.currentUsers.currentPatient,
    ageOfMajority: selectedCountry ? selectedCountry.majorityAge : undefined,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getPatientICPAData: getCurrentPatientICPADataThunk,
  },
  dispatch,
);

export class UpdatePatientInfoContainer extends React.Component {
  static propTypes = {
    getPatientICPAData: PropTypes.func.isRequired,
    patientICPAData: PropTypes.object,
    currentPatient: PropTypes.object.isRequired,
  }

  static defaultProps = {
    patientICPAData: {
      userDetails: {},
      status: '',
    },
  }

  componentDidMount() {
    const { currentPatient } = this.props;
    const { id } = currentPatient;
    this.getCurrentUserICPAFields(id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentPatient } = this.props;
    if (nextProps.currentPatient.id !== currentPatient.id) {
      this.getCurrentUserICPAFields(nextProps.currentPatient.id);
    }
  }

  getCurrentUserICPAFields = (id) => {
    const { getPatientICPAData } = this.props;
    if (id) {
      getPatientICPAData(id);
    }
  }

  render() {
    return (
      <UpdatePatientInfoPresenter
        {...this.props}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePatientInfoContainer);
