import React, { ButtonHTMLAttributes } from 'react';
import Style from './InfoButton.scss';

export enum ButtonType {
  info = 'info',
  warning = 'warning',
}

export interface InfoButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType;
  style?: React.CSSProperties;
}

const InfoButton = (props: InfoButtonProps) => {
  const {
    onClick,
    buttonType = ButtonType.info,
    style,
    ...otherProps
  } = props;

  return (
    <button
      className={Style[buttonType]}
      onClick={onClick}
      style={style}
      type="button"
      {...otherProps}
    />
  );
};

export default InfoButton;
