import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import { usePapaParse } from 'react-papaparse';
import UserHelper from '~/redux/modules/users/UserHelper';
import useSanitizer from '~/hooks/useSanitizer';
import { translate } from '../WithTranslate/WithTranslate.jsx';

const mapStateToProps = (state) => {
  const currentUserName = UserHelper.userFullName(state);
  return { currentUserName };
};

const FileUploaderPopupConfirm = (props) => {
  const { t, onConfirm, onDecline, file, currentUserName } = props;
  const { readString } = usePapaParse();
  const sanitizer = useSanitizer();
  const [parsedUploadData, setParsedUploadData] = useState({});

  useEffect(() => {
    const readFile = async () => {
      const fileContent = await file.text();
      const parsedCsv = readString(fileContent, { header: true });
      setParsedUploadData(parsedCsv.data[0]);
    };

    readFile();
  }, []);

  return (
    <Dialog open={true} size="medium" onClose={onDecline} dataAttributes={{ testid: 'file-uploader-confirm' }}>
      <DialogHeader dataAttributes={{ testid: 'file-uploader-confirm' }}>
        <h2>{t('uploadConfirmTitle')}</h2>
      </DialogHeader>

      <DialogContent dataAttributes={{ testid: 'file-uploader-confirm' }}>
        <p>
          <span>
            {t('uploadConfirmName')} <strong>{`${parsedUploadData['First Name']} ${parsedUploadData['Last Name']}`}</strong>
          </span>
          <br />
          <span>
            {t('uploadConfirmPeriod')} <strong>{parsedUploadData['Start Date']} - {parsedUploadData['End Date']}</strong>
          </span>
          <br />
          <span>
            {t('uploadConfirmCurrentPatient')} <strong>{currentUserName}</strong>
          </span>
        </p>
        <p>
          <i dangerouslySetInnerHTML={{ __html: sanitizer(t('privacyNotice', { url: 'https://glooko.com/privacy/' })) }} />
        </p>
      </DialogContent>

      <DialogActions dataAttributes={{ testid: 'file-uploader-confirm' }}>
        <Button
          variation="secondary"
          onClick={onDecline}
          dataAttributes={{ testid: 'file-uploader-cancel' }}
        >
          {t('cancelButton')}
        </Button>

        <Button onClick={onConfirm} dataAttributes={{ testid: 'file-uploader-confirm' }}>{t('confirmButton')}</Button>
      </DialogActions>
    </Dialog>
  );
};

FileUploaderPopupConfirm.propTypes = {
  t: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  currentUserName: PropTypes.string.isRequired,
};

FileUploaderPopupConfirm.defaultProps = {
  t: /* istanbul ignore next */ () => { },
  onConfirm: /* istanbul ignore next */ () => { },
  onDecline: /* istanbul ignore next */ () => { },
};

export default connect(mapStateToProps, null)(translate('FileUploaderPopup')(FileUploaderPopupConfirm));
