import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from '@glooko/common-ui';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  MIDS_FEATURE,
  PATIENT_DEACTIVATION_FEATURE,
  POP_INSIGHTS_FEATURE,
} from '~/bundles/shared/constants/providerGroupSite';

import {
  setPatientTablePageIndex,
} from '~/bundles/shared/helpers/localStorageHelper';

import { buildFilterParams } from '~/bundles/shared/helpers/filterParamsHelper';
import ProviderGroupSiteHelper from '~/redux/modules/providerGroupSite/ProviderGroupSiteHelper';

import { fetchFilterProviderPatientsThunk } from '~/redux/thunks/providerGroupSite/providerGroupSite';
import { updateFiltersList } from '~/redux/modules/providerGroupSite/providerGroupSite';

import { trackFilterApplied } from '~/services/eventLogging';
import { clearPopInsightsFilters } from '~/services/providerGroupSiteApi';
import { USER_TYPE_PRO_ADMIN } from '~/bundles/shared/constants/users';
import FilterPatientsPresenter from '../FilterPatientsPresenter/FilterPatientsPresenter';
import Style from './FilterPatientsContainer.scss';

const mapStateToProps = (state) => ({
    filters: state.providerGroupSite.filters,
    showMids: ProviderGroupSiteHelper.hasSubscriptionFeature(state, MIDS_FEATURE),
    showIncludeDeactivated: ProviderGroupSiteHelper.hasSubscriptionFeature(
      state,
      PATIENT_DEACTIVATION_FEATURE,
    ),
    showDuplicatesFilter: state.users.currentUsers.currentUser.userType === USER_TYPE_PRO_ADMIN,
    showFlags: ProviderGroupSiteHelper.hasSubscriptionFeature(state, POP_INSIGHTS_FEATURE),
    searchParams: state.searchParams,
    isPopulationInsightsFilter: state.providerGroupSite.isPopulationInsightsFilter,
    isShowCareProgramsTags: state.providerGroupSite.carePrograms &&
    state.providerGroupSite.carePrograms.length > 0,
    paginationData: state.providerGroupSite.paginationData,
  });

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    dispatchUpdateFiltersList: updateFiltersList,
    dispatchFilterPatientList: fetchFilterProviderPatientsThunk,
  },
  dispatch,
);

class FilterPatientsContainer extends Component {
  static defaultProps = {
    isPopulationInsightsFilter: false,
    atRiskFilterMessage: '',
    clearAtRiskFilterMessage: '',
    isShowCareProgramsTags: false,
  }

  static propTypes = {
    filters: PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      customTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      patientStatuses: PropTypes.arrayOf(PropTypes.shape({})),
      midsTags: PropTypes.arrayOf(PropTypes.shape({})),
      careProgramTags: PropTypes.arrayOf(PropTypes.shape({})),
      flags: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    showIncludeDeactivated: PropTypes.bool.isRequired,
    showMids: PropTypes.bool.isRequired,
    showFlags: PropTypes.bool.isRequired,
    dispatchUpdateFiltersList: PropTypes.func.isRequired,
    dispatchFilterPatientList: PropTypes.func.isRequired,
    searchParams: PropTypes.object.isRequired,
    paginationData: PropTypes.object.isRequired,
    isPopulationInsightsFilter: PropTypes.bool,
    atRiskFilterMessage: PropTypes.string,
    clearAtRiskFilterMessage: PropTypes.string,
    isShowCareProgramsTags: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
      currentFilters: {
        tags: props.filters.tags,
        customTags: props.filters.customTags,
        patientStatuses: props.filters.patientStatuses,
        midsTags: props.filters.midsTags,
        careProgramTags: props.filters.careProgramTags,
        flags: props.filters.flags,
      },
      expanded: false,
      searchString: '',
      includeDeactivated: this.includeDeactivated(),
      duplicatesFilterSelected: props.searchParams.duplicates,
    };

    this.debouncedSearch = _.debounce((e) => {
      this.setState((prevState) => ({ ...prevState, searchString: e.target.value }));
    }, 300);
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.setState((prevState) => ({
      ...prevState,
      currentFilters: {
        ...nextProps.filters,
      },
    }));
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  onDropdownClick = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ ...prevState, expanded: !prevState.expanded }));
  }

  onFilterClick = (e) => {
    const id = e.target.parentNode.id;
    const key = id.split('-')[0];

    this.setState((prevState) => {
      const newState = prevState.currentFilters[key].map((element) => {
        if (element.id !== id) return element;
        return { ...element, checked: !element.checked };
      });

      return {
        ...prevState,
        currentFilters: {
          ...prevState.currentFilters,
          [key]: newState,
        },
      };
    });
  }

  onSearchKeyUp = (e) => {
    e.persist();
    this.debouncedSearch(e);
  }

  onIncludeDeactivatedClick = () => {
    this.setState((prevState) => (
      { ...prevState, includeDeactivated: !prevState.includeDeactivated }
    ));
  }

  onDuplicatesFilterClick = () => {
    this.setState((prevState) => (
      { ...prevState, duplicatesFilterSelected: !prevState.duplicatesFilterSelected }
    ));
  }

  onCancelClick = (e) => {
    e.preventDefault();

    this.resetCurrentSelection();
    this.trackFilterApplied('cancel');
  }

  onApplyClick = (e) => {
    e.preventDefault();

    const {
      dispatchFilterPatientList,
    } = this.props;
    const searchParams = { ...this.props.searchParams };
    const { currentFilters, includeDeactivated, duplicatesFilterSelected } = this.state;

    if (this.isApplyDisabled()) return;
    this.setState((prevState) => ({ ...prevState, expanded: false }));

    const filterParams = buildFilterParams(currentFilters);
    this.selectedDeactivatedFilter(includeDeactivated);

    searchParams.includeDeactivated = includeDeactivated;
    searchParams.duplicates = duplicatesFilterSelected;
    window.sessionStorage.setItem('showDuplicateAccounts', duplicatesFilterSelected);
    searchParams.page = 1;
    setPatientTablePageIndex(1);
    window.storeSelectedFilters(this.getSelectedFiltersObj(currentFilters));
    dispatchFilterPatientList(searchParams, currentFilters, filterParams);

    this.trackFilterApplied('apply');
  }

  handleShowDuplicates = (e) => {
    e.preventDefault();

    const {
      dispatchFilterPatientList,
    } = this.props;
    const searchParams = { ...this.props.searchParams };
    const { currentFilters, includeDeactivated } = this.state;

    const filterParams = buildFilterParams(currentFilters);

    this.setState({ duplicatesFilterSelected: true });
    window.sessionStorage.setItem('showDuplicateAccounts', true);
    searchParams.duplicates = true;

    searchParams.includeDeactivated = includeDeactivated;
    searchParams.page = 1;
    setPatientTablePageIndex(1);

    // fetch the duplicate patients
    dispatchFilterPatientList(searchParams, currentFilters, filterParams);
  }

  onClearFilter = (id) => {
    const { filters } = this.props;
    const { includeDeactivated, duplicatesFilterSelected } = this.state;
    const isClearingDuplicates = id.startsWith('view-duplicates-0');
    const searchParams = { ...this.props.searchParams, duplicates: isClearingDuplicates ? false : duplicatesFilterSelected };
    const key = id.split('-');
    let updatedFilters = { ...filters };

    if (filters[key[0]]) {
      const updatedKeyFilters = filters[key[0]].map((filter) => {
        if (filter.id !== `${key[0]}-${key[1]}`) return filter;
        return { ...filter, checked: false };
      });
      updatedFilters = { ...filters, [key[0]]: updatedKeyFilters };
      window.storeSelectedFilters(this.getSelectedFiltersObj(updatedFilters));
    }

    this.selectedDeactivatedFilter(includeDeactivated);

    if (isClearingDuplicates) {
      this.setState({ duplicatesFilterSelected: false });
      window.sessionStorage.setItem('showDuplicateAccounts', false);
    }

    const filterParams = buildFilterParams(updatedFilters);
    searchParams.page = 1;
    setPatientTablePageIndex(1);
    this.props.dispatchFilterPatientList(searchParams, updatedFilters, filterParams);
  }

  onClearAll = () => {
    const { filters, showDuplicatesFilter } = this.props;
    const searchParams = { ...this.props.searchParams, duplicates: false };
    const { includeDeactivated } = this.state;
    const updatedFilters = {
      tags: filters.tags.map((filter) => ({ ...filter, checked: false })),
      customTags: filters.customTags.map((filter) => ({ ...filter, checked: false })),
      patientStatuses: filters.patientStatuses.map((filter) => ({ ...filter, checked: false })),
      midsTags: filters.midsTags.map((filter) => ({ ...filter, checked: false })),
      flags: filters.flags.map((filter) => ({ ...filter, checked: false })),
      careProgramTags: filters.careProgramTags.map((filter) => ({ ...filter, checked: false })),
    };

    if (includeDeactivated) {
      this.selectedDeactivatedFilter(false);
      this.setState({ includeDeactivated: false });
    }

    if (showDuplicatesFilter) {
      this.setState({ duplicatesFilterSelected: false });
      window.sessionStorage.setItem('showDuplicateAccounts', false);
    }

    window.removeFilteredSearchResultsFromSession(includeDeactivated);

    const filterParams = buildFilterParams(updatedFilters);
    searchParams.page = 1;
    setPatientTablePageIndex(1);
    this.props.dispatchFilterPatientList(searchParams, updatedFilters, filterParams);
  }

  getSelectedFiltersObj = (filters) => {
    const selectedFilters = {
      tags: filters.tags.filter((filter) => filter.checked).map((filter) => filter.label),
      customTags: filters.customTags.filter((filter) => filter.checked).map((filter) => filter.label),
      patientStatuses: filters.patientStatuses.filter((filter) => filter.checked).map((filter) => filter.id.split('-')[1]),
      midsTags: filters.midsTags.filter((filter) => filter.checked).map((filter) => filter.label),
      careProgramTags: filters.careProgramTags.filter((filter) => filter.checked)
        .map((filter) => filter.label),
      flags: filters.flags.filter((filter) => filter.checked).map((filter) => filter.originalId),

    };
    return selectedFilters;
  }

  clearAtRiskPopulationFilters = () => {
    clearPopInsightsFilters()
      .then((response) => {
        if (response && response.status === 200) {
          window.location.assign('/patients');
        }
      });
  }

  resetCurrentSelection = () => {
    const {
      tags, customTags, midsTags, careProgramTags, flags, patientStatuses,
    } = this.props.filters;

    this.setState((prevState) => ({
      ...prevState,
      expanded: false,
      includeDeactivated: this.includeDeactivated(),
      duplicatesFilterSelected: this.props.searchParams.duplicates,
      currentFilters: {
        ...prevState.currentFilters,
        tags,
        customTags,
        midsTags,
        careProgramTags,
        flags,
        patientStatuses,
      },
    }));
  }

  handleClickOutside = (event) => {
    if (!this.node.contains(event.target)) {
      this.resetCurrentSelection();
    }
  }

  displayFilteredSearchResults = (filters, includeDeactivated) => {
    window.displayFilteredSearchResults(
      this.getSelectedFiltersObj(filters),
      includeDeactivated,
    );
  }

  isApplyDisabled = () => {
    const { filters, showDuplicatesFilter, searchParams } = this.props;
    const { currentFilters, includeDeactivated, duplicatesFilterSelected } = this.state;
    const isCheckedCallback = (e) => e.checked;

    return !currentFilters.tags.some(isCheckedCallback) &&
           !currentFilters.customTags.some(isCheckedCallback) &&
           !currentFilters.midsTags.some(isCheckedCallback) &&
           !currentFilters.careProgramTags.some(isCheckedCallback) &&
           !currentFilters.flags.some(isCheckedCallback) &&
           !currentFilters.patientStatuses.some(isCheckedCallback) &&
           !filters.tags.some(isCheckedCallback) &&
           !filters.midsTags.some(isCheckedCallback) &&
           !filters.careProgramTags.some(isCheckedCallback) &&
           !filters.flags.some(isCheckedCallback) &&
           !filters.patientStatuses.some(isCheckedCallback) &&
           !includeDeactivated && !this.includeDeactivated() &&
           (showDuplicatesFilter && (duplicatesFilterSelected === searchParams.duplicates));
  };

  includeDeactivated = () => window.sessionStorage.include_deactivated === 'true';

  selectedDeactivatedFilter = (includeDeactivated) => {
    window.selectedDeactivatedFilter(includeDeactivated);
  }

  trackFilterApplied = (clickedButton) => {
    const { tags, customTags, midsTags, flags } = this.state.currentFilters;
    const countChecked = (array) => {
      const count = array.filter((obj) => obj.checked).length;

      return count === 0 ? null : count;
    };

    trackFilterApplied(
      clickedButton, countChecked(tags), countChecked(customTags),
      countChecked(midsTags), countChecked(flags),
    );
  };

  renderAtRiskPopulationFilters = () => {
    const { isPopulationInsightsFilter, atRiskFilterMessage,
      clearAtRiskFilterMessage } = this.props;
    return (
      isPopulationInsightsFilter &&
        <div className={Style.patientContent}>
          <Alert severity="info" dataAttributes={{ testid: 'filter-patients' }}>
            <span className={Style.patientInfo}>
              {atRiskFilterMessage}
            </span>
            <Button
              className={Style.alertPatient}
              onClick={() => { this.clearAtRiskPopulationFilters(); }}
              variation='link'
              dataAttributes={{ testid: 'filter-patients-clear-at-risk-filter' }}
            >
              {clearAtRiskFilterMessage}
            </Button>
          </Alert>
        </div>
    );
  }

  render() {
    const { filters, showIncludeDeactivated, showMids,
      isShowCareProgramsTags, showFlags, paginationData,
      showDuplicatesFilter } = this.props;
    const { currentFilters, searchString, includeDeactivated, expanded, duplicatesFilterSelected } = this.state;

    return (
      <div ref={(node) => { this.node = node; }}>
        {this.renderAtRiskPopulationFilters()}
        <FilterPatientsPresenter
          filters={filters}
          currentFilters={currentFilters}
          searchString={searchString}
          includeDeactivated={includeDeactivated}
          showIncludeDeactivated={showIncludeDeactivated}
          showMids={showMids}
          showFlags={showFlags}
          showDuplicatesFilter={showDuplicatesFilter}
          isShowingDuplicates={duplicatesFilterSelected}
          expanded={expanded}
          paginationData={paginationData}
          isApplyDisabled={this.isApplyDisabled()}
          onDropdownClick={this.onDropdownClick}
          onFilterClick={this.onFilterClick}
          onSearchKeyUp={this.onSearchKeyUp}
          onIncludeDeactivatedClick={this.onIncludeDeactivatedClick}
          onDuplicatesFilterClick={this.onDuplicatesFilterClick}
          onCancelClick={this.onCancelClick}
          onApplyClick={this.onApplyClick}
          onClearFilter={this.onClearFilter}
          onClearAll={this.onClearAll}
          onShowDuplicatesClick={this.handleShowDuplicates}
          isShowCareProgramsTags={isShowCareProgramsTags}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterPatientsContainer);
