import { merge, omitBy, isNil, has } from 'lodash';
import I18n from '~/utils/I18n/I18n';

const t = I18n.getFixedT(null, 'UserHelper');

class UserHelper {
  static displayUser(state) {
    const { currentUser, currentPatient } = state.users.currentUsers;

    return currentPatient || currentUser;
  }

  static currentUser(state) {
    return state.users.currentUsers.currentUser;
  }

  static currentPatient(state) {
    return state.users.currentUsers.currentPatient;
  }

  static currentDisplayUserPatternsEnabled(state) {
    return UserHelper.displayUser(state).patternsEnabled;
  }

  static userFullName(state) {
    const userHelper = UserHelper.displayUser(state);
    return `${userHelper.firstName} ${userHelper.lastName}`;
  }

  static userDateOfBirth(state) {
    const userHelper = UserHelper.displayUser(state);
    return userHelper.dateOfBirth;
  }

  static userTypeOfDiabetes(state) {
    const userHelper = UserHelper.displayUser(state);
    return userHelper.diabetesType;
  }

  static currentPatientMRN(state) {
    const { currentPatient } = state.users.currentUsers;
    const hasMrn = has(currentPatient, 'mrn');
    return hasMrn ? currentPatient.mrn : null;
  }

  static CurrentUserHidePatientList(state) {
    return UserHelper.currentUser(state).hidePatientList;
  }

  static displayUserIsPatient(state) {
    return UserHelper.displayUser(state).userType.toLowerCase() === 'patient';
  }

  static currentUserIsPatient(state) {
    return UserHelper.currentUser(state).userType.toLowerCase() === 'patient';
  }

  static currentUserCountry(state) {
    return UserHelper.currentUser(state).countryOfResidence;
  }

  static lastSyncTimestampStore(state) {
    return UserHelper.displayUser(state).lastSyncTimestamps;
  }

  static lastSyncTimestamp(state) {
    return UserHelper.lastSyncTimestampStore(state).lastSyncTimestamp;
  }

  static lastKioskSync(state) {
    return UserHelper.lastSyncTimestampStore(state).kiosk;
  }

  static currentUserIsMinor(state) {
    return UserHelper.currentUser(state).isMinor;
  }

  static currentPatientIsMinor(state) {
    return UserHelper.currentPatient(state)?.isMinor;
  }

  static currentPatientGuardianEmail(state) {
    return UserHelper.currentPatient(state)?.guardianEmail;
  }

  static getGuardianEmail(state) {
    return UserHelper.currentUser(state).guardianEmail;
  }

  static lastSyncTimestampByType(state, egv = false) {
    if (egv) {
      return UserHelper.lastCgmSyncTimestamp(state);
    }
    return UserHelper.lastBgSyncTimestamp(state);
  }

  static lastBgSyncTimestamp(state) {
    const { pump, meter } = UserHelper.lastSyncTimestampStore(state);
    if (pump && meter) {
      return pump > meter ? pump : meter;
    }
    return pump || meter;
  }

  static lastCgmSyncTimestamp(state) {
    return UserHelper.lastSyncTimestampStore(state).cgmDevice;
  }

  static syncedExerciseApps(state) {
    return UserHelper.displayUser(state).syncedExerciseApps;
  }

  static hasPump(state) {
    return !!UserHelper.lastSyncTimestampStore(state).pump;
  }

  static hasMeter(state) {
    return !!UserHelper.lastSyncTimestampStore(state).meter;
  }

  static hasCgmDevice(state) {
    return !!UserHelper.lastSyncTimestampStore(state).cgmDevice;
  }

  static hasPenDevice(state) {
    return !!UserHelper.lastSyncTimestampStore(state).insulinPen;
  }

  static hasBG(state) {
    return !!UserHelper.hasPump(state) || !!UserHelper.hasMeter(state);
  }

  static hasManualData(state) {
    return UserHelper.displayUser(state).insulinData;
  }

  static userPreference(state) {
    return UserHelper.displayUser(state).preference;
  }

  static userPendingConsents(state) {
    return UserHelper.currentUser(state).pendingConsents;
  }

  static setGraphSelectionsLocally(options) {
    if (!localStorage) return {};
    const oldOptions = UserHelper.getLocalGraphSelections();
    const newOptions = omitBy(options, isNil);
    const mergedOptions = merge(oldOptions, newOptions);
    localStorage.setItem('graph_selections', JSON.stringify(mergedOptions));
    return mergedOptions;
  }

  static getLocalGraphSelections() {
    if (!localStorage) return {};
    const storedValues = JSON.parse(localStorage.getItem('graph_selections') ||
      '{}');
    return omitBy(storedValues, isNil);
  }

  static removeLocalGraphSelections() {
    if (!localStorage) return;
    localStorage.removeItem('graph_selections');
  }

  static retrieveLabel(key) {
    switch (key) {
      case 'gestational':
        return t('gestational');
      case 'lada':
        return t('lada');
      case 'not_specified':
        return t('not_specified');
      case 'none':
        return t('none');
      case 'other':
        return t('other');
      case 'pre_diabetes':
        return t('pre_diabetes');
      case 'type_1':
        return t('type_1');
      case 'type_2':
        return t('type_2');
      case 'bg':
        return t('bg');
      case 'bg_manual':
        return t('bg_manual');
      case 'cgm':
        return t('cgm');
      case 'and':
        return t('and');
      case 'en':
        return t('en');
      case 'de':
        return t('de');
      case 'fr':
        return t('fr');
      case 'fr-CA':
        return t('fr-CA');
      case 'el':
        return t('el');
      case 'es':
        return t('es');
      case 'es-US':
        return t('es-US');
      case 'tr':
        return t('tr');
      case 'hr':
        return t('hr');
      case 'en-GB':
        return t('en-GB');
      case 'da':
        return t('da');
      case 'cs':
        return t('cs');
      case 'fi':
        return t('fi');
      case 'it':
        return t('it');
      case 'nb-NO':
        return t('nb-NO');
      case 'nl':
        return t('nl');
      case 'pl':
        return t('pl');
      case 'sk':
        return t('sk');
      case 'sv':
        return t('sv');
      case 'pt-PT':
        return t('pt-PT');
      case 'ar':
        return t('ar');
      default:
        // eslint-disable-next-line no-process-env
        if (process.env.DEBUG === 'true') {
          throw new Error('UserHelper.retrieveLabel', key);
        }
        return null;
    }
  }

  static hasClosedLoopDevice(state) {
    return UserHelper.displayUser(state).hasClosedLoopDevice;
  }
}

export default UserHelper;
window.UserHelpersetGraphSelectionsLocally = UserHelper.setGraphSelectionsLocally;
