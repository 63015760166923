import moment from 'moment';
import disallowUndefinedProperties from '~/utils/disallowUndefined';
import Readings from '~/services/Readings';
import Images from '~/utils/ImageStore';
import { numberFormatterOneDecimal, numberFormatterThreeDecimals, roundToKilosOneDecimal } from '~/utils/i18nFormats';
import CarbsGraph from '../../../graphs/components/Graphs/CarbsGraph/CarbsGraph.scss';
import PumpModesGraph from '../../../graphs/components/Graphs/PumpModesGraph/PumpModesGraph.scss';
import LabelStyle from './labels.scss';

class Labels {
  static bolus() {
    // Don't render label for the first extended bolus step
    if (
      this.point.series.name === 'extendedBolusStep12' &&
      moment(this.point.timestamp).unix() === this.x
    ) return false;

    return this.y > 0 ? numberFormatterOneDecimal(this.y) : false;
  }

  static insulinColumn() {
    const textShadow = this.y / this.series.chart.yAxis[0].max < 0.15 ? '0.5' : '0';
    const percentage =
      this.point.customTotal ?
        (this.y / this.point.customTotal) * 100 :
        this.percentage;

    return `<div style="text-shadow: -${textShadow}px 0 black, 0 ${textShadow}px black, ${textShadow}px 0 black, 0 -${textShadow}px black;">${Math.round(percentage)}%</div>`;
  }

  static exerciseInline() {
    const y = this.point.yOrig;
    return y > 9999 ? roundToKilosOneDecimal(y) : numberFormatterOneDecimal(y);
  }

  static carbAllDot() {
    return numberFormatterOneDecimal(this.point.yOrig);
  }

  static carbAll() {
    return `<div class="${CarbsGraph.carbAllPdfDot}">${numberFormatterOneDecimal(this.point.yOrig)}</div>`;
  }

  static carbAll12() {
    const suffix = this.point.series.chart.userOptions.reactProps.weekView ? 'WeekView' : '';
    const multi = Array.isArray(this.point.children) && this.point.children.length > 1;
    const style = multi ? CarbsGraph[`carbAll12Multiple${suffix}`] : CarbsGraph[`carbAll12${suffix}`];

    return `<div class="${style}">${numberFormatterOneDecimal(this.point.yOrig)}</div>`;
  }

  static scatter() {
    return Readings.displayWithHiLo(
      this.point.value,
      this.series.chart.userOptions.reactProps.meterUnits,
    );
  }

  static numberFormatterOneDecimal() {
    return numberFormatterOneDecimal(this.point.yOrig);
  }

  static overviewInlineDot() {
    return `<div class="${LabelStyle.overviewInlineDot}">${numberFormatterOneDecimal(this.point.yOrig)}</div>`;
  }

  static pumpAutomaticMode() {
    if (this.point.interpolated) return null;

    return `<img class="${PumpModesGraph.AutomaticModeIcon}" src="${Images.pumpAutomaticMode}"></img>`;
  }

  static pumpSleepMode() {
    if (this.point.interpolated) return null;

    return `<img class="${PumpModesGraph.SleepModeIcon}" src="${Images.pumpSleepMode}"></img>`;
  }

  static pumpExerciseMode() {
    if (this.point.interpolated) return null;

    return `<img class="${PumpModesGraph.ExerciseModeIcon}" src="${Images.pumpExerciseMode}"></img>`;
  }

  static pumpManualMode() {
    if (this.point.interpolated) return null;

    return `<img class="${PumpModesGraph.ManualModeIcon}" src="${Images.pumpManualMode}"></img>`;
  }

  static pumpOp5HypoProtectMode() {
    if (this.point.interpolated) return null;

    return `<img class="${PumpModesGraph.op5HypoProtectModeIcon}" src="${Images.pumpOp5HypoProtectMode}"></img>`;
  }

  static pumpOp5LimitedMode() {
    if (this.point.interpolated) return null;

    return `<img class="${PumpModesGraph.op5LimitedModeIcon}" src="${Images.pumpOp5LimitedMode}"></img>`;
  }

  static pumpCamapsBoostMode() {
    if (this.point.interpolated) return null;

    return `<img style="width: 12px" src="${Images.camapsBoost}" />`;
  }

  static pumpCamapsEaseOffMode() {
    if (this.point.interpolated) return null;

    return `<img style="width: 12px" src="${Images.camapsEaseOff}" />`;
  }

  static bgTarget() {
    if (!this.point.label) return null;

    const meterUnits =
      Readings.displayMeterUnits(this.series.chart.userOptions.reactProps.meterUnits);

    return `${this.point.y} ${meterUnits}`;
  }

  static basalLabels() {
    if (!this.point.label) return null;

    return `${numberFormatterThreeDecimals(this.point.rate)}`;
  }
}

export default disallowUndefinedProperties(Labels);
