export const buildFilterParams = (filters) => {
  const updatedFilters = {
    tags: filters.tags.filter((filter) => filter.checked === true).map((value) => value.label),
    customTags: filters.customTags.filter((filter) => filter.checked === true).map(
      (value) => value.label,
    ),
    patientStatuses: filters.patientStatuses ? filters.patientStatuses.filter(
      (filter) => filter.checked === true).map(
      (value) => value.id.split('-')[1],
    ) : [],
    midsTags: filters.midsTags.filter((filter) => filter.checked === true).map(
      (value) => value.label,
    ),
    careProgramTags: filters.careProgramTags.filter((filter) => filter.checked === true).map(
      (value) => value.label,
    ),
    flags: filters.flags.filter((filter) => filter.checked === true).map(
      (value) => value.originalId,
    ),
  };
  return updatedFilters;
};
