import classNames from 'classnames';
import { range } from 'lodash';
import { TYPE_BG } from 'bundles/shared/constants/readings';
import { FETCH_STATUS_SUCCESSFUL, FETCH_STATUS_FAILED } from 'bundles/shared/constants/graphs';
import Readings from 'services/Readings';
import { SECONDS_IN_DAY } from 'bundles/shared/constants/time';
import { useGraphAxis } from 'bundles/graphs/hooks/useGraphAxis';
import { translate } from 'bundles/shared/components/WithTranslate/WithTranslate';
import WithLoadingState from 'bundles/shared/components/WithLoadingState/WithLoadingState';
import Spinner from 'bundles/shared/components/Spinner/Spinner';
import OverviewGraphRow from '../OverviewGraphRow/OverviewGraphRow';
import BGGraph from '../../Graphs/BGGraph/BGGraph';
import Colors from '../../../../../../assets/styles//export/colors';
import CarbsGraph from '../../Graphs/CarbsGraph/CarbsGraph';
import InsulinInlineGraph from '../../Graphs/InsulinInlineGraph/InsulinInlineGraph';
import Style from './OverviewGraphPresenter.scss';
import OverviewGraphExerciseRow from './OverviewGraphExerciseRow/OverviewGraphExerciseRow';
import OverviewGraphInsulinRow from './OverviewGraphInsulinRow/OverviewGraphInsulinRow';

export interface OverviewGraphPresenterProps extends WithTranslationFunction {
  startTimestamp: number;
  endTimestamp: number;
  meterUnits: MeterUnits;
  graphsWindowWidth: number;
  axisWidth: number;
  bgGraphSeries: GraphSeries[];
  cgmGraphSeries: GraphSeries[];
  carbsSeries: GraphSeries[];
  insulinPumpSeries: GraphSeries[];
  insulinManualSeries: GraphSeries[];
  insulinInlinePumpSeries: GraphSeries[];
  insulinInlineManualSeries: GraphSeries[];
  exerciseSeries: GraphSeries[];
  stepsSeries: GraphSeries[];
  fetchStatus: FetchStatus;
  fetchStatusExercise: FetchStatus;
  hasCarbs: boolean;
  hasExercise: boolean;
  hasSteps: boolean;
  hasInsulinManual: boolean;
  hasInsulinPump: boolean;
  readingsType: ReadingType;
  oneDayWidth: number;
  inPdf: boolean;
}

const OverviewGraphPresenter = (props: OverviewGraphPresenterProps) => {
  const {
    carbsSeries,
    insulinPumpSeries,
    insulinManualSeries,
    exerciseSeries,
    stepsSeries,
    insulinInlinePumpSeries,
    insulinInlineManualSeries,
    bgGraphSeries: propsBgGraphSeries,
    cgmGraphSeries,
    readingsType,
    meterUnits,
    hasCarbs,
    hasExercise,
    hasSteps,
    hasInsulinPump,
    hasInsulinManual,
    inPdf,
    t,
    startTimestamp,
    endTimestamp,
    axisWidth,
    oneDayWidth,
    graphsWindowWidth,
    fetchStatusExercise,
    fetchStatus,
  } = props;
  const { carbsAxisMax, exerciseAxisMax, stepsAxisMax, insulinAxisMax, bgAxisMax } = useGraphAxis();

  const days = Math.round((endTimestamp - startTimestamp) / SECONDS_IN_DAY);
  const step = days > 30 && !inPdf ? 7 : 1;
  const firstStepOffset = days > 30 && !inPdf ? days % 7 : 0;
  const configOverride = {
    xAxis: {
      plotLines: range(
        (Math.floor(startTimestamp / SECONDS_IN_DAY) * SECONDS_IN_DAY) +
        (firstStepOffset * SECONDS_IN_DAY),
        (Math.ceil(endTimestamp / SECONDS_IN_DAY) * SECONDS_IN_DAY) - 0.1,
        SECONDS_IN_DAY * step,
      ).map((elem) => ({
        color: Colors.borderGrey,
        width: 1,
        dashStyle: 'solid',
        value: elem,
        zIndex: 2,
      })),
    },
  };
  const bgGraphPdf = [...propsBgGraphSeries, ...cgmGraphSeries];
  const bgGraphWeb = readingsType === TYPE_BG ? propsBgGraphSeries : cgmGraphSeries;
  const bgGraphSeries = inPdf ? bgGraphPdf : bgGraphWeb;

  const carbsAxisEndIndex = carbsAxisMax(carbsSeries);
  const exerciseAxisEndIndex = exerciseAxisMax(exerciseSeries);
  const stepsAxisEndIndex = stepsAxisMax(stepsSeries);
  const insulinManualAxisEndIndex = insulinAxisMax(insulinManualSeries);
  const insulinPumpAxisEndIndex = insulinAxisMax(insulinPumpSeries);
  const bgGraphAxisEndIndex = bgAxisMax(meterUnits);

  return (
    <div className={Style.OverviewGraphPresenter}>
      <OverviewGraphRow
        axisEndIndex={bgGraphAxisEndIndex}
        axisStartIndex={0}
        axisSubtitle={`(${Readings.displayMeterUnits(meterUnits)})`}
        axisTickStep={meterUnits === Readings.MMOLL ? 6 : 100}
        axisTitle={t('glucoseTitle')}
        axisWidth={axisWidth}
        endTimestamp={endTimestamp}
        inPdf={inPdf}
        oneDayWidth={oneDayWidth}
        startTimestamp={startTimestamp}
        windowHeight={inPdf ? 300 : 250}
      >
        <div className={classNames(Style.borderTop, Style.borderBottom, { [Style.borderRight]: inPdf })}>
          <BGGraph
            configOverride={configOverride}
            endTimestamp={endTimestamp}
            fetchStatus={fetchStatus}
            series={bgGraphSeries}
            startTimestamp={startTimestamp}
            windowHeight={inPdf ? 300 : 250}
            windowWidth={graphsWindowWidth}
            yAxisMax={bgGraphAxisEndIndex}
          />
        </div>
      </OverviewGraphRow>

      {hasCarbs ? (
        <OverviewGraphRow
          axisEndIndex={inPdf ? 100 : carbsAxisEndIndex}
          axisHide={inPdf}
          axisStartIndex={0}
          axisSubtitle={`(${t('grams')})`}
          axisTickStep={Math.floor(carbsAxisEndIndex / 2)}
          axisTitle={t('carbsTitle')}
          axisWidth={axisWidth}
          endTimestamp={endTimestamp}
          inPdf={inPdf}
          oneDayWidth={oneDayWidth}
          startTimestamp={startTimestamp}
          windowHeight={inPdf ? 48 : 72}
        >
          <div className={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
            <CarbsGraph
              configOverride={configOverride}
              endTimestamp={endTimestamp}
              fetchStatus={fetchStatus}
              series={carbsSeries}
              startTimestamp={startTimestamp}
              windowHeight={inPdf ? 48 : 72}
              windowWidth={graphsWindowWidth}
              yAxisMax={inPdf ? 100 : carbsAxisEndIndex}
            />
          </div>
        </OverviewGraphRow>
      ) : null}

      {hasInsulinPump && inPdf ? (
        <OverviewGraphRow
          axisEndIndex={0}
          axisHide
          axisStartIndex={0}
          axisSubtitle={`(${t('units')})`}
          axisTickStep={0}
          axisTitle={hasInsulinManual ? t('insulinDeviceTotal') : t('insulinTotal')}
          axisWidth={axisWidth}
          endTimestamp={endTimestamp}
          inPdf={inPdf}
          oneDayWidth={oneDayWidth}
          startTimestamp={startTimestamp}
          windowHeight={48}
        >
          <div className={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
            <InsulinInlineGraph
              configOverride={configOverride}
              endTimestamp={endTimestamp}
              fetchStatus={fetchStatus}
              series={insulinInlinePumpSeries}
              startTimestamp={startTimestamp}
              windowHeight={48}
              windowWidth={graphsWindowWidth}
              yAxisMax={100}
            />
          </div>
        </OverviewGraphRow>
      ) : null}

      {hasInsulinPump ? (
        <OverviewGraphInsulinRow
          axisEndIndex={insulinPumpAxisEndIndex}
          axisSubtitle={`(${t('units')})`}
          axisTickStep={Math.floor(insulinPumpAxisEndIndex / 2)}
          axisTitle={hasInsulinManual ? t('insulinDevice') : t('insulin')}
          axisWidth={axisWidth}
          configOverride={configOverride}
          endTimestamp={endTimestamp}
          fetchStatus={fetchStatus}
          inPdf={inPdf}
          oneDayWidth={oneDayWidth}
          series={insulinPumpSeries}
          startTimestamp={startTimestamp}
          windowHeight={inPdf ? 100 : 72}
          windowWidth={graphsWindowWidth}
          yAxisMax={insulinPumpAxisEndIndex}
        />
      ) : null}

      {hasInsulinManual && inPdf ? (
        <OverviewGraphRow
          axisEndIndex={0}
          axisHide
          axisStartIndex={0}
          axisSubtitle={`(${t('units')})`}
          axisTickStep={0}
          axisTitle={hasInsulinPump ? t('insulinManualTotal') : t('insulinTotal')}
          axisWidth={axisWidth}
          endTimestamp={endTimestamp}
          inPdf={inPdf}
          oneDayWidth={oneDayWidth}
          startTimestamp={startTimestamp}
          windowHeight={48}
        >
          <div className={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
            <InsulinInlineGraph
              configOverride={configOverride}
              endTimestamp={endTimestamp}
              fetchStatus={fetchStatus}
              series={insulinInlineManualSeries}
              startTimestamp={startTimestamp}
              windowHeight={48}
              windowWidth={graphsWindowWidth}
              yAxisMax={100}
            />
          </div>
        </OverviewGraphRow>
      ) : null}

      {hasInsulinManual ? (
        <OverviewGraphInsulinRow
          axisEndIndex={insulinManualAxisEndIndex}
          axisSubtitle={`(${t('units')})`}
          axisTickStep={Math.floor(insulinManualAxisEndIndex / 2)}
          axisTitle={hasInsulinPump ? t('insulinManual') : t('insulin')}
          axisWidth={axisWidth}
          configOverride={configOverride}
          endTimestamp={endTimestamp}
          fetchStatus={fetchStatus}
          inPdf={inPdf}
          oneDayWidth={oneDayWidth}
          series={insulinManualSeries}
          startTimestamp={startTimestamp}
          windowHeight={inPdf ? 100 : 72}
          windowWidth={graphsWindowWidth}
          yAxisMax={insulinManualAxisEndIndex}
        />
      ) : null}

      {!hasInsulinPump && !hasInsulinManual && !inPdf && (
        <OverviewGraphInsulinRow
          axisEndIndex={10}
          axisSubtitle={`(${t('units')})`}
          axisTickStep={5}
          axisTitle={t('insulin')}
          axisWidth={axisWidth}
          configOverride={configOverride}
          endTimestamp={endTimestamp}
          fetchStatus={fetchStatus}
          inPdf={inPdf}
          oneDayWidth={oneDayWidth}
          series={[]}
          startTimestamp={startTimestamp}
          windowHeight={72}
          windowWidth={graphsWindowWidth}
          yAxisMax={10}
        />
      )}

      <OverviewGraphExerciseRow
        axisEndIndex={stepsAxisEndIndex}
        axisSubtitle={t('stepsSubtitle')}
        axisTitle={t('stepsTitle')}
        axisWidth={axisWidth}
        configOverride={configOverride}
        endTimestamp={endTimestamp}
        fetchStatus={fetchStatusExercise}
        graphsWindowWidth={graphsWindowWidth}
        inPdf={inPdf}
        oneDayWidth={oneDayWidth}
        series={stepsSeries}
        show={hasSteps}
        startTimestamp={startTimestamp}
      />

      <OverviewGraphExerciseRow
        axisEndIndex={exerciseAxisEndIndex}
        axisSubtitle={t('exerciseSubtitle')}
        axisTitle={t('exerciseTitle')}
        axisWidth={axisWidth}
        configOverride={configOverride}
        endTimestamp={endTimestamp}
        fetchStatus={fetchStatusExercise}
        graphsWindowWidth={graphsWindowWidth}
        inPdf={inPdf}
        oneDayWidth={oneDayWidth}
        series={exerciseSeries}
        show={hasExercise}
        startTimestamp={startTimestamp}
      />
    </div>
  );
};

export const TranslatedOverviewGraphPresenter = translate('OverviewGraphPresenter')(OverviewGraphPresenter);
export default WithLoadingState(
  TranslatedOverviewGraphPresenter,
  (props: OverviewGraphPresenterProps) => (
    !(
      (props.fetchStatus === FETCH_STATUS_SUCCESSFUL ||
        props.fetchStatus === FETCH_STATUS_FAILED) &&
      (props.fetchStatusExercise === FETCH_STATUS_SUCCESSFUL ||
        props.fetchStatusExercise === FETCH_STATUS_FAILED)
    )
  ),
  {
    emptyComponent: () => (
      <div style={{
        left: 122,
        width: 'calc(100% - 122px)',
        height: '100%',
        position: 'absolute',
      }}
      >
        <Spinner />
      </div>
    ),
  },
);
