import { useState } from 'react';
import { connect } from 'react-redux';
import Readings from 'services/Readings';
import images from 'utils/ImageStore';
import { numberFormatterOneDecimal } from 'utils/i18nFormats';
import UserHelper from 'redux/modules/users/UserHelper';
import Spinner from 'bundles/shared/components/Spinner/Spinner';
import WithTranslate from '../../WithTranslate/WithTranslate';
import SystemModeLegend from './SystemModeLegend';
import GraphsLegendPresenter from './GraphsLegendPresenter';
import Group from './GraphsLegendGroup';
import Item from './GraphsLegendItem';

interface LegendConfig {
  controlIqSystemModes: ControlIqSystemModes;
  basalIqSystemModes: GenericSystemModes;
  camApsSystemModes: CamApsSystemModes;
  genericSystemModes: GenericSystemModes;
  op5SystemModes: Op5SystemModes;
  glucose: Reading;
  readingTypes: ReadingTypes;
  insulinDose: InsulinDose;
  basal: Basal;
  basalOp5: BasalOp5;
  insulin: Insulin;
}

export interface OverviewHoursLegendProps extends WithTranslationFunction {
  afterMealMax: number;
  beforeMealMax: number;
  normalMin: number;
  meterUnits: string;
  displayMeterUnits: string;
  isConfigured: boolean;
  legendConfig: {
    controlIqSystemModes: ControlIqSystemModes;
    basalIqSystemModes: GenericSystemModes;
    camApsSystemModes: CamApsSystemModes;
    genericSystemModes: GenericSystemModes;
    op5SystemModes: Op5SystemModes;
    glucose: Reading;
    readingTypes: ReadingTypes;
    insulinDose: InsulinDose;
    basal: Basal,
    basalOp5: BasalOp5,
    insulin: Insulin,
  };
  inPdf: boolean;
  isWeekView?: boolean;
  isWeekViewWeb?: boolean;
}

export interface OverviewHoursLegendState {
  pdf: { inPDF: boolean }
  graphs: {
    overviewGraphHoursLegendConfig: {
      data: LegendConfig;
      configured: boolean;
    }
  }
}

const mapStateToProps = (state: OverviewHoursLegendState, ownProps: OverviewHoursLegendProps) => {
  const user = UserHelper.displayUser(state);
  const { beforeMealNormalGlucoseMax, afterMealNormalGlucoseMax, normalGlucoseMin, meterUnits } = user.preference;

  return {
    beforeMealMax: Readings.displayValue(
      Readings.denormalizedValue(beforeMealNormalGlucoseMax),
      meterUnits,
    ),
    afterMealMax: Readings.displayValue(
      Readings.denormalizedValue(afterMealNormalGlucoseMax),
      meterUnits,
    ),
    normalMin: Readings.displayValue(
      Readings.denormalizedValue(normalGlucoseMin),
      meterUnits,
    ),
    meterUnits,
    inPdf: state.pdf.inPDF,
    displayMeterUnits: Readings.displayMeterUnits(meterUnits),
    isConfigured: ownProps.isConfigured || state.graphs.overviewGraphHoursLegendConfig.configured,
    legendConfig: ownProps.legendConfig || state.graphs.overviewGraphHoursLegendConfig.data,
  };
};

const OverviewHoursLegend = (props: OverviewHoursLegendProps) => {
  const { isWeekView, isWeekViewWeb, meterUnits, t, isConfigured, legendConfig, inPdf } = props;
  const [expanded, setExpanded] = useState(true);
  const onButtonClick = () => setExpanded(!expanded);
  const linkLabel = () => (expanded ? t('showLess') : t('showAll'));

  const loader = () => (
    <Spinner
      style={{
        backgroundSize: '50px',
        width: '50px',
        height: '50px',
        margin: '35px auto',
      }}
    />
  );

  const legend = () => {
    const { normalMin, beforeMealMax, afterMealMax, displayMeterUnits } = props;
    const above400Value = numberFormatterOneDecimal(Readings.displayValue(400, meterUnits));
    const {
      controlIqSystemModes,
      basalIqSystemModes,
      camApsSystemModes,
      genericSystemModes,
      op5SystemModes,
      glucose,
      readingTypes,
      insulinDose,
      basal,
      basalOp5,
      insulin,
    } = legendConfig;

    const controlIqAutoModes = [];
    if (controlIqSystemModes.display) {
      if (controlIqSystemModes.exercise) {
        controlIqAutoModes.push({ name: t('pumpModeExercise'), imgSrc: images.pumpExerciseLegend, descr: t('pumpModeExerciseDescr'), testId: 'exercise' });
      }
      if (controlIqSystemModes.sleep) {
        controlIqAutoModes.push({ name: t('pumpModeSleep'), imgSrc: images.pumpSleepLegend, descr: t('pumpModeSleepDescr'), testId: 'sleep' });
      }
    }
    const op5Modes = [];
    if (op5SystemModes.display) {
      if (op5SystemModes.activity) {
        op5Modes.push({ name: t('pumpModeOp5Activity'), imgSrc: images.pumpHypoProtectLegend, descr: t('pumpModeOp5ActivityDescr'), testId: 'activity' });
      }
      if (op5SystemModes.limited) {
        op5Modes.push({ name: t('pumpModeOp5Limited'), imgSrc: images.pumpLimitedLegend, descr: t('pumpModeOp5LimitedDescr'), testId: 'limited' });
      }
    }
    const camApsAutoModes = [];
    if (camApsSystemModes.display) {
      if (camApsSystemModes.boost) {
        camApsAutoModes.push({ name: t('camapsBoost'), imgSrc: images.camapsBoostLegend, descr: t('camapsBoostDesc'), testId: 'boost' });
      }
      if (camApsSystemModes.easeOff) {
        camApsAutoModes.push({ name: t('camapsEaseOff'), imgSrc: images.camapsEaseOffLegend, descr: t('camapsEaseOffDesc'), testId: 'easeOff' });
      }
      if (camApsSystemModes.attempting) {
        camApsAutoModes.push({ name: t('camapsAutoAttempting'), imgSrc: images.camapsAutoAttemptingLegend, descr: t('camapsAutoAttemptingDesc'), testId: 'attempting' });
      }
    }

    return (
      <>
        {controlIqSystemModes.display ? (
          <SystemModeLegend
            autoDescr={t('pumpModeControlIQAutoDescr')}
            autoLabel="Control-IQ"
            autoModes={controlIqAutoModes}
            disableTooltip={inPdf}
            displayAuto={controlIqSystemModes.auto}
            displayManual={controlIqSystemModes.manual}
            manualDescr={t('pumpModeControlIQManualDescr')}
            manualLabel={t('pumpModeManual')}
            systemLabel="Tandem t:slim X2"
            testId="controlIqSystemModes"
            titleVisible={expanded}
          />
        ) : null}

        {basalIqSystemModes.display ? (
          <SystemModeLegend
            autoDescr={t('pumpModeBasalIQAutoDescr')}
            autoLabel="Basal-IQ"
            disableTooltip={inPdf}
            displayAuto={basalIqSystemModes.auto}
            displayManual={basalIqSystemModes.manual}
            manualDescr={t('pumpModeBasalIQManualDescr')}
            manualLabel={t('pumpModeManual')}
            systemLabel="Tandem t:slim X2"
            testId="basalIqSystemModes"
            titleVisible={expanded}
          />
        ) : null}

        {genericSystemModes.display ? (
          <SystemModeLegend
            autoLabel={t('pumpModeGenericAuto')}
            disableTooltip={inPdf}
            displayAuto={genericSystemModes.auto}
            displayManual={genericSystemModes.manual}
            manualLabel={t('pumpModeManual')}
            systemLabel="Medtronic 670G"
            testId="genericSystemModes"
            titleVisible={expanded}
          />
        ) : null}

        {op5SystemModes.display ? (
          <SystemModeLegend
            autoDescr={t('pumpModeOp5AutoDescr')}
            autoLabel={t('pumpModeOp5Auto')}
            autoModes={op5Modes}
            disableTooltip={inPdf}
            displayAuto={op5SystemModes.auto}
            displayManual={op5SystemModes.manual}
            manualDescr={t('pumpModeOp5ManualDescr')}
            manualLabel={t('pumpModeManual')}
            systemLabel="Omnipod® 5"
            testId="op5SystemModes"
            titleVisible={expanded}
          />
        ) : null}

        {camApsSystemModes.display ? (
          <SystemModeLegend
            autoDescr={t('camapsAutoOnDesc')}
            autoLabel={t('camapsAutoOn')}
            autoModes={camApsAutoModes}
            disableTooltip={isWeekView || inPdf}
            displayAuto={camApsSystemModes.auto}
            displayManual={camApsSystemModes.manual}
            manualDescr={t('camapsAutoOffDesc')}
            manualLabel={t('camapsAutoOff')}
            systemLabel="CamAPS FX"
            testId="camApsSystemModes"
            titleVisible={expanded}
          />
        ) : null}

        <Group
          title={t('glucose')}
          titleVisible={glucose.display ? expanded : false}
          visible={glucose.display}
        >
          <Item
            label={t('beforeMeal', {
              normalMin: numberFormatterOneDecimal(normalMin),
              beforeMealMax: numberFormatterOneDecimal(beforeMealMax),
              meterUnits: displayMeterUnits,
            })}
            src={images.GlucoseBeforeMeal}
            visible
          />

          <Item
            label={t('afterMeal', {
              normalMin: numberFormatterOneDecimal(normalMin),
              afterMealMax: numberFormatterOneDecimal(afterMealMax),
              meterUnits: displayMeterUnits,
            })}
            src={images.GlucoseAfterMeal}
            visible
          />

          <Item
            label={t('aboveRange')}
            src={images.GlucoseAboveRange}
            testId="glucose.above"
            visible={glucose.above ? expanded : null}
          />

          <Item
            label={t('inTargetRange')}
            src={images.GlucoseInTargetRange}
            testId="glucose.normal"
            visible={glucose.normal ? expanded : null}
          />

          <Item
            label={t('belowRange')}
            src={images.GlucoseBelowRange}
            testId="glucose.low"
            visible={glucose.low ? expanded : null}
          />
        </Group>

        <Group
          title={t('typesOfReadings')}
          titleVisible={readingTypes.display ? expanded : false}
          visible={readingTypes.display}
        >
          <Item
            label={t('aboveRange')}
            src={images.GlucoseAboveRange}
            testId="readingTypes.above"
            visible={readingTypes.above ? !expanded : null}
          />

          <Item
            label={t('inTargetRange')}
            src={images.GlucoseInTargetRange}
            testId="readingTypes.normal"
            visible={readingTypes.normal ? !expanded : null}
          />

          <Item
            label={t('belowRange')}
            src={images.GlucoseBelowRange}
            testId="readingTypes.low"
            visible={readingTypes.low ? !expanded : null}
          />

          <Item
            label={t('bgReadings')}
            src={images.GlucoseBgReadings}
            testId="readingTypes.bg"
            visible={readingTypes.bg ? expanded : null}
          />

          <Item
            label={t('pumpBg')}
            src={images.GlucosePumpBg}
            testId="readingTypes.pumpBg"
            visible={readingTypes.pumpBg ? expanded : null}
          />

          <Item
            label={t('calibrations')}
            src={images.GlucoseCalibrations}
            testId="readingTypes.calibrations"
            visible={readingTypes.calibrations ? expanded : null}
          />

          <Item
            label={t('cgmReadings')}
            src={images.GlucoseCgmReadings}
            testId="readingTypes.cgm"
            visible={readingTypes.cgm ? expanded : null}
          />

          <Item
            label={t('cgmEstimated')}
            src={images.GlucoseCgmCalculatedDots}
            testId="readingTypes.calculatedCgm"
            visible={readingTypes.calculatedCgm ? expanded : null}
          />

          <Item
            label={t('pumpAbove', { amount: above400Value })}
            src={images.GlucoseAbove400Pump}
            testId="readingTypes.above400Manual"
            visible={readingTypes.above400Manual ? expanded : null}
          />

          <Item
            label={t('above', { amount: above400Value })}
            src={images.GlucoseAbove400}
            testId="readingTypes.above400"
            visible={readingTypes.above400 ? expanded : null}
          />

          <Item
            assetStyle={{ width: 11 }}
            label={t('carbs')}
            src={images.OtherCarbs}
            testId="readingTypes.carbs"
            visible={readingTypes.carbs ? expanded : null}
          />

          <Item
            assetStyle={{ width: 11 }}
            label={t('carbsMultiple')}
            src={images.CarbsMultiple}
            testId="readingTypes.carbsMultiple"
            visible={readingTypes.carbsMultiple ? expanded : null}
          />

          <Item
            label={t('exercise')}
            src={images.Exercise}
            testId="readingTypes.exercise"
            visible={readingTypes.exercise && expanded ? !isWeekView : null}
          />
        </Group>

        <Group
          title={t('insulinDose')}
          titleVisible={insulinDose.display ? expanded : false}
          visible={insulinDose.display ? expanded : false}
        >
          <Item
            label={t('bolusDelivered')}
            src={images.InsulinBolus}
            testId="insulinDose.deliveredBolus"
            visible={insulinDose.deliveredBolus ? expanded : null}
          />

          <Item
            label={t('bolusUndelivered')}
            src={images.InsulinBolusSuggested}
            testId="insulinDose.undeliveredBolus"
            visible={insulinDose.undeliveredBolus ? expanded : null}
          />

          <Item
            label={t('automaticBolus')}
            src={images.InsulinAutomaticBolus}
            testId="insulinDose.automaticBolus"
            visible={insulinDose.automaticBolus ? expanded : null}
          />

          <Item
            label={t('extendedBolus')}
            src={images.InsulinExtBolus}
            testId="insulinDose.extendedBolus"
            visible={insulinDose.extendedBolus ? expanded : null}
          />

          <Item
            label={t('basal')}
            src={images.InsulinBasal}
            testId="insulinDose.basal"
            visible={insulinDose.basal ? expanded : null}
          />

          <Item
            label={t('premixed')}
            src={images.InsulinPremixed}
            testId="insulinDose.premixed"
            visible={insulinDose.premixed ? expanded : null}
          />

          <Item
            label={t('custom')}
            src={images.InsulinCustom}
            testId="insulinDose.custom"
            visible={insulinDose.custom ? expanded : null}
          />

          <Item
            label={t('suspended')}
            src={images.InsulinSuspended}
            testId="insulinDose.suspended"
            visible={insulinDose.suspended ? expanded : null}
          />

          <Item
            label={t('override')}
            src={images.InsulinOverride}
            testId="insulinDose.overrideBolus"
            visible={insulinDose.overrideBolus ? expanded : null}
          />

          <Item
            label={t('calculatedBolus')}
            src={images.InsulinCalculatedBolus}
            testId="insulinDose.calculatedBolus"
            visible={(insulinDose.calculatedBolus && !inPdf) ? expanded : null}
          />
        </Group>

        <Group
          title={t('basal')}
          titleVisible={basal.display ? expanded : false}
          visible={basal.display ? expanded : false}
        >
          <Item
            label={t('scheduled')}
            src={images.InsulinScheduledBasal}
            testId="basal.scheduledBasal"
            visible={basal.scheduledBasal ? expanded : null}
          />

          <Item
            label={t('unusedScheduledBasal')}
            src={images.InsulinUnusedScheduledBasal}
            testId="basal.unusedScheduledBasal"
            visible={basal.unusedScheduledBasal ? expanded : null}
          />

          <Item
            label={t('basalModulation')}
            src={images.InsulinBasalModulation}
            testId="basal.basalModulation"
            visible={basal.basalModulation ? expanded : null}
          />

          <Item
            label={t('tempBasal')}
            src={images.InsulinTempBasal}
            testId="basal.tempBasalRate"
            visible={basal.tempBasalRate ? expanded : null}
          />

          <Item
            label={t('suspend')}
            src={images.InsulinSuspend}
            testId="basal.suspend"
            visible={basal.suspend ? expanded : null}
          />

          <Item
            label={t('lgsPlgs')}
            src={images.LgsPlgs}
            testId="basal.lgsPlgs"
            visible={basal.lgsPlgs ? expanded : null}
          />

          <Item
            assetStyle={{ width: 12 }}
            label={t('setSite')}
            src={images.SetChange}
            testId="basal.setSiteChange"
            visible={basal.setSiteChange ? expanded : null}
          />

          <Item
            assetStyle={{ width: 12 }}
            label={t('reservoir')}
            src={images.canulaRefil}
            testId="basal.reservoirChange"
            visible={basal.reservoirChange ? expanded : null}
          />

          <Item
            assetStyle={{ width: 12 }}
            label={t('pumpAlarm')}
            src={images.pumpAlarm}
            testId="basal.pumpAlarm"
            visible={basal.pumpAlarm ? expanded : null}
          />

          <Item
            assetStyle={{ width: 12 }}
            label={t('advisoryAlert')}
            src={images.pumpAdvisoryAlert}
            testId="basal.pumpAdvisoryAlert"
            visible={basal.pumpAdvisoryAlert ? expanded : null}
          />
        </Group>

        <Group
          title={t('basalDelivery')}
          titleVisible={basalOp5.display}
          visible={basalOp5.display}
        >
          <Item
            label={t('basalDeliveryAutomated')}
            src={images.basalDeliveryAutomated}
            testId="basalOp5.auto"
            visible={basalOp5.auto}
          />

          <Item
            label={t('basalDeliveryAutomatedMax')}
            src={images.basalDeliveryAutomatedMax}
            testId="basalOp5.autoMax"
            visible={basalOp5.autoMax}
          />

          <Item
            label={t('basalDeliveryAutomatedPause')}
            src={images.basalDeliveryAutomatedPause}
            testId="basalOp5.autoPause"
            visible={basalOp5.autoPause}
          />
        </Group>

        <Group
          title={t('insulin')}
          titleVisible={insulin.display ? expanded : false}
          visible={insulin.display ? expanded : false}
        >
          <Item
            label={t('bolus')}
            src={images.InsulinBolus}
            testId="insulin.bolus"
            visible={insulin.bolus ? expanded : null}
          />

          <Item
            label={t('basal')}
            src={images.InsulinBasal}
            testId="insulin.basal"
            visible={insulin.basal ? expanded : null}
          />

          <Item
            label={t('premixed')}
            src={images.InsulinPremixed}
            testId="insulin.premixed"
            visible={insulin.premixed ? expanded : null}
          />

          <Item
            label={t('custom')}
            src={images.InsulinManual}
            testId="insulin.custom"
            visible={insulin.custom ? expanded : null}
          />

          <Item
            label={t('calculatedBolus')}
            src={images.InsulinCalculatedBolus}
            testId="insulin.calculatedBolus"
            visible={(insulin.calculatedBolus && !inPdf) ? expanded : null}
          />
        </Group>
      </>
    );
  };

  if (isConfigured && !legendConfig) return null;

  return (
    <GraphsLegendPresenter
      hiddenLink={!isConfigured}
      linkLabel={linkLabel()}
      onButtonClick={onButtonClick}
      weekView={isWeekView}
      weekViewWeb={isWeekViewWeb}
    >
      {isConfigured ? legend() : loader()}
    </GraphsLegendPresenter>
  );
};

export default WithTranslate('GraphsLegend')(connect(mapStateToProps)(OverviewHoursLegend));
