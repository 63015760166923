import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Select } from '@glooko/common-ui';
import { find } from 'lodash';
import { GreyOverlay } from '~/bundles/shared/components/Overlay/Overlay.jsx';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Style from './SelectInsulinType.scss';

class SelectInsulinType extends React.Component {
  state = { selectedMedication: null, editMedication: false }

  setSelectedMedication = (state) => this.setState({ selectedMedication: state });

  setEditMedication = () => this.setState({ editMedication: !this.state.editMedication });

  resetReduxStates = () => {
    const {
      setMedicationOverrideList,
      setDisplayInsulinSelector,
      setPayloadForMedicationSelector,
    } = this.props;

    setDisplayInsulinSelector(false);
    setMedicationOverrideList(null);
    setPayloadForMedicationSelector(null, null, null);
    this.setSelectedMedication(null);
    this.setEditMedication(null);
  }

  handleSelectedMedicationChange = (selectedMedication) => {
    console.log({ selectedMedication }, this.state.selectedMedication);
    if (selectedMedication.value === this.state.selectedMedication) return;

    this.setSelectedMedication(selectedMedication.value);
  }

  handleButtonClick = () => {
    const {
      payload,
      rowId,
      updateSerialNumberCache,
      assignToPatientThunkProp,
      selectedOverrideGuid,
    } = this.props;

    const selectedMedication = this.state.selectedMedication || selectedOverrideGuid;

    this.resetReduxStates();

    const modifiedPayload = { ...payload };
    delete modifiedPayload.medicationOverrideList;

    this.props.onClickCallback(assignToPatientThunkProp, {
      ...modifiedPayload,
      medicationOverrideGuid: selectedMedication,
    }, rowId, /* istanbul ignore next */ () => updateSerialNumberCache(false));
  }

  mappedMedicationList = (translatedMedications) => {
    if (!this.props.medicationOverrideList) return [];

    return Object.keys(this.props.medicationOverrideList).map((medication) => ({
      value: this.props.medicationOverrideList[medication],
      label: translatedMedications[medication],
    }));
  }

  render() {
    if (!this.props.visible) return null;

    const { selectedOverrideGuid, t } = this.props;

    const translatedMedications = {
      actrapid: t('actrapid'),
      fiasp: t('fiasp'),
      insulatard: t('insulatard'),
      levemir: t('levemir'),
      novoMix30: t('novoMix30'),
      novorapid: t('novorapid'),
      ryzodeg: t('ryzodeg'),
      tresiba: t('tresiba'),
    };
    const options = this.mappedMedicationList(translatedMedications);
    const selectedValue = this.state.selectedMedication || find(options, (item) => item.value === selectedOverrideGuid);
    const medicationPickerDisabled = selectedOverrideGuid && !this.state.editMedication;
    const title = selectedValue ? t('confirmInsulinType') : t('selectInsulinType');

    return (
      <div>
        <GreyOverlay />
        <div className={Style.SelectInsulinType}>
          <div className={Style.contentContainer}>
            <div className={Style.title}>{title}</div>
            <div className={Style.instructions}>{t('instructions')}</div>
            <div className={Style.insulinPicker}>
              <div className={Style.insulinDropdown}>
                <Select
                  isClearable={false}
                  onChange={this.handleSelectedMedicationChange}
                  options={options}
                  isSearchable={false}
                  defaultValue={selectedValue}
                  isDisabled={medicationPickerDisabled}
                  placeholder="Insulin type"
                />
              </div>
              <div className={Style.insulinEditButtonWrapper}>
                <div
                  className={classNames(Style.insulinEditButton, {
                    [Style.clicked]: !medicationPickerDisabled,
                  })}
                  onClick={this.setEditMedication}
                  role="button"
                  tabIndex="-1"
                >{t('edit')}
                </div>
              </div>
            </div>
            <div className={Style.hintQuestion}>{t('hintQuestion')}</div>
            <div className={Style.hintAnswer}>{t('hintAnswer')}</div>
            <div className={Style.buttonContainer}>
              <Button
                className={Style.buttonContinue}
                disabled={!(selectedValue)}
                onClick={this.handleButtonClick}
                dataAttributes={{ testid: 'select-insulin-type-done' }}
              >
                {t('done')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SelectInsulinType.propTypes = {
  payload: PropTypes.object,
  rowId: PropTypes.string,
  onClickCallback: PropTypes.func,
  assignToPatientThunkProp: PropTypes.func,
  setMedicationOverrideList: PropTypes.func,
  setDisplayInsulinSelector: PropTypes.func,
  setPayloadForMedicationSelector: PropTypes.func,
  updateSerialNumberCache: PropTypes.func,
  medicationOverrideList: PropTypes.object,
  visible: PropTypes.bool,
  findPatientVisible: PropTypes.bool,
  selectedOverrideGuid: PropTypes.string,
  t: PropTypes.func.isRequired,
};

SelectInsulinType.defaultProps = {
  payload: {},
  rowId: '',
  onClickCallback: /* istanbul ignore next */ () => {},
  assignToPatientThunkProp: /* istanbul ignore next */ () => {},
  setMedicationOverrideList: /* istanbul ignore next */ () => {},
  setDisplayInsulinSelector: /* istanbul ignore next */ () => {},
  setPayloadForMedicationSelector: /* istanbul ignore next */ () => {},
  updateSerialNumberCache: /* istanbul ignore next */ () => {},
  medicationOverrideList: null,
  visible: false,
  findPatientVisible: false,
  selectedOverrideGuid: undefined,
};

export default translate('SelectInsulinType')(SelectInsulinType);
